import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import Blink from '../components/Blink';
import DateTZ from '../utils/DateUtils';
import { playSound, sounds } from '../utils/SoundUtils';
import { BLINK_LEVEL } from '../hooks/useBlinkAnimation';

export const initialValue = {
  updateOrdersList: () => {},
  alertLevel: BLINK_LEVEL.DISABLED,
  newOrdersCount: 0,
  setCurrentOrderId: () => {},
};
export const NewOrderAlertContext = createContext(initialValue);

export const useNewOrderAlert = () => useContext(NewOrderAlertContext);

const chooseAlertLevel = (ordersList, setAlertLevel) => {
  const nowTimestamp = DateTZ.toTimestamp(DateTZ.now());
  if (ordersList?.length === 0) {
    setAlertLevel(BLINK_LEVEL.DISABLED);
  } else {
    const [oldestOrder] = sortBy(
      ordersList,
      (order) => order?.progress?.received
    );
    const oldestOrderReceivedTimestamp = oldestOrder?.progress?.received;
    const minutesSinceOldestOrder = DateTZ.diffInMinutes(
      nowTimestamp,
      oldestOrderReceivedTimestamp
    );
    if (minutesSinceOldestOrder > 6) {
      setAlertLevel(BLINK_LEVEL.HIGH);
    } else if (minutesSinceOldestOrder > 3) {
      setAlertLevel(BLINK_LEVEL.MEDIUM);
    } else {
      setAlertLevel(BLINK_LEVEL.LOW);
    }
  }
};

const handleSoundAlert = (ordersList, allowSoundAlert) => {
  if (allowSoundAlert && ordersList?.length) {
    playSound(sounds.PIXIE_DUST);
  }
};

const guardAlertLevel = (allowAlert, alertLevel) =>
  !allowAlert ? BLINK_LEVEL.DISABLED : alertLevel;

const calculateAmountOfNewOrders = (currentOrderId, allNewOrders) => {
  if (!currentOrderId) return allNewOrders?.length;
  const ordersWithoutCurrent = allNewOrders?.filter(
    ({ id }) => id !== currentOrderId
  );
  return ordersWithoutCurrent?.length || 0;
};

export function NewOrderAlertProvider({ children }) {
  const [ordersList, updateOrdersList] = useState([]);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const allowVisualAlert = useSelector(
    (state) => state?.settings?.alert?.visualAlert
  );
  const allowSoundAlert = useSelector(
    (state) => state?.settings?.alert?.soundAlert
  );
  const [alertLevel, setAlertLevel] = useState(BLINK_LEVEL.DISABLED);
  const alertLevelGuarded = guardAlertLevel(allowVisualAlert, alertLevel);

  useEffect(() => {
    chooseAlertLevel(ordersList, setAlertLevel);
    handleSoundAlert(ordersList, allowSoundAlert);
  }, [ordersList, setAlertLevel]);

  const newOrdersCount = useMemo(
    () => calculateAmountOfNewOrders(currentOrderId, ordersList),
    [ordersList, currentOrderId]
  );

  const providerValues = useMemo(
    () => ({
      updateOrdersList,
      alertLevel: alertLevelGuarded,
      newOrdersCount,
      setCurrentOrderId,
    }),
    [updateOrdersList, alertLevelGuarded, newOrdersCount, setCurrentOrderId]
  );

  return (
    <NewOrderAlertContext.Provider value={providerValues}>
      <Blink>{children}</Blink>
    </NewOrderAlertContext.Provider>
  );
}
