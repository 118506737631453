import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      controlButton: {
        backgroundColor: theme.appColors.PRIMARY_500,
        color: theme.appColors.BASE_WHITE,
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
      },
      buttonMinus: {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      buttonPlus: {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
      controlButtonWrap: {
        borderRadius: 1,
      },
      timeInfo: {
        borderRadius: 1,
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      },
      preparationTimeContent: {
        borderRadius: 1,
        backgroundColor: theme.appColors.GRAY_100,
        height: 48,
        width: 128,
      },
      preparationTimeText: {
        fontSize: 20,
        fontFamily: theme.appFonts.medium,
      },
    });
  }
}
