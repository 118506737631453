import { StyleSheet } from 'react-native';
import { ButtonSize } from './Button';

export default class StyleSheets {
  static getSheet({ size }) {
    let fontSize = 16;
    if (size === ButtonSize.L) {
      fontSize = 18;
    } else if (size === ButtonSize.S) {
      fontSize = 14;
    }
    let padding = 4;
    if (size === ButtonSize.L) {
      padding = 6;
    } else if (size === ButtonSize.S) {
      padding = 2;
    }

    return StyleSheet.create({
      button: {
        borderRadius: 8,
      },
      label: {
        fontWeight: '500',
        fontSize,
        padding,
      },
    });
  }
}
