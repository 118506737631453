import React, { useState, useCallback } from 'react';
import { Card, Text } from 'react-native-paper';
import { SafeAreaView, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  add,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  endOfWeek,
  startOfWeek,
} from 'date-fns';
import Button from '../../../components/Button';
import { DropDownHolder } from '../../../components/DropDownHolder';
import ToggleButtonGroup from '../../../components/ToggleButtonGroup';
import DateTimePicker from '../../../components/DateTimePicker';
import { useRestaurant } from '../../../redux/ducks/User/UserReducer';
import { addTempClosingHoursRequest } from '../../../redux/ducks/Settings/SettingsReducer';
import { selectTempClosingHourSaving } from '../../../redux/ducks/Settings/SettingsSelector';
import {
  formatDateTime,
  getTimeStamp,
} from '../../../utils/DateUtils/conversion';
import useRightMenu from './hooks/useRightMenu';
import useStyles from '../../../utils/useStyles';

const intervalOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Tomorrow', value: 'tomorrow' },
  { label: 'This Week', value: 'this-week' },
  { label: 'Next Week', value: 'next-week' },
];

class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      title: { marginTop: 16 },
      closingHoursText: { marginTop: 20, color: theme.appColors.PRIMARY_700 },
      buttonContainer: {
        width: '100%',
        borderTopWidth: 1,
        borderColor: '#EAEAEA',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: 16,
        gap: 16,
        position: 'absolute',
        bottom: 0,
      },
      button: {
        borderRadius: 8,
      },
    });
  }
}

function AddTempClosingHours() {
  const [commonInterval, setCommonInterval] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [fromTime, setFromTime] = useState({
    hours: 0,
    minutes: 0,
  });
  const [toDate, setToDate] = useState(new Date());
  const [toTime, setToTime] = useState({
    hours: 0,
    minutes: 0,
  });
  const saving = useSelector(selectTempClosingHourSaving);
  const navigation = useNavigation();
  const restaurant = useRestaurant();
  const styles = useStyles(StyleSheets);
  const dispatch = useDispatch();

  useRightMenu();

  const handleSubmit = useCallback(() => {
    const fromStamp = getTimeStamp(fromDate, fromTime);
    const toStamp = getTimeStamp(toDate, toTime);
    dispatch(
      addTempClosingHoursRequest({
        restaurantId: restaurant.id,
        start: fromStamp,
        end: toStamp,
        callback: () => {
          navigation.pop();
        },
        showError: (message) => {
          DropDownHolder.showAlert('error', message, '');
        },
      })
    );
  }, [dispatch, restaurant?.id, fromDate, fromTime, toDate, toTime]);

  const handlePressCommonInterval = useCallback((value) => {
    switch (value) {
      case 'today': {
        const st = new Date();
        setFromDate(st);
        setFromTime({ hours: st.getHours(), minutes: st.getMinutes() });
        const en = endOfToday();
        setToDate(en);
        setToTime({ hours: en.getHours(), minutes: en.getMinutes() });
        break;
      }
      case 'tomorrow': {
        const st = startOfTomorrow();
        setFromDate(st);
        setFromTime({ hours: 0, minutes: 0 });
        const en = endOfTomorrow();
        setToDate(en);
        setToTime({ hours: en.getHours(), minutes: en.getMinutes() });
        break;
      }
      case 'this-week': {
        const st = new Date();
        setFromDate(st);
        setFromTime({ hours: st.getHours(), minutes: st.getMinutes() });
        const en = endOfWeek(st, { weekStartsOn: 1 });
        setToDate(en);
        setToTime({ hours: en.getHours(), minutes: en.getMinutes() });
        break;
      }
      case 'next-week': {
        const d = add(new Date(), { days: 7 });
        const st = startOfWeek(d, { weekStartsOn: 1 });
        setFromDate(st);
        setFromTime({ hours: st.getHours(), minutes: st.getMinutes() });
        const en = endOfWeek(d, { weekStartsOn: 1 });
        setToDate(en);
        setToTime({ hours: en.getHours(), minutes: en.getMinutes() });
        break;
      }
      default:
    }
  }, []);

  return (
    <SafeAreaView style={{ margin: 16, flex: 1 }}>
      <Card style={{ marginBottom: 16 }}>
        <Card.Title title="Common Intervals" />
        <Card.Content>
          <ToggleButtonGroup
            value={commonInterval}
            setValue={setCommonInterval}
            options={intervalOptions}
            testID="button-group-interval"
            buttonStyle={{
              flexGrow: 1,
              flexWrap: 'wrap',
            }}
            onChange={handlePressCommonInterval}
          />
        </Card.Content>

        <Card.Title style={styles.title} title="Select Closing Hours" />
        <Card.Content>
          <Text variant="bodyMedium">
            What times will the restaurant be closed?
          </Text>

          <View style={{ flexDirection: 'row', gap: 12, flexWrap: 'wrap' }}>
            <DateTimePicker
              label="From"
              date={fromDate}
              setDate={setFromDate}
              time={fromTime}
              setTime={setFromTime}
            />
            <DateTimePicker
              label="To"
              date={toDate}
              setDate={setToDate}
              time={toTime}
              setTime={setToTime}
            />
          </View>
        </Card.Content>

        <Card.Title style={styles.title} title="Confirm Closing Hours" />
        <Card.Content>
          <Text variant="bodyMedium">
            Are you sure you want to close during the time below?
          </Text>
          <Text style={styles.closingHoursText}>
            {`${formatDateTime(fromDate, fromTime)} - ${formatDateTime(
              toDate,
              toTime
            )}`}
          </Text>
        </Card.Content>
      </Card>
      <View style={styles.buttonContainer}>
        <Button
          testID="btn-cancel"
          mode="outlined"
          color="GRAY_700"
          style={styles.button}
          onPress={() => navigation.pop()}
        >
          Cancel
        </Button>
        <Button
          testID="btn-add-temp-closing"
          mode="contained"
          color="GRAY_900"
          loading={saving}
          style={styles.button}
          onPress={handleSubmit}
        >
          Add Closing Hours
        </Button>
      </View>
    </SafeAreaView>
  );
}

export default AddTempClosingHours;
