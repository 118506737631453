import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Text } from 'react-native-paper';
import { RectButton } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Theme } from '../../constants/theme';
import useStyles from '../../utils/useStyles';
import {
  useRestaurant,
  deleteMenuItem,
  activateMenuItem,
} from '../../redux/ducks/User/UserReducer';

import { formatMoney } from '../../utils/CurrencyUtils';

import StyleSheets from './CategoryItem.style';

export const activate = (
  dispatch,
  activateItem,
  selectedRestaurant,
  menuId,
  item,
  question
) => {
  dispatch(
    activateItem({
      restId: selectedRestaurant?.id,
      menuId,
      item,
      id: item.id,
      question,
    })
  );
};

export const unactivate = (
  dispatch,
  deleteItem,
  selectedRestaurant,
  menuId,
  item,
  question
) => {
  dispatch(
    deleteItem({
      restId: selectedRestaurant?.id,
      menuId,
      item,
      id: item.id,
      question,
    })
  );
};

function CategoryItem(props) {
  const { item, menuId, question } = props;
  const selectedRestaurant = useRestaurant();
  const dispatch = useDispatch();

  const styles = useStyles(StyleSheets);

  const isActive = item.status === 'active';

  return (
    <View testID={`category-item-${item.id}`}>
      <View style={styles.row}>
        <View style={styles.smallRow}>
          {Boolean(item.prices) && (
            <Text style={[styles.text, !isActive ? styles.eightysixed : null]}>
              {formatMoney(item.prices.base)}
            </Text>
          )}
          <Text
            style={[
              styles.itemName,
              styles.text,
              !isActive ? styles.eightysixed : null,
              item.prices ? styles.mLeft : {},
            ]}
          >
            {item.name || item.text}
          </Text>
        </View>
        <View style={[styles.mLeft, styles.buttonWrap]}>
          {isActive ? (
            <RectButton
              testID="unactivate-button"
              style={[styles.actionButton, styles.deleteAction]}
              onPress={() =>
                unactivate(
                  dispatch,
                  deleteMenuItem,
                  selectedRestaurant,
                  menuId,
                  item,
                  question
                )
              }
            >
              <View style={styles.buttonLabel}>
                <Icon color={Theme.colors.danger} size={16} name="cart-off" />
                <Text style={[styles.buttonText, styles.deleteText]}>
                  86 it
                </Text>
              </View>
            </RectButton>
          ) : (
            <RectButton
              testID="activate-button"
              style={[styles.actionButton, styles.activateAction]}
              onPress={() =>
                activate(
                  dispatch,
                  activateMenuItem,
                  selectedRestaurant,
                  menuId,
                  item,
                  question
                )
              }
            >
              <View style={styles.buttonLabel}>
                <Icon color={Theme.colors.primary} size={16} name="cart" />
                <Text style={[styles.buttonText, styles.activateText]}>
                  Restore
                </Text>
              </View>
            </RectButton>
          )}
        </View>
      </View>
      <View style={styles.divider} />
    </View>
  );
}

CategoryItem.propTypes = {
  menuId: PropTypes.number,
  item: PropTypes.object,
  question: PropTypes.bool,
};

export default CategoryItem;
