import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { Button } from 'react-native-paper';
import useStyles from '../../utils/useStyles';
import Stylesheet from './ErrorScreen.styles';

function ErrorScreen({ message = 'Unable to fetch', refetch }) {
  const styles = useStyles(Stylesheet);
  return (
    <View style={styles.container} testID="error-screen">
      <Text style={styles.text} testID="error-screen-message">
        {message}
      </Text>
      {refetch && (
        <Button
          testID="error-screen-refetch-button"
          icon="refresh"
          onPress={refetch}
        >
          Retry
        </Button>
      )}
    </View>
  );
}

ErrorScreen.propTypes = {
  message: PropTypes.string,
  refetch: PropTypes.func,
};

export default ErrorScreen;
