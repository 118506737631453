import React from 'react';
import { ActivityIndicator, Colors } from 'react-native-paper';
import PropTypes from 'prop-types';
import { CONNECTIONS_STATUS } from '../../redux/ducks/Printer/PrinterReducer';
import SettingsMenu from '../SettingsMenu';

export const getStatusProps = (status) =>
  ({
    [CONNECTIONS_STATUS.NOT_CONNECTED]: {
      rightText: 'Disconnected',
      rightDisabled: true,
    },
    [CONNECTIONS_STATUS.CONNECTING]: {
      right: () => <ActivityIndicator animating color={Colors.black} />,
      rightDisabled: true,
    },
    [CONNECTIONS_STATUS.CONNECTED]: {
      rightText: 'Connected',
      rightDisabled: false,
    },
  }[status]);

export function PrinterList({
  printers,
  header,
  itemsTestID,
  connectPrinter,
  openPrinterInfo,
}) {
  function Wrapper({ children }) {
    if (header) {
      return <SettingsMenu header={header}>{children}</SettingsMenu>;
    }
    return children;
  }

  if (printers?.length === 0) return null;

  return (
    <Wrapper>
      {printers?.map((printer) => (
        <SettingsMenu.Item
          testID={itemsTestID}
          key={printer?.name}
          title={printer?.name}
          {...getStatusProps?.(printer?.status)}
          onPress={() => connectPrinter(printer?.name)}
          onLongPress={() => openPrinterInfo(printer?.name)}
        />
      ))}
    </Wrapper>
  );
}

PrinterList.propTypes = {
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  header: PropTypes.string,
  itemsTestID: PropTypes.string,
  connectPrinter: PropTypes.func,
  openPrinterInfo: PropTypes.func,
};
