import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialLoadRequest } from '../../redux/ducks/User/UserReducer';

const useAppLoading = () => {
  const dispatch = useDispatch();

  const isAppLoading = useSelector((state) => state.user.isInitialLoading);

  useEffect(() => {
    const fetchInitialState = async () => {
      await dispatch(initialLoadRequest());
    };

    fetchInitialState();
  }, [dispatch]);

  return { isAppLoading };
};

export default useAppLoading;
