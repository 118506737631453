import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { Text, View, TextInput, ScrollView } from 'react-native';
import { Card, Divider } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import OrderCardInfo from '../../components/OrderCard/components/OrderCardInfo';
import OrderCardContactInfo from '../../components/OrderCard/components/OrderCardContactInfo';
import DropdownItem from '../../components/DropdownItem/DropdownItem';
import ToggleItem from '../../components/ToggleItem/ToggleItem';
import CheckboxItem from '../../components/CheckboxItem/CheckboxItem';
import Button, { ButtonSize } from '../../components/Button';
import { formatMoney } from '../../utils/CurrencyUtils/CurrencyUtils';
import { putErrorOrder } from '../../services/RestaurantManagerPortalService';
import { DropDownHolder } from '../../components/DropDownHolder';
import { formatFooterNoticeTime } from '../../utils/DateUtils/conversion';
import useStyles from '../../utils/useStyles';
import { problems, solutions } from './OrderRefund.constants';
import StyleSheets from './OrderRefund.styles';

const formatRequest = (
  orderDetails,
  selectedProblem,
  selectedSolution,
  value,
  toggleCharge,
  selectedRefundItems,
  notes
) => {
  const problemType = problems.find(
    ({ label }) => label === selectedProblem
  )?.key;
  const problemSolution = solutions.find(
    ({ label }) => label === selectedSolution
  )?.key;

  const formError = {
    type: problemType,
    solution: problemSolution,
    description: notes,
    ...(selectedSolution === 'Refund now' && {
      data: {
        amount: parseFloat(value.toFixed(2)),
        items: toggleCharge
          ? orderDetails?.items.map((item) => item.id)
          : orderDetails?.items
              .filter((item) => selectedRefundItems.includes(item.id))
              .map((item) => item.id),
        answers: [],
      },
    }),
  };

  return formError;
};

const onConfirm = async (
  orderDetails,
  selectedProblem,
  selectedSolution,
  notes,
  value,
  toggleCharge,
  selectedRefundItems,
  restaurant,
  setLoading,
  navigation
) => {
  setLoading(true);
  const formError = formatRequest(
    orderDetails,
    selectedProblem,
    selectedSolution,
    value,
    toggleCharge,
    selectedRefundItems,
    notes
  );

  console.log('FORM ERROR', formError);

  try {
    await putErrorOrder({
      restaurantId: restaurant.id,
      orderId: orderDetails.id,
      formError,
    });
    setLoading(false);
    navigation.pop();

    DropDownHolder.showAlert('success', 'Success', 'Refund created');
  } catch (err) {
    setLoading(false);
    console.error(err);
    DropDownHolder.showAlert(
      'error',
      'Cannot refund amount',
      `${err?.response?.data?.message || 'Unknown error'}`
    );
  }
};

const selectRefundItem = (
  id,
  selectedRefundItems,
  setSelectedRefundItems,
  setRefundTotal
) => {
  if (selectedRefundItems.includes(id)) {
    setRefundTotal(false);
    const removed = selectedRefundItems.filter((item) => item !== id);
    setSelectedRefundItems(removed);
  } else {
    setSelectedRefundItems([...selectedRefundItems, id]);
  }
};

const onToggleCharge = (
  toggleCharge,
  setToggleTax,
  setSelectedRefundItems,
  orderDetails
) => {
  if (toggleCharge) {
    setToggleTax(true);
    setSelectedRefundItems(orderDetails.items.map((item) => item.id));
  }
};

const onChangeSelectedItems = (
  toggleCharge,
  selectedRefundItems,
  orderDetails,
  setToggleCharge
) => {
  if (
    toggleCharge &&
    selectedRefundItems.length !== orderDetails.items.length
  ) {
    setToggleCharge(false);
  }
};

const calculateValue = (
  toggleCharge,
  toggleTax,
  orderDetails,
  selectedRefundItems,
  setValue
) => {
  let totalValue = 0;
  const items = toggleCharge
    ? orderDetails.items
    : orderDetails?.items.filter((item) =>
        selectedRefundItems.includes(item.id)
      );
  items.forEach((item) => {
    const taxAmount = toggleTax && item.tax?.amount ? item.tax?.amount : 0;
    totalValue += item.amount * (item.price.base + taxAmount);
  });
  setValue(totalValue);
};

function OrderRefund() {
  const route = useRoute();
  const navigation = useNavigation();
  const orderDetails = route?.params?.order;
  const restaurant = route?.params?.restaurant;
  const pickupTimestamp = orderDetails?.schedule?.pickup;
  const { customer, total } = orderDetails;

  const [selectedRefundItems, setSelectedRefundItems] = useState([]);
  const [toggleCharge, setToggleCharge] = useState(false);
  const [refundTotal, setRefundTotal] = useState(false);
  const [toggleTax, setToggleTax] = useState(false);
  const [notes, setNotes] = useState('');
  const [selectedProblem, setSelectedProblem] = useState('Item missing');
  const [selectedSolution, setSelectedSolution] = useState('Refund now');
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const styles = useStyles(StyleSheets);

  useEffect(() => {
    if (refundTotal) {
      setToggleTax(true);
      setSelectedRefundItems(orderDetails.items.map((item) => item.id));
      setValue(total);
    }
  }, [refundTotal]);

  useEffect(() => {
    onToggleCharge(
      toggleCharge,
      setToggleTax,
      setSelectedRefundItems,
      orderDetails
    );
  }, [toggleCharge]);

  useEffect(() => {
    onChangeSelectedItems(
      toggleCharge,
      selectedRefundItems,
      orderDetails,
      setToggleCharge
    );
  }, [selectedRefundItems]);

  useEffect(() => {
    if (!refundTotal) {
      calculateValue(
        toggleCharge,
        toggleTax,
        orderDetails,
        selectedRefundItems,
        setValue
      );
    }
  }, [toggleCharge, toggleTax, selectedRefundItems]);

  useEffect(() => {
    if (!toggleTax) {
      setRefundTotal(false);
    }
  }, [toggleTax]);

  const handleRefund = () => {
    if (loading) {
      return;
    }
    if (!notes) {
      DropDownHolder.showAlert(
        'error',
        'Error',
        'The refund notes are required.'
      );
      return;
    }
    if (value > total) {
      DropDownHolder.showAlert(
        'error',
        'Error',
        'The refund amount exceeded the total amount.'
      );
      return;
    }

    onConfirm(
      orderDetails,
      selectedProblem,
      selectedSolution,
      notes,
      value,
      toggleCharge,
      selectedRefundItems,
      restaurant,
      setLoading,
      navigation
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollContainer}
        contentContainerStyle={{ padding: 16 }}
      >
        <Card style={styles.card}>
          <Card.Content style={{ flexDirection: 'row' }}>
            {customer && (
              <OrderCardContactInfo
                label="Customer Info"
                type="customer"
                name={customer.name}
                phone={customer.phone}
                style={{ flex: 1, flexGrow: 1 }}
              />
            )}
            <OrderCardInfo
              title="Order Pickup Date"
              text={formatFooterNoticeTime(
                pickupTimestamp,
                restaurant?.timezone
              )}
              style={styles.gapChild}
            />
          </Card.Content>
        </Card>
        <View style={styles.dropdownContainer}>
          <DropdownItem
            testID="specify-problem-dropdown"
            label="Specify problem"
            items={problems}
            onChange={setSelectedProblem}
            selectedItem={selectedProblem}
          />
          <DropdownItem
            testID="specify-solution-dropdown"
            label="Solution"
            items={solutions}
            onChange={setSelectedSolution}
            selectedItem={selectedSolution}
          />
        </View>
        <View>
          <Text style={styles.subtitle}>Notes</Text>
          <TextInput
            testID="specify-problem-note"
            multiline
            numberOfLines={8}
            textAlignVertical="top"
            placeholder="Share details about the issue."
            style={styles.textArea}
            onChange={(e) => setNotes(e.target.value)}
          />
        </View>
        {selectedSolution === 'Refund now' && (
          <>
            <Text style={styles.title}>
              Specify Refund Amount or Select Item for Solution:
            </Text>
            <Card style={styles.card}>
              <Card.Content style={{ width: '100%' }}>
                <View style={styles.amountContainer}>
                  <Text style={styles.amountText}>Refund Specific Amount</Text>
                </View>
                <View style={styles.toggleContainer}>
                  <Button
                    testID="btn-refund-entire"
                    onPress={() => setRefundTotal(true)}
                    mode="outlined"
                    size={ButtonSize.S}
                    color="GRAY_800"
                  >
                    Refund Entire Charge
                  </Button>
                  <ToggleItem
                    testID="specify-solution-toggle"
                    id="solution"
                    label="Include tax"
                    selected={toggleTax}
                    onChange={setToggleTax}
                  />
                  <View>
                    <TextInputMask
                      type="money"
                      options={{
                        precision: 2,
                        separator: '.',
                        unit: '$',
                      }}
                      style={styles.amountInput}
                      value={value}
                      onChangeText={(text) => {
                        console.log(text);
                        setValue(parseFloat(text.slice(1)));
                      }}
                    />
                  </View>
                </View>
                <Divider />
                <View style={{ marginVertical: 8 }}>
                  {orderDetails?.items?.map((item) => {
                    const taxAmount =
                      toggleTax && item.tax?.amount ? item.tax?.amount : 0;

                    return (
                      <View key={item.id} style={styles.itemContainer}>
                        <CheckboxItem
                          testID="specify-problem-check"
                          id={item.id}
                          isSelected={(id) => selectedRefundItems.includes(id)}
                          label={`${item.amount}x ${item.name}`}
                          onChange={(id) =>
                            selectRefundItem(
                              id,
                              selectedRefundItems,
                              setSelectedRefundItems,
                              setRefundTotal
                            )
                          }
                        />
                        <Text style={styles.itemText}>
                          {formatMoney(item.price.base)}
                          {toggleTax
                            ? ` (+ ${formatMoney(taxAmount)} tax)`
                            : ''}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </Card.Content>
              <Divider />
              <View style={styles.totalContainer}>
                <Text style={styles.totalText}>Total:</Text>
                <Text style={styles.totalPrice}>{formatMoney(total)}</Text>
              </View>
            </Card>
          </>
        )}
      </ScrollView>

      <View style={styles.buttonContainer}>
        <Button
          testID="refund-cancel"
          style={styles.cancelButton}
          mode="outlined"
          color="GRAY_800"
          onPress={() => navigation.pop()}
        >
          Cancel
        </Button>
        <Button
          testID="refund-confirm"
          mode="contained"
          color="GRAY_800"
          disabled={value === 0}
          onPress={handleRefund}
          loading={loading}
        >{`Refund ${formatMoney(value)}`}</Button>
      </View>
    </View>
  );
}

export default OrderRefund;
