import React from 'react';
import { View, SafeAreaView } from 'react-native';
import {
  DrawerItem,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';
import { Text, IconButton } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DrawerActions } from '@react-navigation/routers';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { logoutRequest } from '../../redux/ducks/User/UserReducer';
import useStyles from '../../utils/useStyles';
import Stylesheet from './DrawerContent.styles';

function DrawerContent({ theme, title, ...props }) {
  const dispatch = useDispatch();
  const doLogout = () => {
    dispatch(logoutRequest());
  };

  const closeDrawer = () => {
    props.navigation.dispatch(DrawerActions.closeDrawer());
  };
  const styles = useStyles(Stylesheet);

  const safeArea = useSafeAreaInsets();

  return (
    <>
      <DrawerContentScrollView {...props}>
        <View style={styles.drawerContent}>
          <View style={styles.headerContainer}>
            <View>
              <View style={styles.headerLeftContainer}>
                <Icon name="silverware-fork-knife" style={styles.headerIcon} />
                <Text style={styles.headerTitle}>Eatzy </Text>
                <Text style={styles.headerTitleAccent}>{title}</Text>
              </View>
            </View>
            <View>
              <IconButton
                testID="drawer-content-close-drawer"
                icon="close"
                onPress={closeDrawer}
              />
            </View>
          </View>
          <DrawerItemList
            {...props}
            itemStyle={{ paddingLeft: 10 }}
            activeTintColor={theme.colors.primary}
          />
        </View>
      </DrawerContentScrollView>
      <SafeAreaView
        {...props}
        testID="drawer-content-bottom-safe-area"
        style={{ marginBottom: safeArea.bottom === 0 ? 20 : 0 }}
      >
        <DrawerItem
          testID="drawer-content-logout-button"
          label="Logout"
          style={{ paddingLeft: 20 }}
          onPress={doLogout}
        />
      </SafeAreaView>
    </>
  );
}

DrawerContent.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.string,
};

export default DrawerContent;
