import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: { marginVertical: 5 },
      title: {
        fontSize: 12,
        color: '#282B3140',
        lineHeight: 18,
        fontWeight: 'bold',
      },
      label: {
        fontSize: 14,
        color: '#282B31',
        lineHeight: 21,
        fontWeight: 'bold',
      },
    });
  }
}
