import EscPosPrinterAdapter from './EscPosPrinterAdapter';
import { shouldUseMock } from './Helpers';
import MockPrinterAdapter from './MockPrinterAdapter';
import { isWeb } from '../config';

export class PosPrinterFactory {
  static getAdapterFrom(options) {
    if (shouldUseMock() || isWeb) {
      return new MockPrinterAdapter(options);
    }
    return new EscPosPrinterAdapter(options);
  }
}

export default PosPrinterFactory.getAdapterFrom({ enableDebug: false });
