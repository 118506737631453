import posPrinter from '../../PosPrinter';
import PosEncoder from '../PosEncoder';

export const sample = async () => {
  const posEncoder = new PosEncoder({
    printerInstance: posPrinter,
  });
  const priceTableRatios = {
    left: 0.8,
    right: 0.2,
  };
  await posEncoder.loadCharsPerLine();

  posEncoder
    .initialize()
    .newline()
    .newline()
    .align('left')
    .bold()
    .text('Eatzy Restaurant')
    .bold()
    .newline()
    .newline()
    .align('center')
    .qrcode('https://www.google.com/', 1, 'info', 3)
    .newline()
    .newline()
    .align('left')
    .bold()
    .line('Order Items')
    .bold()
    .printHalfTable('1x Pork Ribs', '$3.99', priceTableRatios)
    .printHalfTable('1x Half Eaten Apple', '$9.99', priceTableRatios)
    .printHalfTable('1x Ham Burguer', '$1.99', priceTableRatios)
    .printHalfTable('1x McFries', '$3.99', priceTableRatios)
    .printHalfTable('1x Ronald McDonald', '$99.99', priceTableRatios)
    .printHalfTable('2x Special Gourmet Burguer', '$199.99', priceTableRatios)
    .printHalfTable('1x Dirt Roll', '$3.99', priceTableRatios)
    .printHalfTable('Total', '$323.93', priceTableRatios)
    .newline()
    .align('center')
    .barcode('3130630574613', 'ean13', 80)
    .newline()
    .newline()
    .cut('partial');

  const { encoded, html } = posEncoder.encode();
  return { encoded, html };
};

export default sample;
