export const ORDER_STATUS = {
  RECEIVED: 'RECEIVED',
  CONFIRMED: 'CONFIRMED',
  READY_FOR_DRIVER: 'READY_FOR_DRIVER',
  COMPLETED_TAKEOUT: 'COMPLETED_TAKEOUT',
  COMPLETED_DELIVERY: 'COMPLETED_DELIVERY',
};

export const ORDER_ACTION = {
  SET_AS_PICKED_UP: 'SET_AS_PICKED_UP',
  SET_AS_READY_FOR_DRIVER: 'SET_AS_READY_FOR_DRIVER',
};

export const mapProgressToStatus = (progress, type) => {
  const orderIsCompleted = progress?.completed;
  const orderIsConfirmed = progress?.confirmed;
  const orderIsReady = progress?.ready;
  if (orderIsCompleted) {
    if (type === 'delivery') return ORDER_STATUS.COMPLETED_DELIVERY;
    return ORDER_STATUS.COMPLETED_TAKEOUT;
  }
  if (orderIsReady) {
    if (type === 'delivery') return ORDER_STATUS.READY_FOR_DRIVER;
    return ORDER_STATUS.COMPLETED_TAKEOUT;
  }
  if (orderIsConfirmed) {
    return ORDER_STATUS.CONFIRMED;
  }
  return ORDER_STATUS.RECEIVED;
};

export const mapProgressNextToActionStatus = (progress, type) => {
  const orderIsCompleted = progress?.completed;
  const orderIsConfirmed = progress?.confirmed;
  const orderIsReady = progress?.ready;
  if (orderIsConfirmed && !orderIsReady && !orderIsCompleted) {
    if (type === 'delivery') return ORDER_ACTION.SET_AS_READY_FOR_DRIVER;
    if (type === 'takeout') return ORDER_ACTION.SET_AS_PICKED_UP;
  }

  return null;
};
