import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import useStyles from '../../../../utils/useStyles';
import { Theme } from '../../../../constants/theme';
import StyleSheets from './MenuListItem.styles';

function MenuListItem(props) {
  const { name, onPress, testId } = props;
  const styles = useStyles(StyleSheets);

  return (
    <View>
      <TouchableOpacity
        testID={`list-item-button-${testId}`}
        style={styles.row}
        onPress={onPress}
      >
        <Text style={styles.title}>{name}</Text>
        <Icon
          style={styles.rightIcon}
          color={Theme.colors.black}
          name="chevron-right"
        />
      </TouchableOpacity>
      <View style={styles.divider} />
    </View>
  );
}

MenuListItem.propTypes = {
  name: PropTypes.string,
  onPress: PropTypes.func,
  testId: PropTypes.string,
};

export default MenuListItem;
