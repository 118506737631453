import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        paddingBottom: 40,
      },
      drawerContent: {
        padding: 10,
      },
      headerTitle: { fontWeight: 'bold', fontSize: 14 },
      headerTitleAccent: {
        fontWeight: 'bold',
        fontSize: 14,
        color: theme.colors.primary,
      },
      headerLeftContainer: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      headerIcon: {
        paddingRight: 5,
        fontSize: 14,
        color: theme.colors.primary,
      },
    });
  }
}
