import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { SafeAreaView } from 'react-native';
import { useDispatch } from 'react-redux';
import { Appbar, useTheme, withTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { RootRoutes } from '../../constants/routes';
import useStyles from '../../utils/useStyles';
import StyleSheet from './Header.styles';
import { setSearchInput } from '../../redux/ducks/Search/SearchReducer';

function Header({ scene }) {
  const { options } = scene;
  const title = options.headerTitle || options.title || scene.route.name;

  const { hideSearchBar } = options;

  const headerRight = options?.headerRight;

  const theme = useTheme();
  const styles = useStyles(StyleSheet);
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const onClickSearch = () => {
    if (scene.route.name === RootRoutes.RESTAURANT_SELECTOR.name) {
      dispatch(setSearchInput([])); // TODO
    }

    return navigation.navigate(RootRoutes.SEARCH.name);
  };

  const safeArea = useSafeAreaInsets();

  return (
    <SafeAreaView
      testID="header-safe-area"
      style={{
        marginHorizontal: 16,
        marginTop: safeArea.top === 0 ? 16 : 0,
      }}
    >
      <Appbar
        style={styles.appBar}
        theme={{
          colors: { primary: theme.colors.white },
        }}
      >
        {scene.back ? (
          <Appbar.BackAction
            testID="app-bar-back-action"
            onPress={navigation.goBack}
          />
        ) : (
          <Appbar.Action
            testID="app-bar-menu-action"
            icon="menu"
            onPress={navigation.openDrawer}
          />
        )}
        <Appbar.Content testID="app-bar-title" title={title} />

        {headerRight}

        {!hideSearchBar && (
          <Appbar.Action
            testID="app-bar-search-action"
            icon="magnify"
            onPress={onClickSearch}
          />
        )}
      </Appbar>
    </SafeAreaView>
  );
}

Header.propTypes = {};

export default withTheme(Header);
