import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getTempClosingHoursRequest,
  getTempClosingHoursResponse,
  addTempClosingHoursRequest,
  addTempClosingHoursResponse,
  deleteTempClosingHoursRequest,
  deleteTempClosingHoursResponse,
} from '../../../ducks/Settings/SettingsReducer';
import {
  getTempClosingHours,
  addTempClosingHours,
  deleteTempClosingHours,
} from '../../../../services/SettingsService';
import errorFallback from '../ErrorFallback';

export function* getTempClosingHoursSaga(action) {
  try {
    const data = yield call(getTempClosingHours, action.payload.restaurantId);

    yield put(getTempClosingHoursResponse({ data }));
  } catch (err) {
    console.error(err);
    yield put(getTempClosingHoursResponse({ error: err }));
    if (action.payload.showError) {
      action.payload.showError('Failed to get temporary closing hours.');
    }
  }
}

export function* addTempClosingHoursSaga(action) {
  const { restaurantId, callback, showError, ...payload } = action.payload;
  try {
    const data = yield call(addTempClosingHours, restaurantId, payload);

    if (data.id) {
      yield put(addTempClosingHoursResponse({}));
      yield call(getTempClosingHoursSaga, { payload: { restaurantId } });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    console.error(err);
    yield put(addTempClosingHoursResponse({ error: err }));
    if (showError) {
      action.payload.showError('Failed to add temporary closing hour.');
    }
  }
}

export function* deleteTempClosingHoursSaga(action) {
  try {
    const { restaurantId, hourId } = action.payload;
    const data = yield call(deleteTempClosingHours, restaurantId, hourId);

    if (data.deleted) {
      yield put(deleteTempClosingHoursResponse({}));
      yield call(getTempClosingHoursSaga, { payload: { restaurantId } });
    }
  } catch (err) {
    console.error(err);
    yield put(deleteTempClosingHoursResponse({ error: err }));
  }
}

export function* watchSettings() {
  yield takeLatest(
    getTempClosingHoursRequest,
    errorFallback,
    getTempClosingHoursSaga
  );
  yield takeLatest(
    addTempClosingHoursRequest,
    errorFallback,
    addTempClosingHoursSaga
  );
  yield takeLatest(
    deleteTempClosingHoursRequest,
    errorFallback,
    deleteTempClosingHoursSaga
  );
}
