import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      title: {
        color: `${theme.colors.black}60`,
        fontWeight: 'bold',
        fontSize: theme.fontSize?.FONT_12,
      },
      name: {
        fontSize: theme.fontSize?.FONT_14,
        fontWeight: 'bold',
        marginTop: 20,
      },
    });
  }
}
