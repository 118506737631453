import React from 'react';
import { View, Image } from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';

import useStyles from '../../utils/useStyles';
import StyleSheets from './Splash.styles';

const logo = require('../../assets/images/delivery.png');

function Splash() {
  const styles = useStyles(StyleSheets);
  return (
    <View style={styles.container} testID="splash-screen">
      <Image source={logo} style={styles.logo} />
      <Text style={styles.text}>Restaurant App Manager</Text>
      <ActivityIndicator animating size="large" />
    </View>
  );
}

export default Splash;
