import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        flex: 1,
      },
      scrollContainer: {
        flex: 1,
      },
      card: {
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#DADCE0',
      },
      dropdownContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: -16,
      },
      amountContainer: {
        width: '100%',
        marginTop: 16,
      },
      amountText: {
        fontSize: 14,
        textAlign: 'right',
      },
      toggleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
        marginBottom: 16,
        width: '100%',
      },

      priceText: {
        fontSize: 16,
        textAlign: 'right',
        lineHeight: 48,
      },
      title: {
        fontSize: 20,
        marginTop: 16,
        marginBottom: 12,
      },
      itemsContainer: {
        padding: 16,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#DADCE0',
        marginTop: 12,
      },
      itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      itemText: { lineHeight: 38 },
      subtitle: {
        fontSize: 12,
        marginTop: 12,
      },
      textArea: {
        backgroundColor: '#FFFFFF',
        height: 144,
        borderRadius: 8,
        borderWidth: 1,
        marginTop: 6,
        padding: 10,
        borderColor: theme.appColors.GRAY_300,
      },
      amountInput: {
        height: 36,
        borderRadius: 8,
        borderWidth: 1,
        padding: 10,
        borderColor: theme.appColors.GRAY_300,
        textAlign: 'right',
      },
      totalContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        paddingVertical: 24,
      },
      totalText: {
        fontSize: 24,
        color: theme.appColors.GRAY_500,
      },
      totalPrice: {
        fontSize: 24,
        color: theme.appColors.GRAY_900,
      },
      buttonContainer: {
        width: '100%',
        borderTopWidth: 1,
        borderColor: '#EAEAEA',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: 16,
        bottom: 0,
      },
      cancelButton: {
        marginRight: 10,
      },
    });
  }
}
