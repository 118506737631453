import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        paddingHorizontal: 24,
        paddingVertical: 24,
        borderRadius: 8,
      },
      orderInfoContainer: {
        flexDirection: 'row',
        paddingVertical: 12,
      },
      gapContainer: {
        marginHorizontal: -10,
      },
      gapChild: {
        marginHorizontal: 10,
      },
      rightContainer: {
        flex: 5,
        justifyContent: 'space-between',
      },
      chips: {
        marginTop: 16,
        paddingHorizontal: 24,
        flexDirection: 'row',
      },
      chipContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
      },
      orderInfo: {
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: 12,
      },
      orderIdentifier: {
        fontSize: 20,
        fontFamily: theme.appFonts.medium,
        color: theme.appColors.GRAY_900,
      },
      orderSubtotal: {
        marginLeft: 'auto',
        fontSize: 20,
        fontFamily: theme.appFonts.medium,
        color: theme.appColors.GRAY_900,
      },
      flexRow: {
        flexDirection: 'row',
      },
      nameTitle: {
        fontSize: theme.fontSize.FONT_12,
        color: theme.colors.primary,
        fontWeight: 'bold',
      },
      customerName: {
        fontSize: theme.fontSize.FONT_16,
        color: theme.colors.black,
      },
      hideBackground: {
        backgroundColor: theme.colors.background,
        borderWidth: 0,
      },
    });
  }
}
