import { configureFonts, DefaultTheme } from 'react-native-paper';

const fonts = {
  regular: {
    fontFamily: 'Poppins-Regular',
    fontWeight: 'normal',
  },
  medium: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
  },
  light: {
    fontFamily: 'Poppins-Light',
    fontWeight: '300',
  },
  bold: {
    fontFamily: 'Poppins-SemiBold',
    fontWeight: '600',
  },
};

const fontConfig = {
  web: fonts,
  ios: fonts,
  android: fonts,
};

export const Theme = {
  ...DefaultTheme,
  fonts: configureFonts(fontConfig),
  appFonts: {
    thin: 'Inter_100Thin',
    extraThin: 'Inter_200ExtraLight',
    light: 'Inter_300Light',
    regular: 'Inter_400Regular',
    medium: 'Inter_500Medium',
    semiBold: 'Inter_600SemiBold',
    bold: 'Inter_700Bold',
    extraBold: 'Inter_800ExtraBold',
    black: 'Inter_900Black',
  },
  fontSize: {
    FONT_10: 10,
    FONT_12: 12,
    FONT_14: 14,
    FONT_16: 16,
    FONT_20: 20,
    FONT_24: 24,
  },
  appColors: {
    BASE_WHITE: '#ffffff',
    BASE_BLACK: '#000000',
    GRAY_900: '#101828',
    GRAY_800: '#1D2939',
    GRAY_700: '#344054',
    GRAY_600: '#475467',
    GRAY_500: '#667085',
    GRAY_400: '#98A2B3',
    GRAY_300: '#D0D5DD',
    GRAY_200: '#EAECF0',
    GRAY_100: '#F2F4F7',
    GRAY_50: '#F9FAFB',
    GRAY_25: '#FCFCFD',
    PRIMARY_900: '#0B4A6F',
    PRIMARY_800: '#065986',
    PRIMARY_700: '#026AA2',
    PRIMARY_600: '#0086C9',
    PRIMARY_500: '#0BA5EC',
    PRIMARY_400: '#36BFFA',
    PRIMARY_300: '#7CD4FD',
    PRIMARY_200: '#B9E6FE',
    PRIMARY_100: '#E0F2FE',
    PRIMARY_50: '#F0F9FF',
    PRIMARY_25: '#F5FBFF',
    SUCCESS_400: '#2ED3B7',
    SUCCESS_500: '#15B79E',
    ERROR_900: '#7A271A',
    ERROR_800: '#912018',
    ERROR_700: '#B42318',
    ERROR_600: '#D92D20',
    ERROR_500: '#F04438',
    ERROR_400: '#F97066',
    ERROR_300: '#FDA29B',
    ERROR_200: '#FECDCA',
    ERROR_100: '#FEE4E2',
    ERROR_50: '#FEF3F2',
    ERROR_25: '#FFFBFA',
    WARNING_900: '#7A2E0E',
    WARNING_800: '#93370D',
    WARNING_700: '#B54708',
    WARNING_600: '#DC6803',
    WARNING_500: '#F79009',
    WARNING_400: '#FDB022',
    WARNING_300: '#FEC84B',
    WARNING_200: '#FEDF89',
    WARNING_100: '#FEF0C7',
    WARNING_50: '#FFFAEB',
    WARNING_25: '#FFFCF5',
  },
  colors: {
    white: '#FFFFFF',
    black: '#282B31',
    orange: '#FF9300',
    warning: '#F5841B',
    fadingWarning: '#FFECCE',
    rippleWarning: '#FFECCE33',
    purple: '#BA28FF',
    fadingPurple: '#BA28FF1F',
    green: '#79CE43',
    fadingGreen: '#79CE431F',
    primary: '#0058FF',
    primary05: 'rgba(9, 87, 235, 0.05)',
    light: '#0000000F',
    accent: '#FFF',
    surface: '#FFF',
    onSurface: '#000',
    primaryLight: 'rgba(0, 98, 255, 0.05)',
    lightBlue: '#008ECC',
    fadingBlue: '#0062FF1F',
    whiteBlue: '#0058FF0A',
    fadingOrange: '#FF93001F',
    btn: '#3C58FF',
    red: 'red',
    background: '#F5F7F7',
    backgroundColor: '#F5F7F7',
    borderColor: '#DADCE0',
    lightText: '#282B3199',
    darkText: '#b7b8ba',
    danger: '#FF3138',
    danger05: 'rgba(234, 59, 59, 0.05)',
  },
};

export default Theme;
