import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import GeneralFooter from '../../../../components/GeneralFooter';
import { useRestaurant } from '../../../../redux/ducks/User/UserReducer';
import useStyles from '../../../../utils/useStyles';
import { formatMoney } from '../../../../utils/CurrencyUtils';
import Stylesheet from './OrderHistoryFooter.styles';

function OrderHistoryFooter({ orderAmount, total, ...props }) {
  const restaurant = useRestaurant();
  const styles = useStyles(Stylesheet);
  return (
    <GeneralFooter {...props} style={styles.container}>
      <View style={styles.orderData}>
        {orderAmount != null && (
          <View style={styles.leftContainer}>
            <Text style={styles.orderAmount}>{orderAmount} orders</Text>
          </View>
        )}
        {total != null && (
          <View style={styles.rightContainer}>
            <Text style={styles.total}>{formatMoney(total)}</Text>
          </View>
        )}
      </View>
      <View style={{ height: 40 }}>
        <Text style={styles.title}>{restaurant?.services?.delivery?.name}</Text>
        <Text style={styles.message}>
          {restaurant?.services?.delivery?.phone}
        </Text>
      </View>
    </GeneralFooter>
  );
}

OrderHistoryFooter.propTypes = {
  orderAmount: PropTypes.number,
  total: PropTypes.number,
};

export default OrderHistoryFooter;
