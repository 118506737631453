import snakeToCamel from '../utils/CaseUtils/snakeToCamel';
import { isNetworkError } from '../utils/ReactUtils';
import { getInstance } from '../utils/AxiosInterceptors';

const axios = () => getInstance();

export const doLogin = async ({ username, password }) => {
  try {
    const response = await axios().post('/account/authorize', {
      username,
      password,
    });
    return snakeToCamel(response?.data);
  } catch (err) {
    console.error(err);
    if (isNetworkError(err)) {
      throw new Error('Unable to connect to the server');
    }

    throw snakeToCamel(err?.response?.data);
  }
};

export const getProfile = async () => {
  try {
    const response = await axios().get('/account/profile');
    return snakeToCamel(response?.data);
  } catch (err) {
    console.error(err);
    throw snakeToCamel(err?.response);
  }
};

export const doRefresh = async ({ token }) => {
  try {
    const response = await axios().post('/account/refresh', {
      token,
    });
    return snakeToCamel(response?.data);
  } catch (err) {
    console.error(err);
    if (isNetworkError(err)) {
      throw new Error('Unable to connect to the server');
    }

    throw snakeToCamel(err?.response?.data);
  }
};
