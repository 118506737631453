import { useCallback } from 'react';

export const computeCustomSelection = (
  currentValue,
  option,
  isMultiple,
  allowSingleUnselect
) => {
  if (isMultiple) {
    const selectedOptions = currentValue || [];
    return selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
  }
  const selectedOption = currentValue || null;

  if (allowSingleUnselect && selectedOption === option) {
    return null;
  }
  return option;
};
function useSelectFields({
  onChange,
  value,
  name,
  isMultiple = false,
  allowSingleUnselect = true, // Only relevant when isMultiple is false
}) {
  const isSelected = useCallback(
    (optionId) =>
      isMultiple ? value?.includes?.(optionId) : value === optionId,
    [isMultiple, value]
  );
  const handleSelection = (optionId) => {
    const computedValue = computeCustomSelection(
      value,
      optionId,
      isMultiple,
      allowSingleUnselect
    );
    onChange(name, computedValue);
  };
  return { isSelected, handleSelection };
}
export default useSelectFields;
