import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootRoutes } from '../../constants/routes';
import SettingsMenu from '../../components/SettingsMenu';
import {
  restaurantOptionsState,
  selectRestaurant,
} from '../../redux/ducks/User/UserReducer';

function RestaurantSelector() {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const availableRestaurantsList = useSelector(restaurantOptionsState);

  useEffect(() => {
    if (availableRestaurantsList?.length === 1) {
      navigate(RootRoutes.DASHBOARD.name);
    }
  }, []);

  const handleSelectRestaurant = (restaurantId) => {
    dispatch(
      selectRestaurant({
        id: restaurantId,
      })
    );
    navigate(RootRoutes.DASHBOARD.name);
  };

  return (
    <ScrollView testID="restaurant-selector-page">
      <SafeAreaView style={{ margin: 15 }}>
        <SettingsMenu>
          {availableRestaurantsList?.map((restaurant) => (
            <SettingsMenu.Item
              key={restaurant.id}
              title={restaurant.name}
              isLink
              onPress={() => handleSelectRestaurant(restaurant.id)}
            />
          ))}
        </SettingsMenu>
      </SafeAreaView>
    </ScrollView>
  );
}

export default RestaurantSelector;
