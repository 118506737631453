import React, { useCallback } from 'react';
import { View, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import FooterLoading from '../FooterLoading';
import OrderCard from '../OrderCard';
import RefreshControl from '../RefreshControl';
import LoadingScreen from '../LoadingScreen';

export const createRenderItem = ({
  onActionPress,
  onItemPress,
  hideStatusBadges,
}) => {
  function render({ item }) {
    return (
      <>
        <OrderCard
          {...item}
          hideStatusBadges={hideStatusBadges}
          onActionPress={onActionPress}
          onPress={onItemPress}
        />
        <View style={{ marginTop: 16 }} />
      </>
    );
  }
  render.propTypes = { item: PropTypes.any };
  return render;
};

export const keyExtractor = (item) => String(item?.id);

function OrdersList({
  data,
  onActionPress,
  onItemPress,
  hideStatusBadges,
  isLoading,
  isFirstLoading,
  refetch,
  fetchNextPage,
  isFetchingNextPage,
  emptyComponent: EmptyComponent,
}) {
  const renderItem = useCallback(
    createRenderItem({ onActionPress, onItemPress, hideStatusBadges }),
    [onActionPress, onItemPress]
  );
  if (isFirstLoading) {
    return <LoadingScreen />;
  }

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      testID="orders-list-container"
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={refetch} />
      }
      keyExtractor={keyExtractor}
      ListHeaderComponent={<View style={{ marginTop: 16 }} />}
      data={data}
      renderItem={renderItem}
      onEndReached={fetchNextPage}
      onEndReachedThreshold={0.1}
      ListFooterComponent={<FooterLoading loading={isFetchingNextPage} />}
      ListEmptyComponent={EmptyComponent ? <EmptyComponent /> : null}
    />
  );
}

OrdersList.propTypes = {
  isLoading: PropTypes.bool,
  isFirstLoading: PropTypes.bool,
  refetch: PropTypes.func,
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  onActionPress: PropTypes.func,
  onItemPress: PropTypes.func,
  hideStatusBadges: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      customer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        phone: PropTypes.string,
      }),
      status: PropTypes.string,
      driver: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        phone: PropTypes.string,
      }),
      total: PropTypes.number,
      progress: PropTypes.shape({
        placed: PropTypes.number,
        received: PropTypes.number,
        confirmed: PropTypes.number,
        completed: PropTypes.number,
      }),
      schedule: PropTypes.shape({
        pickup: PropTypes.number,
      }),
    })
  ),
  emptyComponent: PropTypes.func,
};

export default OrdersList;
