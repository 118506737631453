import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      textInput: {
        backgroundColor: theme.colors.white,
        marginTop: 20,
        height: 50,
        lineHeight: 17,
      },
    });
  }
}
