/* eslint-disable no-use-before-define */
import posPrinter from '../../PosPrinter';
import {
  calculateChargedSubtotal,
  calculateTotalQuantity,
} from '../../../pages/OrderDetails/OrderDetails.controller';
import { formatMoney } from '../../CurrencyUtils';
import DateTZ from '../../DateUtils';
import PosEncoder from '../PosEncoder';

const spaceString = (length, character = ' ') =>
  Array(length).fill(character).join('');

const wordWrap = ({ str, width = 26, brk = '' }) => {
  if (!str) return str;

  const words = str.split(' ');

  const lines = [];
  let lineLength = 0;
  let line = '';

  words.forEach((word) => {
    lineLength += word.length + 1;

    if (lineLength >= width && line !== '') {
      const finalSpaces = spaceString(width - line.length);

      lines.push(line + finalSpaces);

      line = '';
      lineLength = word.length + 1;
    }

    if (word.length > width) {
      const substring = `${word.substring(0, width - 1)}-`;
      lines.push(substring);

      line = `${word.substring(width - 1)} `;
      lineLength = line.lenght;
    } else {
      line += `${word} `;
    }
  });

  lines.push(line);

  return lines.join(brk);
};

export const orderDetails = async ({ order, restaurant }) => {
  const posEncoder = new PosEncoder({
    printerInstance: posPrinter,
  });
  await posEncoder.loadCharsPerLine();
  // posEncoder.setTabWidth(1);
  const tableDescriptionIdentation = 5;
  const priceTableRatios = {
    left: 0.8,
    right: 0.2,
  };

  const addIdentation = (identLevel, text) => {
    const spaces = spaceString(
      identLevel * 2 + (tableDescriptionIdentation - 2)
    );

    return `-${spaces}${text}`;
    // spaces.forEach(() => posEncoder.horizontalTab());
  };

  const divider = (separator = '_') =>
    posEncoder
      .bold()
      .printHalfTable('', '', priceTableRatios, separator)
      .bold();

  const renderBoldedText = (text) => posEncoder.bold().text(text).bold();
  const renderItalicText = (text) => posEncoder.italic().text(text).italic();

  const renderAnswer = (answer, identLevel) => {
    const breaker = '__NEW_LINE__';
    const answerIdentationText = addIdentation(identLevel, '');
    const wrappedAnswerLength = 34 - identLevel;

    const leading =
      answer?.amount && answer?.amount > 1 ? `${answer.amount}x` : '';

    const answerLines = wordWrap({
      str: `${leading} ${answer?.text?.toUpperCase()}`,
      brk: `${breaker}`,
      width: wrappedAnswerLength,
    }).split(breaker);

    answerLines?.forEach((line) => {
      renderBoldedText(`${answerIdentationText}${line}`);
    });

    if (answer?.notes) {
      const answerNodeIdentationText = addIdentation(identLevel + 1, '');
      const wrappedAnswerLen = 34 - identLevel - 1;

      const notesText = `${answer?.notes.replace(/\n/g, ' ')}`;
      const noteLines = wordWrap({
        str: `${notesText}`,
        brk: `${breaker}`,
        width: wrappedAnswerLen,
      }).split(breaker);

      noteLines?.forEach((line) => {
        posEncoder.newline();
        renderBoldedText(`${answerNodeIdentationText}${line}`);
      });
    }

    posEncoder.newline();

    answer?.subQuestions?.forEach((subQuestion) => {
      renderQuestion(subQuestion, identLevel + 1);
    });
  };

  const renderQuestion = (question, identLevel) => {
    const identationText = addIdentation(identLevel, '');
    const text = wordWrap({
      str: `${identationText}${question.text}`,
      brk: `${identationText}`,
      width: 40,
    });
    posEncoder.text(text);
    posEncoder.newline();
    question?.answers?.forEach((answer) => {
      renderAnswer(answer, identLevel + 1);
    });
  };

  const renderInitilization = () => posEncoder.initialize().newline().newline();

  const renderRestaurantHeader = (restaurantData) => {
    posEncoder
      .align('center')
      .bold()
      .text(restaurantData?.name)
      .bold()
      .newline()
      .text(restaurantData?.phone)
      .align('left');
  };
  const renderOrderType = (orderData) => {
    divider().newline();
    if (orderData?.type === 'takeout') {
      posEncoder.multiplySize(3);
      posEncoder.bold().text('Takeout').bold().newline();
      posEncoder.multiplySize(3);
    } else {
      posEncoder.multiplySize(3);
      posEncoder.bold().text('Delivery').bold().newline();
      posEncoder.multiplySize(3);
    }
    divider().newline();
  };

  const renderInfo = (type, value) =>
    posEncoder.bold().text(type).bold().newline().text(value);

  renderInitilization();
  renderRestaurantHeader(restaurant);
  posEncoder.newline().newline();
  posEncoder.align('center');
  posEncoder
    .bold()
    .multiplySize(2)
    .text(`#${order?.id}`)
    .multiplySize(2)
    .bold()
    .newline();

  renderOrderType(order);

  posEncoder.text('Ready to pickup by: ').newline();

  if (DateTZ.isAfterToday(order?.schedule?.pickup, restaurant?.timezone)) {
    posEncoder
      .bold()
      .multiplySize(2)
      .text(
        DateTZ.getFormattedFullDate(
          order?.schedule?.pickup,
          restaurant?.timezone
        )
      )
      .multiplySize(2)
      .bold()
      .newline();
  } else {
    posEncoder
      .text(
        DateTZ.getFormattedFullDate(
          order?.schedule?.pickup,
          restaurant?.timezone
        )
      )
      .newline();
  }

  posEncoder
    .bold()
    .multiplySize(2)
    .text(
      DateTZ.getFormattedTime(order?.schedule?.pickup, restaurant?.timezone)
    )
    .multiplySize(2)
    .bold()
    .newline();

  divider();
  if (order?.driver) {
    renderInfo('Driver: ', `${order?.driver?.name}`);
    renderItalicText(` - ${order?.driver?.phone}`).newline();
  }
  renderInfo('Customer: ', `${order?.customer?.name}`);
  renderItalicText(` - ${order?.customer?.phone}`).newline();

  posEncoder.align('left');
  posEncoder.newline();
  posEncoder.newline();

  posEncoder
    .bold()
    .text(`${calculateTotalQuantity(order?.items)} Items`)
    .bold();
  if (order?.items?.some((item) => item?.notes)) {
    posEncoder.text(' - contains special instructions');
  }
  posEncoder.newline();
  posEncoder.printHalfTable('Qty. Description', 'Price', priceTableRatios, ' ');
  divider();
  order.items.forEach(({ amount, name, price, questions, notes }) => {
    const identationForAmount = spaceString(
      tableDescriptionIdentation - `${amount}x`.length
    );

    const identationNewLine = `-${spaceString(tableDescriptionIdentation - 1)}`;

    posEncoder
      .bold()
      .printHalfTable(
        `${amount}x${identationForAmount}${wordWrap({
          str: name,
          brk: identationNewLine,
        })}`,
        formatMoney((price.charged || 0) * (amount || 1)),
        priceTableRatios,
        ' '
      )
      .bold();
    if (notes) {
      const formattedNotes = notes.replace(/\n/g, ' ');

      const text = addIdentation(1, 'Instructions:');
      const indentation = addIdentation(2, '');
      const breaker = '__NEW_LINE__';
      renderItalicText(text);

      posEncoder.newline();

      const wrappedNotes = wordWrap({
        str: `${formattedNotes}`,
        brk: `${breaker}`,
        width: 30,
      }).split(breaker);

      wrappedNotes?.forEach((line) => {
        renderBoldedText(`${indentation}${line}`);
        posEncoder.newline();
      });

      posEncoder.newline();
    }
    questions?.forEach((question) => renderQuestion(question, 1));
    divider();
  });

  posEncoder.bold();
  posEncoder.printHalfTable(
    'Subtotal',
    formatMoney(calculateChargedSubtotal(order?.items)),
    priceTableRatios,
    ' '
  );
  posEncoder.bold();

  if (restaurant?.services?.delivery?.name)
    posEncoder
      .newline()
      .align('center')
      .text(restaurant?.services?.delivery?.name)
      .newline()
      .newline()
      .newline()
      .newline();

  divider(' ').newline();

  posEncoder.cut('partial');

  const { encoded, html } = posEncoder.encode();
  return { encoded, html };
};

export default orderDetails;
