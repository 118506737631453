import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text } from 'react-native-paper';
import useStyles from '../../../../utils/useStyles';
import { Theme } from '../../../../constants/theme';
import StyleSheets from './MenuListTitle.styles';

function MenuListTitle(props) {
  const { name, onPress, testId } = props;
  const styles = useStyles(StyleSheets);

  return (
    <View>
      <TouchableOpacity
        testID={`list-title-button-${testId}`}
        style={styles.simpleRow}
        onPress={onPress}
      >
        <Icon
          style={styles.leftIcon}
          color={Theme.colors.black}
          name="arrow-left"
        />
        <Text style={styles.pageTitle}>{name}</Text>
      </TouchableOpacity>
      <View style={styles.divider} />
    </View>
  );
}

MenuListTitle.propTypes = {
  name: PropTypes.string,
  onPress: PropTypes.func,
  testId: PropTypes.string,
};

export default MenuListTitle;
