import React, { useCallback, useMemo } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useTheme } from 'react-native-paper';
import { useSelector } from 'react-redux';
import Header from '../components/Header';
import { RootRoutes, useDrawerRoutes, userTypes } from '../constants/routes';
import DrawerContent from '../components/DrawerContent';
import {
  restaurantOptionsState,
  useRestaurant,
} from '../redux/ducks/User/UserReducer';

const DrawerNav = createDrawerNavigator();

const useRouteFilter = (routesObject) => {
  const availableRestaurantsList = useSelector(restaurantOptionsState);

  const filterOutRestaurantSelector = useCallback((routes) => {
    if (availableRestaurantsList?.length === 1) {
      return routes.filter(
        (item) => item.name !== RootRoutes.RESTAURANT_SELECTOR.name
      );
    }
    return routes;
  }, []);

  const filteredRoutes = useMemo(
    () =>
      [filterOutRestaurantSelector].reduce(
        (previousResult, currentFilter) => currentFilter(previousResult),
        routesObject.routes
      ),
    [routesObject.routes, filterOutRestaurantSelector]
  );

  return filteredRoutes;
};

function Drawer() {
  const theme = useTheme();

  const routesObject = useDrawerRoutes(userTypes.ADMIN);
  const routes = useRouteFilter(routesObject);

  const restaurant = useRestaurant();

  return (
    <DrawerNav.Navigator
      drawerContent={(props) => (
        <DrawerContent {...props} title={routesObject.title} theme={theme} />
      )}
      screenOptions={{
        headerMode: 'screen',
        lazy: true,
        header: ({ previous, navigation, ...scene }) => (
          <Header scene={scene} previous={previous} navigation={navigation} />
        ),
      }}
    >
      {routes?.map(({ name, component, options }) => {
        const opts = {
          ...options,
          title: options?.title ? options.title : restaurant?.name ?? '',
        };

        return (
          <DrawerNav.Screen
            key={name}
            name={name}
            component={component}
            options={opts}
          />
        );
      })}
    </DrawerNav.Navigator>
  );
}

export default Drawer;
