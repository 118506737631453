import { StyleSheet } from 'react-native';
import { isTablet } from 'react-native-device-info';

export default class StyleSheets {
  static getSheet(themes) {
    return StyleSheet.create({
      container: {
        flex: 1,
        backgroundColor: themes.colors.backgroundColor,
      },
      keyboard: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        width: isTablet() ? '80%' : '90%',
        paddingVertical: '8%',
        paddingHorizontal: '5%',
        borderColor: themes.colors.borderColor,
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#FFF',
      },
      signInText: {
        fontSize: themes.fontSize.FONT_20,
        fontFamily: themes.fonts.regular.fontFamily,
        color: themes.colors.black,
        alignSelf: 'center',
        marginTop: 10,
      },
      logo: {
        width: 45,
        height: 45,
        alignSelf: 'center',
      },
      snackBar: {
        color: '#FFF',
        backgroundColor: '#f00',
      },
      versionText: {
        position: 'absolute',
        bottom: 15,
        alignSelf: 'center',
        color: themes.colors.black,
        opacity: 0.3,
      },
      errorText: {
        color: themes.colors.red,
        marginTop: 3,
      },
    });
  }
}
