import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        maxWidth: '30%',
        flexDirection: 'row',
        alignItems: 'center',
      },
      text: {
        lineHeight: 30,
        fontSize: 16,
        marginLeft: 20,
      },
    });
  }
}
