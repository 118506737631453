import {
  useQuery as RQUseQuery,
  useInfiniteQuery as RQUseInfiniteQuery,
} from 'react-query';

import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import useAsyncFunction from '../../hooks/useAsyncFunction';

export * from 'react-query';

export const useEnhanceQueryFocusEffect = (useQueryReturn, options) => {
  useFocusEffect(
    useCallback(() => {
      if (
        ((options?.refetchOnWindowFocus && useQueryReturn.isStale) ||
          options?.refetchOnWindowFocus === 'always') &&
        options.enabled !== false
      )
        useQueryReturn.refetch();
    }, [options?.enabled, options?.refetchOnWindowFocus])
  );
};

export function useQuery(queryKey, queryFn, options) {
  const useQueryReturn = RQUseQuery(queryKey, queryFn, options);
  useEnhanceQueryFocusEffect(useQueryReturn, options);
  const [refetch, isRefetching] = useAsyncFunction(useQueryReturn.refetch);
  return { ...useQueryReturn, refetch, isRefetching };
}

export function useInfiniteQuery(queryKey, queryFn, options) {
  const useQueryReturn = RQUseInfiniteQuery(queryKey, queryFn, options);
  useEnhanceQueryFocusEffect(useQueryReturn, options);
  const [refetch, isRefetching] = useAsyncFunction(useQueryReturn.refetch);
  return { ...useQueryReturn, refetch, isRefetching };
}
