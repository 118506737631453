import React, { useCallback, useMemo } from 'react';
import { ScrollView, View } from 'react-native';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { Dialog, Portal, useTheme } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import DateTZ, { getDateRanges } from '../../utils/DateUtils';
import SettingsMenu from '../SettingsMenu';
import {
  useRestaurant,
  deleteMenuItem,
} from '../../redux/ducks/User/UserReducer';

export const save = (
  dispatch,
  deleteItem,
  selectedRestaurant,
  selectedItem,
  hideModal,
  payload
) => {
  dispatch(
    deleteItem({
      restId: selectedRestaurant?.id,
      menuId: selectedItem.menuId,
      item: selectedItem.item,
      id: selectedItem.item.id,
      ...payload,
    })
  );
  hideModal();
};

export const onConfirm = (
  dispatch,
  deleteItem,
  selectedRestaurant,
  selectedItem,
  hideModal,
  onSave,
  onDismiss,
  { startDate, endDate }
) => {
  const startTimestamp = DateTZ.toTimestamp(DateTZ.fromJSDate(startDate));
  const endTimestamp = DateTZ.toTimestamp(DateTZ.fromJSDate(endDate));
  const selectedDateRange = {
    start: DateTZ.changeToTimezoneWithSameLocalTime(
      startTimestamp,
      selectedRestaurant?.restaurantTimezone
    ),
    end: DateTZ.changeToTimezoneWithSameLocalTime(
      endTimestamp,
      selectedRestaurant?.restaurantTimezone
    ),
  };

  onDismiss();
  onSave(
    dispatch,
    deleteItem,
    selectedRestaurant,
    selectedItem,
    hideModal,
    selectedDateRange
  );
};

function DeleteRangeMenu({ isOpen, closeMenu, selectedItem }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const restaurant = useRestaurant();
  const restaurantTimezone = restaurant?.timezone;

  const defaultDateRanges = useMemo(
    () => getDateRanges(restaurantTimezone),
    [restaurantTimezone]
  );

  const items = useMemo(
    () => [
      {
        label: 'Today',
        value: {
          start: defaultDateRanges?.today.range.startDate,
          end: defaultDateRanges?.today.range.endDate,
        },
      },
      { label: 'Until I Add It Back' },
      { label: 'Remove Forever', value: { permanent: true } },
    ],
    [restaurantTimezone, defaultDateRanges]
  );

  const [customDatePickerOpen, setCustomDatePickerOpen] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setCustomDatePickerOpen(false);
  }, [setCustomDatePickerOpen]);

  const itemStyle = useCallback(
    () => ({
      backgroundColor: theme.colors.white,
    }),
    []
  );

  return (
    <View>
      <View testID="date-picker-modal-container">
        <DatePickerModal
          mode="range"
          visible={customDatePickerOpen}
          onDismiss={onDismiss}
          onConfirm={(range) =>
            onConfirm(
              dispatch,
              deleteMenuItem,
              restaurant,
              selectedItem,
              closeMenu,
              save,
              onDismiss,
              range
            )
          }
        />
      </View>

      <Portal>
        <Dialog
          testID="select-range-dialog"
          style={{ paddingLeft: 0 }}
          visible={isOpen}
          onDismiss={closeMenu}
        >
          <Dialog.Title style={{ color: 'black' }}>
            Select 86 Period - {selectedItem?.item.name}
          </Dialog.Title>
          <Dialog.ScrollArea style={{ paddingHorizontal: 0, paddingTop: 0 }}>
            <ScrollView>
              {items.map((item) => (
                <SettingsMenu.Item
                  testID="select-range-menu-item"
                  onPress={() =>
                    save(
                      dispatch,
                      deleteMenuItem,
                      restaurant,
                      selectedItem,
                      closeMenu,
                      item.value
                    )
                  }
                  style={itemStyle(item)}
                  title={item.label}
                  key={item.label}
                />
              ))}
              <SettingsMenu.Item
                testID="select-range-menu-item"
                isLink
                style={itemStyle('custom')}
                onPress={() => {
                  setCustomDatePickerOpen(true);
                }}
                title="Custom Range"
              />
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
    </View>
  );
}

DeleteRangeMenu.propTypes = {
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func,
  selectedItem: PropTypes.object,
};

export default DeleteRangeMenu;
