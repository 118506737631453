import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  discoverPrintersRequest,
  setAllowDiscovery,
} from '../../redux/ducks/Printer/PrinterReducer';
import useInterval from '../useInterval';

export const usePrinterDiscovery = () => {
  const dispatch = useDispatch();
  const isDiscovering = useSelector((state) => state.printer.isDiscovering);
  const allowDiscovery = useSelector((state) => state.printer.allowDiscovery);

  const startDiscovery = () => dispatch(discoverPrintersRequest());
  const toggleAllowDiscovery = () =>
    dispatch(setAllowDiscovery(!allowDiscovery));

  const DISCOVER_INTERVAL = allowDiscovery ? 10000 : null;

  useInterval(() => startDiscovery(), DISCOVER_INTERVAL);

  useEffect(() => {
    if (allowDiscovery) {
      startDiscovery();
    }
  }, [allowDiscovery]);

  return {
    isDiscovering,
    startDiscovery,
    allowDiscovery,
    toggleAllowDiscovery,
  };
};

export default usePrinterDiscovery;
