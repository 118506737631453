import { all, fork } from 'redux-saga/effects';
import { watchUser } from './User/UserSaga';
import { watchRestaurant } from './Restaurant/RestaurantSaga';
import { watchPrinter } from './Printer/PrinterSaga';
import { watchSettings } from './Settings/SettingsSaga';

export default function* rootSaga() {
  yield all([
    fork(watchUser),
    fork(watchRestaurant),
    fork(watchPrinter),
    fork(watchSettings),
  ]);
}
