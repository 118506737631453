import { StyleSheet } from 'react-native';
import { Theme } from '../../constants/theme';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        marginHorizontal: -4,
      },
      buttonText: {
        fontWeight: '500',
        fontSize: 14,
        color: Theme.appColors.GRAY_700,
        textAlign: 'center',
      },
      buttonTextActive: {
        color: Theme.appColors.GRAY_800,
      },
      buttonContainer: {
        borderRadius: 8,
        borderColor: Theme.appColors.GRAY_300,
        borderStyle: 'solid',
        borderWidth: 1,
        width: 'auto',
        paddingHorizontal: 8,
        marginHorizontal: 4,
        flex: 1,
      },
      buttonContainerActive: {
        borderRadius: 8,
        backgroundColor: Theme.appColors.PRIMARY_50,
        borderColor: Theme.appColors.PRIMARY_300,
      },
    });
  }
}
