import React from 'react';
import { useNavigation } from '@react-navigation/native';
import Button from '../../../../components/Button';
import { RootRoutes } from '../../../../constants/routes';

const useRightMenu = () => {
  const navigation = useNavigation();

  const goToDashboard = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: RootRoutes.DASHBOARD.name }],
    });
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (
        <Button testID="showMenu" onPress={goToDashboard}>
          Dashboard
        </Button>
      ),
    });
  }, [navigation]);
};

export default useRightMenu;
