import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      containerStyle: {
        paddingVertical: 4,
        paddingHorizontal: 0,
      },
      textStyle: {
        color: theme.appColors.WARNING_500,
        fontFamily: theme.appFonts.medium,
        fontSize: 16,
      },
      iconStyle: {
        fontSize: 16,
      },
    });
  }
}
