import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      appBarHeader: {
        marginLeft: 16,
        backgroundColor: theme.colors.danger,
        cursor: 'default',
      },
      offlineText: {
        color: 'white',
      },
    });
  }
}
