import React from 'react';
import { useTheme } from 'react-native-paper';
import { RefreshControl as RNRefreshControl } from 'react-native';

function RefreshControl(props) {
  const theme = useTheme();
  return (
    <RNRefreshControl
      enabled
      colors={[theme.colors.primary]}
      tintColor={theme.colors.black}
      {...props}
    />
  );
}

export default RefreshControl;
