import snakeToCamel from '../utils/CaseUtils/snakeToCamel';
import { getInstance } from '../utils/AxiosInterceptors';

const axios = () => getInstance();

export const getTempClosingHours = async (restaurantId) => {
  const response = await axios().get(
    `/localareamng/restaurant/${restaurantId}/closing`
  );
  return snakeToCamel(response?.data);
};

export const addTempClosingHours = async (restaurantId, data) => {
  const response = await axios().post(
    `/localareamng/restaurant/${restaurantId}/closing`,
    data
  );
  return snakeToCamel(response?.data);
};

export const deleteTempClosingHours = async (restaurantId, hourId) => {
  const response = await axios().delete(
    `/localareamng/restaurant/${restaurantId}/closing/${hourId}`
  );
  return snakeToCamel(response?.data);
};
