import { useMutation, useQueryClient } from 'react-query';
import { confirmOrder } from '../../../services/RestaurantManagerPortalService';
import { shouldUseFakeData } from '../../../utils/config';
import { DropDownHolder } from '../../../components/DropDownHolder';

import DateTZ from '../../../utils/DateUtils/DateUtils';

export const handleConfirmOrder = async ({
  restaurantId,
  orderId,
  expectedTime,
  pickupSchedule,
}) => {
  const pickupTime = pickupSchedule + expectedTime;
  return confirmOrder({
    restaurantId,
    orderId,
    pickupTime,
  });
};

export const createTransformQueryData = (pickupTime) => (previousData) => ({
  ...previousData,
  progress: {
    ...previousData.progress,
    confirmed: DateTZ.getNowTimestamp(),
  },
  schedule: {
    ...previousData.schedule,
    pickup: pickupTime,
  },
});

export const createOnSettledConfirmHandler =
  ({ queryClient, queryKey }) =>
  async (data, error, variables) => {
    const pickupTime = variables.pickupSchedule + variables.expectedTime;

    if (!error || shouldUseFakeData()) {
      queryClient.invalidateQueries('orders');
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, createTransformQueryData(pickupTime));
    }
  };

export const createOnErrorConfirmHandler = () => async () => {
  if (!shouldUseFakeData())
    DropDownHolder.showAlert(
      'error',
      'Unabled to confirm order',
      'Sorry, we were unable to complete your request, please try again!'
    );
};

const useConfirmOrderDetails = (queryKey, { restaurantId, orderId }) => {
  const queryClient = useQueryClient();
  const confirmOrderMutation = useMutation(handleConfirmOrder, {
    onSettled: createOnSettledConfirmHandler({ queryClient, queryKey }),
  });
  return ({ expectedTime, pickupSchedule }) =>
    confirmOrderMutation
      .mutateAsync({
        restaurantId,
        orderId,
        expectedTime,
        pickupSchedule,
      })
      .catch(createOnErrorConfirmHandler());
};
export default useConfirmOrderDetails;
