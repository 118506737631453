import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { Button, Surface } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';

import TimeStepper from '../../../../components/TimeStepper';
import useStyles from '../../../../utils/useStyles';
import useAsyncFunction from '../../../../hooks/useAsyncFunction';
import usePrinter from '../../../../hooks/usePrinter';
import Stylesheet from './OrderDetailsBottomConfirmSheet.styles';

export const handlePrintAfterConfirmation = async ({
  data,
  shouldPrintAndConfirm,
  hasConnectedPrinters,
  handlePrint,
  enablePrintAndConfirm,
}) => {
  if (!hasConnectedPrinters) {
    await enablePrintAndConfirm(false);
  } else if (data && shouldPrintAndConfirm && hasConnectedPrinters) {
    await enablePrintAndConfirm(false);
    await handlePrint();
  }
};

export const useConfirmAndPrint = ({
  data,
  hasConnectedPrinters,
  handlePrint,
}) => {
  const [shouldPrintAndConfirm, enablePrintAndConfirm] = useState(false);

  useEffect(() => {
    handlePrintAfterConfirmation({
      data,
      hasConnectedPrinters,
      shouldPrintAndConfirm,
      handlePrint,
      enablePrintAndConfirm,
    });
  }, [data, hasConnectedPrinters, shouldPrintAndConfirm, handlePrint]);
  return {
    enablePrintAndConfirm,
  };
};

export const getConfirmText = (hasConnectedPrinters) =>
  hasConnectedPrinters ? 'Confirm and Print' : 'Confirm';

function OrderDetailsBottomConfirmSheet({
  timeConfig,
  pickupSchedule,
  onConfirmOrder,
  template,
  data,
}) {
  const styles = useStyles(Stylesheet);

  const [expectedTimeWait, setExpectedTimeWait] = useState(timeConfig?.time);

  const [handleConfirmOrder, isLoading] = useAsyncFunction(() =>
    onConfirmOrder?.({
      expectedTime: expectedTimeWait,
      pickupSchedule,
    })
  );

  const {
    actions: { handlePrint },
    state: { connectedPrinters },
  } = usePrinter({ template, data });

  const hasConnectedPrinters = Boolean(connectedPrinters?.length);

  const { enablePrintAndConfirm } = useConfirmAndPrint({
    data,
    hasConnectedPrinters,
    handlePrint,
  });

  const handleConfirmAndPrint = () => {
    const response = handleConfirmOrder();
    enablePrintAndConfirm(true);
    return response;
  };

  const confirmText = getConfirmText(hasConnectedPrinters);
  return (
    <Surface style={styles.confirmOrderSheetContainer}>
      <View style={styles.confirmIOrderSheetInnerContainer}>
        <Text style={styles.preparationTimeText}>
          Preparation Time for this Order
        </Text>
        <View style={styles.bottomSheetContainer}>
          <View>
            <TimeStepper
              skipDebounce
              loading={timeConfig?.isLoading}
              baseTime={timeConfig?.baseTime}
              time={expectedTimeWait}
              setTime={setExpectedTimeWait}
              min={timeConfig?.min}
              max={timeConfig?.max}
              step={timeConfig?.step}
            />
          </View>
          <View style={{ flex: 1 }}>
            <Button
              testID="order-details-bottom-confirm-button"
              disabled={isLoading || timeConfig?.isLoading}
              loading={isLoading}
              uppercase={false}
              mode="contained"
              contentStyle={styles.confirmButtonContent}
              style={styles.confirmButton}
              labelStyle={styles.confirmButtonLabelStyle}
              onPress={handleConfirmAndPrint}
            >
              {confirmText}
            </Button>
          </View>
        </View>
      </View>
      <SafeAreaView style={{ flex: 0 }} />
    </Surface>
  );
}

OrderDetailsBottomConfirmSheet.propTypes = {
  timeConfig: PropTypes.shape({
    baseTime: PropTypes.number,
    time: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    isLoading: PropTypes.bool,
  }),
  pickupSchedule: PropTypes.number,
  onConfirmOrder: PropTypes.func,
  template: PropTypes.func,
  data: PropTypes.any,
};

export default OrderDetailsBottomConfirmSheet;
