import { DateTime } from 'luxon';

const toTimestamp = (dateTime) => Math.round(dateTime.toMillis() / 1000);

export const getDateRanges = (timezone) => {
  const transformDate = ([startDate, endDate]) => ({
    startDate: toTimestamp(startDate),
    endDate: toTimestamp(endDate),
  });

  const dateWithTZ = DateTime.now().setZone(timezone);

  return {
    today: {
      label: 'Today',
      range: transformDate([
        dateWithTZ.startOf('day'),
        dateWithTZ.endOf('day'),
      ]),
    },
    yesterday: {
      label: 'Yesterday',
      range: transformDate([
        dateWithTZ.minus({ days: 1 }).startOf('day'),
        dateWithTZ.minus({ days: 1 }).endOf('day'),
      ]),
    },
    'this-week': {
      label: 'This Week',
      range: transformDate([
        dateWithTZ.startOf('week'),
        dateWithTZ.endOf('week'),
      ]),
    },
    'last-week': {
      label: 'Last Week',
      range: transformDate([
        dateWithTZ.minus({ weeks: 1 }).startOf('week'),
        dateWithTZ.minus({ weeks: 1 }).endOf('week'),
      ]),
    },
    'this-month': {
      label: 'This Month',
      range: transformDate([
        dateWithTZ.startOf('month'),
        dateWithTZ.endOf('month'),
      ]),
    },
    'last-month': {
      label: 'Last Month',
      range: transformDate([
        dateWithTZ.minus({ months: 1 }).startOf('month'),
        dateWithTZ.minus({ months: 1 }).endOf('month'),
      ]),
    },
    'today-plus-365': {
      label: 'Today Plus 365 Days',
      range: transformDate([
        dateWithTZ.startOf('day'),
        dateWithTZ.plus({ days: 365 }).endOf('day'),
      ]),
    },
    'tomorrow-plus-365': {
      label: 'Tomorrow Plus 365 Days',
      range: transformDate([
        dateWithTZ.plus({ days: 1 }).startOf('day'),
        dateWithTZ.plus({ days: 366 }).endOf('day'),
      ]),
    },
  };
};

export const getDateRangesOrderedValues = () => [
  'today',
  'yesterday',
  'this-week',
  'last-week',
  'this-month',
  'last-month',
];

export default getDateRanges;
