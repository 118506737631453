import { Animated } from 'react-native';

export function forVerticalInvertedIOS({
  current,
  inverted,
  layouts: { screen },
}) {
  const translateY = Animated.multiply(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [-screen.height, 0],
      extrapolate: 'clamp',
    }),
    inverted
  );

  return {
    cardStyle: {
      transform: [{ translateY }],
    },
  };
}

export default forVerticalInvertedIOS;
