import React from 'react';
import { Surface, Text } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../../../utils/useStyles';
import TimeStepper from '../../../../components/TimeStepper';
import StyleSheet from './PreparationTime.styles';

function PreparationTime(props) {
  const styles = useStyles(StyleSheet);

  return (
    <Surface style={styles.surfaceContainer}>
      <Text numberOfLines={1} style={styles.title}>
        Current Preparation Time
      </Text>
      <TimeStepper {...props} />
    </Surface>
  );
}

PreparationTime.propTypes = {
  time: PropTypes.number,
  setTime: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  loading: PropTypes.bool,
};
PreparationTime.defaultProps = {
  time: 0,
  step: 300,
  min: 0,
  max: 6000,
};

export default PreparationTime;
