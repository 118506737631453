import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      headerContainer: {
        marginHorizontal: 16,
        zIndex: 1,
        elevation: 1,
        shadowOffset: {
          height: 2,
        },
        top: -4,
        borderRadius: 4,
      },
      header: {
        flexDirection: 'row',
        paddingVertical: 8,
        paddingHorizontal: 16,

        justifyContent: 'space-between',
      },
      checkboxRow: { flexDirection: 'row', alignItems: 'center' },
      container: {
        flex: 1,
        marginHorizontal: 16,
      },
      text: {
        fontSize: theme.fontSize.FONT_20,
        fontFamily: theme.fonts.regular.fontFamily,
        marginBottom: 20,
      },
      footerLoadingIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        marginBottom: 30,
      },
      footer: {
        marginBottom: 30,
      },
    });
  }
}
