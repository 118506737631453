import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        flex: 1,
        backgroundColor: theme.colors.backgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
      },
      logo: {
        width: 45,
        height: 45,
        marginBottom: 25,
      },
      text: {
        fontSize: theme.fontSize.FONT_20,
        marginBottom: 20,
      },
    });
  }
}
