import React, { useEffect, useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRestaurantMenu,
  useRestaurant,
} from '../../redux/ducks/User/UserReducer';
import useStyles from '../../utils/useStyles';

import TabBar from '../../components/TabBar';
import MenuList from '../../components/MenuList';
import Loading from '../../components/LoadingScreen';
import DeleteRangeMenu from '../../components/DeleteRangeMenu';
import DeletedItems from '../../components/DeletedItems';
import StyleSheets from './Menu.styles';

export const showModal = (item, setVisible, setSelectedItem) => {
  if (item.item.status !== 'active') return;
  setVisible(true);
  setSelectedItem(item);
};

function Menu() {
  const styles = useStyles(StyleSheets);
  const dispatch = useDispatch();
  const selectedRestaurant = useRestaurant();
  const menu = useSelector((state) => state.user.restaurantMenu);
  const loading = useSelector((state) => state.user.isLoading);
  const menuQuestions = useSelector(
    (state) => state.user.restaurantQuestions || {}
  );

  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const hideModal = () => setVisible(false);

  useEffect(() => {
    const loadMenuData = async (id) => {
      if (!id) return;
      dispatch(getRestaurantMenu({ id }));
    };

    loadMenuData(selectedRestaurant?.id);
  }, [selectedRestaurant, dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <View style={styles.container} testID="menu-page">
      <SafeAreaView style={{ margin: 15, flex: 1 }}>
        <TabBar>
          <TabBar.Screen label="All items">
            <MenuList
              menu={menu || []}
              showModal={(item) => showModal(item, setVisible, setSelectedItem)}
              menuQuestions={menuQuestions}
            />
          </TabBar.Screen>
          <TabBar.Screen label="86d items">
            <DeletedItems menu={menu || []} menuQuestions={menuQuestions} />
          </TabBar.Screen>
          <TabBar.Screen label="Active items">
            <MenuList
              menu={menu || []}
              showModal={(item) => showModal(item, setVisible, setSelectedItem)}
              menuQuestions={menuQuestions}
              active
            />
          </TabBar.Screen>
        </TabBar>
      </SafeAreaView>
      <DeleteRangeMenu
        closeMenu={hideModal}
        isOpen={visible}
        selectedItem={selectedItem}
      />
    </View>
  );
}

export default Menu;
