import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import { Divider } from 'react-native-paper';

import { useNavigation } from '@react-navigation/native';
import useStyles from '../../utils/useStyles';
import PreparationTime from './components/PreparationTime';
import RestaurantStatus from './components/RestaurantStatus';
import usePreparationTime from '../../hooks/usePreparationTime';
import { RootRoutes } from '../../constants/routes';
import { useRestaurant } from '../../redux/ducks/User/UserReducer';
import withContactInfo from '../../hocs/withContactInfo';
import ConfirmedOrdersTab from './tabs/ConfirmedOrders';
import FutureOrdersTab from './tabs/FutureOrders';
import NewOrdersTab from './tabs/NewOrders';
import ToggleButtonGroup from '../../components/ToggleButtonGroup';
import {
  getNextPageParam,
  getPageResults,
  useInitialTab,
} from './Dashboard.controller';
import StyleSheets from './Dashboard.styles';
import { useInfiniteQuery } from '../../libs/ReactQueryEnhanced/ReactQueryEnhanced';
import { useNewOrderAlert } from '../../context/NewOrderAlert';
import { getOrders } from '../../services/RestaurantManagerPortalService';
import { getDashboardRefreshInterval } from '../../utils/config';
import { getDateRanges } from '../../utils/DateUtils/getDateRanges';

const REFRESH_INTERVAL = getDashboardRefreshInterval();

const fetchNewOrders = ({
  queryKey: [, { types, status, startTimestamp, endTimestamp, restaurantId }],
  pageParam,
}) =>
  getOrders({
    restaurantId,
    start: startTimestamp,
    end: endTimestamp,
    page: pageParam,
    types,
    status,
    details: 1,
    exclusive: 1,
  });

const tabOptions = [
  { label: 'New Orders', value: 'new' },
  { label: 'Confirmed Orders', value: 'confirmed' },
  { label: 'Future Orders', value: 'future' },
];

function Dashboard() {
  const styles = useStyles(StyleSheets);
  const { navigate } = useNavigation();
  const [activeTab, setActiveTab] = React.useState(tabOptions[0].value);
  const tabLabel = useMemo(
    () => tabOptions.find((option) => option.value === activeTab).label,
    [activeTab]
  );

  useInitialTab({ setActiveTab });

  const restaurant = useRestaurant();
  const restaurantId = restaurant?.id;
  const restaurantTimezone = restaurant?.timezone;
  const dateRange = useMemo(
    () => getDateRanges(restaurantTimezone).today.range,
    [restaurantTimezone]
  );

  const {
    time: preparationTime,
    update: updatePreparationTime,
    isLoading: isLoadingPreparationTime,
    min: minPreparationTime,
    max: maxPreparationTime,
    step: stepPreparationTime,
  } = usePreparationTime(restaurantId);

  const queryKey = [
    'orders',
    {
      types: ['delivery', 'takeout'],
      status: 'received',
      startTimestamp: dateRange.startDate,
      endTimestamp: dateRange.endDate,
      restaurantId,
    },
  ];

  const { updateOrdersList } = useNewOrderAlert();

  useInfiniteQuery(queryKey, fetchNewOrders, {
    getNextPageParam,
    refetchOnWindowFocus: true,
    refetchInterval: REFRESH_INTERVAL,
    onSuccess: (event) => {
      const ordersList = getPageResults(event);
      updateOrdersList(ordersList);
    },
  });

  const goToOrderDetails = ({ orderId }) => {
    navigate(RootRoutes.ORDER_DETAILS.name, {
      restaurantId,
      orderId,
    });
  };

  return (
    <View style={styles.container} testID="dashboard-page">
      <View style={styles.headerContainer}>
        <PreparationTime
          loading={isLoadingPreparationTime}
          time={preparationTime}
          setTime={updatePreparationTime}
          min={minPreparationTime}
          max={maxPreparationTime}
          step={stepPreparationTime}
        />
        <RestaurantStatus
          restaurantId={restaurantId}
          timezone={restaurant?.timezone}
        />
      </View>

      <Divider />
      <ToggleButtonGroup
        value={activeTab}
        setValue={setActiveTab}
        options={tabOptions}
        testID="tabs-order"
        containerStyle={{ marginTop: 20 }}
        buttonStyle={{ flexGrow: 1 }}
      />

      <Text style={styles.tabLabel}>{tabLabel}</Text>

      {activeTab === 'new' && (
        <NewOrdersTab
          restaurant={restaurant}
          goToOrderDetails={goToOrderDetails}
        />
      )}
      {activeTab === 'confirmed' && (
        <ConfirmedOrdersTab
          restaurantId={restaurantId}
          goToOrderDetails={goToOrderDetails}
        />
      )}
      {activeTab === 'future' && (
        <FutureOrdersTab
          restaurantId={restaurantId}
          goToOrderDetails={goToOrderDetails}
        />
      )}
    </View>
  );
}

export default withContactInfo(Dashboard);
