import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        backgroundColor: theme.colors.white,
        height: '95%',
        borderWidth: 1,
        borderColor: theme.colors.borderColor,
        borderRadius: 4,
      },
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 56,
        padding: 14,
      },
      simpleRow: {
        flexDirection: 'row',
        height: 56,
        padding: 14,
        paddingLeft: 0,
      },
      divider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.borderColor,
      },
      bigDivider: {
        width: '100%',
        height: 2,
        backgroundColor: theme.colors.borderColor,
      },
      title: {
        lineHeight: 28,
        fontSize: 14,
        color: theme.colors.black,
        fontWeight: 'bold',
      },
      pageTitle: {
        lineHeight: 28,
        fontSize: 14,
        color: theme.colors.black,
        fontWeight: 'bold',
      },
      rightIcon: {
        height: 14,
      },
    });
  }
}
