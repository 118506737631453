import React, { useMemo } from 'react';
import { View } from 'react-native';
import {
  Surface,
  TouchableRipple,
  Divider,
  useTheme,
} from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import OrderCardContactInfo from './components/OrderCardContactInfo';
import {
  formatFooterNoticeTime,
  timeDifferenceFromNow,
} from '../../utils/DateUtils/conversion';
import DateTZ from '../../utils/DateUtils';
import OrderCardInfo from './components/OrderCardInfo';

import OrderCardActionButton from './components/OrderCardActionButton';
import Chip from './components/Chip';
import { ChipColor } from './components/Chip/Chip.constants';
import { useRestaurant } from '../../redux/ducks/User/UserReducer';
import { formatMoney } from '../../utils/CurrencyUtils';
import { BIG_ORDER_BOUNDARY_PRICE } from '../../utils/config';

import StyleSheet from './OrderCard.styles';

function OrderCard({
  type,
  id,
  customer,
  driver,
  mode,
  progress,
  schedule,
  hideActionButton,
  onActionPress,
  onPress,
  total,
  hideBackground,
}) {
  const styles = useStyles(StyleSheet);
  const restaurant = useRestaurant();
  const theme = useTheme();

  // const receivedTimestamp = progress?.received;
  const pickupTimestamp = schedule?.pickup;
  const modeString = mode === 'asap' ? 'ASAP' : 'Target';
  const isToday = DateTZ.isToday(pickupTimestamp, restaurant?.timezone);
  const { value, unit, isLate, isClose } = useMemo(
    () => timeDifferenceFromNow(pickupTimestamp),
    [pickupTimestamp]
  );

  const handlePress =
    onPress &&
    (() => {
      onPress({ orderId: id });
    });

  return (
    <Surface
      style={{
        elevation: 2,
        borderRadius: 8,
        ...(hideBackground ? { elevation: 0 } : {}),
      }}
    >
      <TouchableRipple onPress={handlePress} testID="order-card">
        <View>
          <View style={styles.chips}>
            {!progress.completed && (
              <Chip
                title={`Pick up ${isLate ? 'over due' : `in ${value} ${unit}`}`}
                color={isClose || isLate ? ChipColor.RED : ChipColor.BLUE}
              />
            )}

            {total > BIG_ORDER_BOUNDARY_PRICE && (
              <Chip title="Big Order" color={ChipColor.LIGHT_YELLOW} />
            )}
            {!progress.confirmed && (
              <Chip
                title="New Order"
                color={ChipColor.YELLOW}
                style={{ marginLeft: 'auto' }}
              />
            )}
          </View>
          <View
            style={[
              styles.container,
              hideBackground ? styles.hideBackground : {},
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {customer && (
                <OrderCardContactInfo
                  orderId={id}
                  type="customer"
                  name={customer.name}
                  phone={customer.phone}
                  style={{ flex: 1, flexGrow: 1 }}
                />
              )}
              <Chip
                title="Pickup Time"
                subtitle={`${
                  isToday ? modeString : ''
                } ${formatFooterNoticeTime(
                  pickupTimestamp,
                  restaurant?.timezone
                )}`}
                color={ChipColor.BLACK}
                borderRadius={8}
                style={{ maxWidth: '50%' }}
              />
            </View>
            <Divider
              style={{ height: 1, backgroundColor: theme.appColors.GRAY_300 }}
            />
            <View style={[styles.orderInfoContainer, styles.gapContainer]}>
              <OrderCardInfo
                title="Order Type"
                text={type}
                style={styles.gapChild}
              />
              {/* <OrderCardInfo
                title="Received at"
                text={formatFooterNoticeTime(
                  receivedTimestamp,
                  restaurant?.timezone
                )}
              /> */}
              {Boolean(pickupTimestamp) && (
                <OrderCardInfo
                  title="Pickup at"
                  text={formatFooterNoticeTime(
                    pickupTimestamp,
                    restaurant?.timezone
                  )}
                  textColor={
                    // eslint-disable-next-line no-nested-ternary
                    isClose || isLate
                      ? 'ERROR_500'
                      : isToday
                      ? 'PRIMARY_600'
                      : 'GRAY_900'
                  }
                  style={styles.gapChild}
                />
              )}
              <OrderCardInfo
                title="Order Amount"
                text={formatMoney(total)}
                style={styles.gapChild}
              />
            </View>
            {driver && (
              <Divider
                style={{ height: 1, backgroundColor: theme.appColors.GRAY_300 }}
              />
            )}
            {driver && (
              <View style={[styles.orderInfoContainer, styles.gapContainer]}>
                <OrderCardInfo
                  title="Driver"
                  text={driver.name}
                  style={styles.gapChild}
                />
                <OrderCardInfo
                  title="Driver Info"
                  text={driver.phone}
                  style={styles.gapChild}
                />
              </View>
            )}
          </View>
          {!hideActionButton && (
            <OrderCardActionButton
              testID="order-card-action-btn"
              orderId={id}
              progress={progress}
              type={type}
              onActionPress={onActionPress}
            />
          )}
        </View>
      </TouchableRipple>
    </Surface>
  );
}

OrderCard.propTypes = {
  onActionPress: PropTypes.func,
  id: PropTypes.number,
  type: PropTypes.oneOf(['delivery', 'takeout']),
  customer: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  driver: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  total: PropTypes.number,
  progress: PropTypes.shape({
    placed: PropTypes.number,
    received: PropTypes.number,
    confirmed: PropTypes.number,
    completed: PropTypes.number,
  }),
  schedule: PropTypes.shape({
    pickup: PropTypes.number,
  }),
  onPress: PropTypes.func,
  hideActionButton: PropTypes.bool,
  hideBackground: PropTypes.bool,
};

OrderCard.defaultProps = {
  type: 'delivery',
};

export default OrderCard;
