import { useCallback, useState } from 'react';
import useCancelablePromise from '../useCancelablePromise';

const useAsyncFunction = (method) => {
  const [isLoading, setIsLoading] = useState(false);
  const makeCancelable = useCancelablePromise();
  const wrappedMethod = useCallback(
    async (...args) => {
      const asyncWrap = async (...params) => method?.(...params);
      setIsLoading(true);
      return makeCancelable(asyncWrap(...args))
        .then((response) => {
          setIsLoading(false);
          return response;
        })
        .catch((err) => {
          setIsLoading(false);
          throw err;
        });
    },
    [makeCancelable, setIsLoading, method]
  );

  return [wrappedMethod, isLoading];
};
export default useAsyncFunction;
