import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getRestaurantConfiguration,
  updateDelays,
} from '../../services/RestaurantManagerPortalService';

export const fetchPreparationTime = async (restaurantId) => {
  const restaurantConfigs = await getRestaurantConfiguration(restaurantId);
  return {
    min: restaurantConfigs?.minPreparationTime,
    max: restaurantConfigs?.maxPreparationTime,
    step: restaurantConfigs?.stepPreparationTime,
    current: restaurantConfigs?.currentPreparationTime,
  };
};

export const updatePreparationTime = async (restaurantId, timeInSeconds) => {
  updateDelays(restaurantId, {
    currentPreparationTime: timeInSeconds,
  });
};

export const fetchOrderDelays = async (restaurantId) => {
  const restaurantConfigs = await getRestaurantConfiguration(restaurantId);
  return {
    min: restaurantConfigs?.minOrderDelay,
    max: restaurantConfigs?.maxOrderDelay,
    step: restaurantConfigs?.stepOrderDelay,
    current: restaurantConfigs?.minOrderDelay,
  };
};

const useTimeStepper = (restaurantId, key, fetchValues) => {
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const {
    data: restaurantConfigs,
    isLoading: isLoadingRestaurantConfigs,
    isFetching: isFetchingRestaurantConfigs,
  } = useQuery(
    ['time-stepper', key, restaurantId],
    () => fetchValues(restaurantId),
    {
      retry: 3,
    }
  );

  const { min, max, step, current } = restaurantConfigs || {};

  useEffect(() => {
    if (current) {
      setTimeInSeconds(current);
    }
  }, [current]);

  const { mutate } = useMutation('updateDelays', (seconds) =>
    updateDelays(restaurantId, seconds)
  );

  const handleChangeTime = (seconds) => {
    if (!isLoadingRestaurantConfigs) {
      setTimeInSeconds(seconds);
      mutate(seconds);
    }
  };

  const isLoading =
    isLoadingRestaurantConfigs ||
    isFetchingRestaurantConfigs ||
    restaurantConfigs == null;

  return {
    time: timeInSeconds,
    update: handleChangeTime,
    isLoading,
    min,
    max,
    step,
  };
};

export default useTimeStepper;
