import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        flex: 1,
        paddingHorizontal: 16,
      },
      headerContainer: {
        marginVertical: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      tabLabel: {
        fontSize: theme.fontSize.FONT_20,
        fontWeight: '500',
        color: theme.appColors.GRAY_900,
        marginTop: 20,
      },
      text: {
        fontSize: theme.fontSize.FONT_20,
        fontFamily: theme.fonts.regular.fontFamily,
        marginBottom: 20,
      },
      footerLoadingIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        marginBottom: 30,
      },
      footer: {
        marginBottom: 30,
      },
    });
  }
}
