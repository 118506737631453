import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      row: {
        width: '100%',
        height: 56,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 14,
      },
      smallRow: {
        flexGrow: 1,
        flexDirection: 'row',
      },
      bigRow: {
        width: '70%',
        flexDirection: 'row',
      },
      divider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.borderColor,
      },
      eightysixed: {
        color: theme.colors.red,
        textDecorationLine: 'line-through',
      },
      text: {
        lineHeight: 56,
        fontSize: theme.fontSize.FONT_14,
        color: theme.colors.black,
      },
      itemName: {
        flexGrow: 1,
      },
      buttonLabel: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
      },
      actionButton: {
        alignItems: 'center',
        borderRadius: 2,
        paddingVertical: 8,
        flexDirection: 'row',
        width: 128,
      },
      deleteAction: {
        backgroundColor: theme.colors.danger05,
      },
      deleteText: {
        color: theme.colors.danger,
      },
      activateAction: {
        backgroundColor: theme.colors.primary05,
      },
      activateText: {
        color: theme.colors.primary,
      },
      buttonWrap: {
        alignSelf: 'center',
      },
      buttonText: {
        fontSize: 16,
        fontFamily: theme.fonts.medium.fontFamily,
        marginLeft: 8,
      },
      touchable: {
        width: '40%',
        height: 56,
      },
      mLeft: {
        marginLeft: 10,
      },
      leftIcon: {
        fontSize: 16,
        height: 16,
        width: 'auto',
        margin: 0,
        marginRight: 8,
      },
    });
  }
}
