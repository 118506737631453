import React from 'react';
import { Button } from 'react-native-paper';
import PropTypes from 'prop-types';
import { useOrderCardActionButton } from './OrderCardActionButton.controller';

function OrderCardActionButton({ orderId, progress, type, onActionPress }) {
  const { details, action, isLoading } = useOrderCardActionButton({
    orderId,
    progress,
    type,
    onActionPress,
  });

  if (!progress || !details?.text) return null;
  return (
    <Button
      testID="order-card-action-button"
      uppercase={false}
      color={details.backgroundColor}
      labelStyle={{
        color: details.color,
      }}
      mode="contained"
      style={{ marginHorizontal: 16, marginBottom: 16 }}
      onPress={action}
      disabled={isLoading}
      loading={isLoading}
    >
      {details.text}
    </Button>
  );
}
OrderCardActionButton.propTypes = {
  orderId: PropTypes.number,
  type: PropTypes.oneOf(['delivery', 'takeout']),
  progress: PropTypes.shape({
    placed: PropTypes.number,
    received: PropTypes.number,
    confirmed: PropTypes.number,
    completed: PropTypes.number,
  }),
  onActionPress: PropTypes.func,
};

export default OrderCardActionButton;
