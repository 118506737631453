import { Notifier, NotifierComponents } from 'react-native-notifier';
import { isWeb } from '../../utils/config';

export class DropDownHolder {
  static dropDown;

  static setDropDown(dropDown) {
    this.dropDown = dropDown;
  }

  static getDropDown() {
    return this.dropDown;
  }

  static showAlert(type, title, message) {
    if (isWeb) {
      Notifier.showNotification({
        title,
        description: message,
        Component: NotifierComponents.Alert,
        componentProps: {
          alertType: type, // 'info' | 'success' | 'warn' | 'error'
          titleStyle: { textAlign: 'left' },
          descriptionStyle: { textAlign: 'left' },
        },
      });
    } else {
      DropDownHolder.dropDown.alertWithType(
        type, // 'info' | 'warn' | 'error' | 'custom' | 'success';
        title,
        message
      );
    }
  }
}

export default DropDownHolder;
