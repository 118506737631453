import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      title: {
        textAlign: 'center',
        fontWeight: 'bold',
      },
      message: {
        textAlign: 'center',
      },
    });
  }
}
