import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      confirmOrderSheetContainer: {
        flexDirection: 'row',
        backgroundColor: 'white',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: theme.appColors.GRAY_300,
      },
      confirmIOrderSheetInnerContainer: {
        flex: 1,
        height: 100,
        paddingHorizontal: 24,
        paddingVertical: 14,
      },
      confirmButton: {
        backgroundColor: theme.appColors.GRAY_900,
        borderRadius: 8,
        marginLeft: 24,
      },
      confirmButtonContent: {
        height: 48,
      },
      confirmButtonLabelStyle: {
        fontFamily: theme.appFonts.medium,
        fontSize: 16,
      },
      preparationTimeText: {
        color: theme.appColors.GRAY_700,
        fontSize: 12,
        fontFamily: theme.appFonts.medium,
        marginBottom: 6,
      },
      bottomSheetContainer: {
        flex: 1,
        margin: 0,
        flexDirection: 'row',
        alignItems: 'center',
      },
    });
  }
}
