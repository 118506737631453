/* eslint-disable react/no-danger */
import { useRoute } from '@react-navigation/native';
import React from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { WebView } from 'react-native-webview';

import { isWeb } from '../../utils/config';

function WebViewWrapper() {
  const route = useRoute();
  const html = route?.params?.html;
  if (isWeb) {
    return (
      <ScrollView
        testID="scroll-view"
        style={{
          flex: 1,
          backgroundColor: '#fff',
          wordWrap: 'break-word',
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </ScrollView>
    );
  }
  return (
    <WebView
      testID="web-view"
      userAgent="Mozilla/5.0 (Linux; Android 8.0.0; Pixel 2 XL Build/OPD1.170816.004) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3714.0 Mobile Safari/537.36"
      originWhitelist={['*']}
      source={{ html }}
    />
  );
}

function ReceiptVisualizer() {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <WebViewWrapper />
      </View>
    </SafeAreaView>
  );
}

export default ReceiptVisualizer;
