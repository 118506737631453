import { createSelector } from '@reduxjs/toolkit';

export const selectSettingsState = (state) => state.settings;

export const selectTempClosingHours = createSelector(
  selectSettingsState,
  (state) => state.tempClosingHours
);

export const selectDeleteTempClosingHourId = createSelector(
  selectTempClosingHours,
  (tempClosingHours) => tempClosingHours.deleteHourId
);

export const selectTempClosingHourSaving = createSelector(
  selectTempClosingHours,
  (tempClosingHours) => tempClosingHours.saving
);
