import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        width: '100%',
        backgroundColor: '#FFF',
        borderWidth: 1,
        borderColor: '#DADCE0',
        borderRadius: 1,
        marginTop: 10,
        marginBottom: 20,
        paddingHorizontal: 12,
        paddingVertical: 5,
      },
      row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    });
  }
}
