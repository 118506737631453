import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { RootRoutes } from '../constants/routes';
import Header from '../components/Header';

const SettingsNav = createStackNavigator();

function HeaderComponent({ previous, navigation, ...scene }) {
  return <Header scene={scene} previous={previous} navigation={navigation} />;
}

function SettingsStack() {
  const routes = [
    RootRoutes.SETTINGS,
    RootRoutes.SETTINGS_PRINTER,
    RootRoutes.SETTINGS_ALERT,
    RootRoutes.SETTINGS_TEMPORARY_CLOSING_HOURS,
    RootRoutes.SETTINGS_ADD_TEMPORARY_CLOSING_HOURS,
  ];

  return (
    <SettingsNav.Navigator
      initialRouteName={RootRoutes.SETTINGS.name}
      screenOptions={{
        headerMode: 'screen',
        header: HeaderComponent,
      }}
      header={Header}
    >
      {routes.map(({ name, component, options }) => (
        <SettingsNav.Screen
          key={name}
          name={name}
          component={component}
          options={{ hideSearchBar: true, ...options }}
        />
      ))}
    </SettingsNav.Navigator>
  );
}

export default SettingsStack;
