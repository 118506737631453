import React from 'react';
import { SafeAreaView } from 'react-native';
import { useTheme, Switch } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import withContactInfo from '../../hocs/withContactInfo';
import SettingsMenu from '../../components/SettingsMenu';
import {
  setSoundAlert,
  setVisualAlert,
} from '../../redux/ducks/Settings/SettingsReducer';

function SettingsAlert() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const visualAlert = useSelector(
    (state) => state?.settings?.alert?.visualAlert
  );
  const soundAlert = useSelector((state) => state?.settings?.alert?.soundAlert);

  const renderVisualAlertSwitch = () => (
    <Switch
      testID="visual-alert-switch"
      color={theme.colors.primary}
      value={visualAlert}
      onValueChange={() => {
        dispatch(setVisualAlert({ enabled: !visualAlert }));
      }}
    />
  );

  const renderSoundAlertSwitch = () => (
    <Switch
      testID="sound-alert-switch"
      color={theme.colors.primary}
      value={soundAlert}
      onValueChange={() => {
        dispatch(setSoundAlert({ enabled: !soundAlert }));
      }}
    />
  );

  return (
    <SafeAreaView style={{ margin: 15, flex: 1 }}>
      <SettingsMenu header="Alerts">
        <SettingsMenu.Item
          title="Visual Alerts"
          right={renderVisualAlertSwitch}
        />
        <SettingsMenu.Item
          title="Sound Alerts"
          right={renderSoundAlertSwitch}
        />
      </SettingsMenu>
    </SafeAreaView>
  );
}

export default withContactInfo(SettingsAlert);
