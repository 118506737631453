import React, { useState } from 'react';
import { View } from 'react-native';
import { Dialog, Portal, Title } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { PrinterList } from '../PrinterList';
import SettingsMenu from '../SettingsMenu';
import { RootRoutes } from '../../constants/routes';
import usePrinter from '../../hooks/usePrinter';
import Button, { ButtonSize } from '../Button/Button';

function PrintDialog({ template, data }) {
  const navigation = useNavigation();
  const [isShownPrintDialog, showPrintDialog] = useState(false);

  const onDismissCallback = () => {
    showPrintDialog(false);
  };

  const {
    actions: { connectPrinter, openPrinterInfo, visualizeReceipt, handlePrint },
    state: { connectedPrinters },
  } = usePrinter({ template, data });

  const goToRefund = () => {
    navigation.navigate('ORDER_REFUND', {
      ...data,
    });
  };

  const goToSettingsPrinter = () => {
    showPrintDialog(false);
    navigation.navigate(RootRoutes.SETTINGS_STACK.name, {
      screen: RootRoutes.SETTINGS_PRINTER.name,
    });
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: (
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
          <Button
            testID="btn-show-print-dialog"
            mode="outlined"
            color="GRAY_900"
            size={ButtonSize.S}
            icon="printer"
            onPress={() => showPrintDialog(true)}
          >
            Printer
          </Button>
          <Button
            testID="btn-go-to-refund"
            mode="outlined"
            color="GRAY_900"
            size={ButtonSize.S}
            icon="alert"
            onPress={goToRefund}
          >
            Refund
          </Button>
        </View>
      ),
    });
  }, [navigation]);

  const noConnectedPrinters = connectedPrinters?.length === 0;
  return (
    <Portal>
      <Dialog visible={isShownPrintDialog} onDismiss={onDismissCallback}>
        <Dialog.Content style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Title>Print Order Details</Title>
          <SettingsMenu header="Connected Printers">
            <PrinterList
              printers={connectedPrinters}
              itemsTestID="settings-printer-mine"
              connectPrinter={connectPrinter}
              openPrinterInfo={openPrinterInfo}
            />
            <SettingsMenu.Item
              title="Other Printers"
              right={(props) => (
                <Button
                  testID="other-printers-button"
                  {...props}
                  uppercase={false}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    padding: 0,
                    margin: -15,
                  }}
                  contentStyle={{
                    flexDirection: 'row',
                    padding: 0,
                    margin: 0,
                    justifyContent: 'flex-end',
                  }}
                  onPress={goToSettingsPrinter}
                >
                  Settings
                </Button>
              )}
            />
          </SettingsMenu>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            testID="preview-button"
            onPress={() => {
              onDismissCallback();
              visualizeReceipt();
            }}
          >
            Preview
          </Button>
          <Button
            testID="print-button"
            disabled={noConnectedPrinters}
            onPress={handlePrint}
          >
            Print
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

export default PrintDialog;

PrintDialog.propTypes = {
  template: PropTypes.func,
  data: PropTypes.any,
};
