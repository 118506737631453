import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        elevation: 0,
        paddingHorizontal: 20,
        paddingVertical: 10,
        justifyContent: 'center',
      },
      safeArea: { flex: 0 },
    });
  }
}
