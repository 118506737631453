import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, withTheme } from 'react-native-paper';
import StyleSheets from './Input.styles';

function Input(props) {
  const {
    label,
    placeholder,
    value,
    theme,
    error,
    onChangeText,
    autoCorrect,
    autoCapitalize,
    rightIcon,
    rightIconPress,
    secureTextEntry,
  } = props;

  const styles = StyleSheets.getSheet(theme);

  return (
    <TextInput
      {...props}
      theme={{
        colors: {
          placeholder: theme.colors.borderColor,
          error: theme.colors.red,
        },
      }}
      underlineColor="transparent"
      style={styles.textInput}
      autoCorrect={autoCorrect || false}
      autoCapitalize={autoCapitalize || 'none'}
      label={label}
      placeholder={placeholder}
      value={value}
      onChangeText={onChangeText}
      mode="outlined"
      error={error}
      right={
        rightIcon && (
          <TextInput.Icon name={rightIcon} onPress={rightIconPress} />
        )
      }
      secureTextEntry={secureTextEntry}
    />
  );
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  theme: PropTypes.object,
  rightIcon: PropTypes.string,
  rightIconPress: PropTypes.func,
  secureTextEntry: PropTypes.bool,
  error: PropTypes.bool,
  autoCorrect: PropTypes.bool,
  autoCapitalize: PropTypes.string,
};

export default withTheme(Input);
