/* istanbul ignore file */
import { takeEvery, put, select, takeLatest, call } from 'redux-saga/effects';
import { DropDownHolder } from '../../../../components/DropDownHolder';
import {
  discoverPrintersRequest,
  discoverPrintersSuccess,
  discoverPrintersFailed,
  connectPrinterSuccess,
  connectPrinterFailed,
  connectPrinterRequest,
  startConnectingRequest,
  printTemplateSuccess,
  printTemplateFailed,
  printTemplateRequest,
  CONNECTIONS_STATUS,
} from '../../../ducks/Printer/PrinterReducer';
import PosPrinter from '../../../../utils/PosPrinter';
import errorFallback from '../ErrorFallback';

export function* discoverPrinters() {
  try {
    const allowDiscovery = yield select(
      (state) => state.printer.allowDiscovery
    );
    if (!allowDiscovery) {
      yield put(discoverPrintersFailed());
      return;
    }

    const printers = yield call(PosPrinter.discover);
    yield put(
      discoverPrintersSuccess({
        printers,
      })
    );
  } catch (error) {
    console.error(error);
    DropDownHolder.showAlert(
      'error',
      'Printer Discovery Failed',
      `Unable to find printers: ${JSON.stringify(error)}`
    );
    yield put(discoverPrintersFailed());
  }
}

export function* connectPrinter(action) {
  const name = action?.payload?.name;
  try {
    const isConnecting = yield select((state) => state.printer.isConnecting);
    if (isConnecting) return;

    yield put(startConnectingRequest({ name }));
    const selectedPrinter = yield select(
      (state) => state.printer.printers.byId[name]
    );

    yield call(PosPrinter.connect, {
      target: selectedPrinter.target,
      printerName: name,
    });
    yield put(connectPrinterSuccess({ name }));
  } catch (err) {
    console.error(err);
    yield put(connectPrinterFailed({ name }));
    if (err?.message) {
      DropDownHolder.showAlert(
        'error',
        'Failed to connect to printer',
        err.message
      );
    } else {
      DropDownHolder.showAlert(
        'error',
        'Connection Failed',
        JSON.stringify(err, null, 2)
      );
    }
  }
}

export function* printDocument(action) {
  const encodedPrint = action?.payload?.encodedPrint;
  try {
    const connected = yield select(
      (state) => state.printer.printers.connectedIds
    );
    const byId = yield select((state) => state.printer.printers.byId);
    const connectedPrinter = byId[connected?.[0]];

    if (connected.length === 0) {
      yield put(printTemplateFailed());
      console.error('No printer connected');
      DropDownHolder.showAlert(
        'error',
        'Unable to print',
        'No printer connected'
      );
      return;
    }
    if (connectedPrinter.status !== CONNECTIONS_STATUS.CONNECTED) {
      yield put(printTemplateFailed());
      console.error('Printer is not ready yet');
      DropDownHolder.showAlert(
        'error',
        'Unable to print',
        'Printer is not ready yet'
      );
      return;
    }

    yield call(PosPrinter.print, encodedPrint);
    yield put(printTemplateSuccess());
  } catch (err) {
    console.error(err);
    yield put(printTemplateFailed());
    DropDownHolder.showAlert(
      'error',
      'Failed to print',
      `We were unable to print, please try again, or reset the connections in the settings page. Error Code: ${err?.message}`
    );
  }
}

export function* watchPrinter() {
  yield takeLatest(discoverPrintersRequest, errorFallback, discoverPrinters);
  yield takeEvery(connectPrinterRequest, errorFallback, connectPrinter);
  yield takeLatest(printTemplateRequest, errorFallback, printDocument);
}
