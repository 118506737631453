/* eslint-disable no-param-reassign */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { groupBy, mapValues } from 'lodash';

export const CONNECTIONS_STATUS = {
  NOT_CONNECTED: 'NOT_CONNECTED',
  CONNECTED: 'CONNECTED',
  CONNECTING: 'CONNECTING',
};

export const INITIAL_STATE = {
  printers: {
    byId: {},
    myIds: [],
    otherIds: [],
    connectedIds: [],
  },
  isConnecting: false,
  isDiscovering: false,
  allowDiscovery: true,
  isPrinting: false,
};

const disconnectAll = (printersById) =>
  mapValues(printersById, (value) => ({
    ...value,
    status: CONNECTIONS_STATUS.NOT_CONNECTED,
  }));

const printerSlice = createSlice({
  name: 'printer',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    discoverPrintersRequest(state) {
      state.isDiscovering = true;
    },
    discoverPrintersSuccess(state, action) {
      const discoveredPrinters = action?.payload?.printers || [];
      if (discoveredPrinters?.length === 0) {
        state.isDiscovering = false;
        return;
      }
      const printerGroups = Object.entries(
        groupBy(discoveredPrinters, 'name')
      ).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            ...value[0],
            status: CONNECTIONS_STATUS.NOT_CONNECTED,
          },
        }),
        {}
      );
      const printerNames = discoveredPrinters.map((item) => item.name);

      state.printers.byId = {
        ...mapValues(printerGroups, (value, key) => ({
          ...value,
          status: state.printers.byId[key]?.status || value.status,
        })),
      };

      const printerNamesNotMine = printerNames.filter(
        (item) =>
          !state.printers.myIds.includes(item) &&
          !state.printers.connectedIds.includes(item)
      );

      state.printers.otherIds = [
        ...new Set([...state.printers.otherIds, ...printerNamesNotMine]),
      ];
      state.isDiscovering = false;
    },
    discoverPrintersFailed(state) {
      state.isDiscovering = false;
    },

    connectPrinterRequest() {},
    startConnectingRequest(state, action) {
      const printerName = action?.payload?.name;
      if (state.printers.connectedIds.includes(printerName)) return;

      state.isConnecting = true;

      const notEqual = (item) => item !== printerName;

      // Remove from other groups and put into connected device
      state.printers.myIds = state.printers.myIds.filter(notEqual);
      state.printers.otherIds = state.printers.otherIds.filter(notEqual);
      if (state.printers.connectedIds.length > 0) {
        state.printers.myIds = state.printers.myIds.concat(
          state.printers.connectedIds
        );
      }
      state.printers.connectedIds = [printerName];

      state.printers.byId = disconnectAll(state.printers.byId);
      state.printers.byId[printerName].status = CONNECTIONS_STATUS.CONNECTING;
    },
    connectPrinterFailed(state, action) {
      state.printers.byId = disconnectAll(state.printers.byId);

      const printerName = action?.payload?.name;
      state.printers.byId[printerName].status =
        CONNECTIONS_STATUS.NOT_CONNECTED;

      state.isConnecting = false;

      state.printers.connectedIds = [];
      state.printers.myIds = [
        ...new Set([printerName, ...state.printers.myIds]),
      ];
    },
    connectPrinterSuccess(state, action) {
      state.printers.byId = disconnectAll(state.printers.byId);

      const printerName = action?.payload?.name;
      state.printers.byId[printerName].status = CONNECTIONS_STATUS.CONNECTED;

      state.isConnecting = false;
    },
    setAllowDiscovery(state, action) {
      const { payload } = action;
      state.allowDiscovery = payload;
    },

    printTemplateRequest(state) {
      state.isPrinting = true;
    },
    printTemplateSuccess(state) {
      state.isPrinting = false;
    },
    printTemplateFailed(state) {
      state.isPrinting = false;
    },

    setPrinterMonitor(state, action) {
      const status = action?.payload?.status;
      state.printerMonitor = status;
    },

    reset(state) {
      state = {
        ...INITIAL_STATE,
        allowDiscovery: state.allowDiscovery,
      };
      return state;
    },
  },
});

export const {
  discoverPrintersRequest,
  discoverPrintersSuccess,
  discoverPrintersFailed,
  connectPrinterRequest,
  startConnectingRequest,
  connectPrinterSuccess,
  connectPrinterFailed,
  setAllowDiscovery,
  printTemplateRequest,
  printTemplateSuccess,
  printTemplateFailed,
  setPrinterMonitor,
  reset,
} = printerSlice.actions;

export const myIdsState = (state) => state.printer.printers.myIds;
export const otherIdsState = (state) => state.printer.printers.otherIds;
export const connectedIdsState = (state) => state.printer.printers.connectedIds;
export const byIdState = (state) => state.printer.printers.byId;

export const getByIds = (idList, byId) => idList.map((name) => byId[name]);

export const myPrintersSelector = createSelector(
  [myIdsState, byIdState],
  getByIds
);
export const otherPrintersSelector = createSelector(
  [otherIdsState, byIdState],
  getByIds
);
export const connectedPrintersSelector = createSelector(
  [connectedIdsState, byIdState],
  getByIds
);

export default printerSlice.reducer;
