import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {},
      title: {
        fontSize: theme.fontSize.FONT_12,
        fontFamily: theme.appFonts.medium,
        lineHeight: 18,
        color: theme.appColors.GRAY_500,
        textTransform: 'capitalize',
      },
      text: {
        color: theme.appColors.GRAY_900,
        fontFamily: theme.appFonts.medium,
        fontSize: theme.fontSize.FONT_16,
        lineHeight: 24,
        textTransform: 'capitalize',
      },
    });
  }
}
