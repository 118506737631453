import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  connectedPrintersSelector,
  connectPrinterRequest,
  myPrintersSelector,
  otherPrintersSelector,
  printTemplateRequest,
  reset,
} from '../../redux/ducks/Printer/PrinterReducer';
import { usePrinterDiscovery } from '../usePrinterDiscovery';
import { DropDownHolder } from '../../components/DropDownHolder';

const usePrinter = ({ template, data } = {}) => {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();

  const isPrinting = useSelector((state) => state.printer.isPrinting);
  const allPrintersById = useSelector((state) => state.printer.printers.byId);
  const myPrinters = useSelector(myPrintersSelector);
  const otherPrinters = useSelector(otherPrintersSelector);
  const connectedPrinters = useSelector(connectedPrintersSelector);
  const connectPrinter = (name) => dispatch(connectPrinterRequest({ name }));
  const resetPrinters = () => dispatch(reset());
  const handlePrint = async () => {
    try {
      const { encoded } = await template(data);
      dispatch(
        printTemplateRequest({
          encodedPrint: encoded,
        })
      );
    } catch (err) {
      console.error(err);
      DropDownHolder.showAlert(
        'error',
        'Failed to print the selected template',
        'We were unable to print, please try again, or reset the connections in the settings page.'
      );
    }
  };

  const [printerInfo, setPrinterInfo] = useState(null);
  const openPrinterInfo = (name) => setPrinterInfo(allPrintersById?.[name]);
  const dismissPrinterInfoModal = () => setPrinterInfo(null);

  const {
    isDiscovering,
    startDiscovery,
    allowDiscovery,
    toggleAllowDiscovery,
  } = usePrinterDiscovery();

  const visualizeReceipt = async () => {
    try {
      const { html } = await template(data);
      navigate('ReceiptVisualizer', { html });
    } catch (err) {
      console.error(err);
      DropDownHolder.showAlert(
        'error',
        'Failed to preview receipt',
        'We were unable to display the currently selected template'
      );
    }
  };

  return {
    actions: {
      startDiscovery,
      toggleAllowDiscovery,
      connectPrinter,
      resetPrinters,
      handlePrint,
      openPrinterInfo,
      dismissPrinterInfoModal,
      visualizeReceipt,
    },
    state: {
      printerInfo,
      isDiscovering,
      allowDiscovery,
      isPrinting,
      myPrinters,
      otherPrinters,
      connectedPrinters,
    },
  };
};

export default usePrinter;
