import CONFIG from 'react-native-config';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Constants from 'expo-constants';
import _ from 'lodash';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

export const { OS } = Platform;

export const isIOS = OS === 'ios';
export const isWeb = OS === 'web';
export const isAndroid = !isIOS;
export const isTablet = DeviceInfo.isTablet();
export const appVersion = DeviceInfo.getVersion();
export const BIG_ORDER_BOUNDARY_PRICE = 150;

const parseEnvName = (envName) => {
  if (
    _.isNil(envName) ||
    !_.isString(envName) ||
    envName.includes('ENVIRONMENT_NAME')
  ) {
    return 'local';
  }

  return envName;
};

const parseApiBaseUrl = (baseUrl) => {
  if (
    _.isNil(baseUrl) ||
    !_.isString(baseUrl) ||
    baseUrl.includes('API_BASE_URL')
  ) {
    return 'https://api.eatsy.net';
  }

  return baseUrl;
};

const getWebConfig = () => {
  const envName = parseEnvName(global.environmentName);
  const apiBaseUrl = parseApiBaseUrl(global.apiBaseUrl);

  const config = {
    ...Constants.manifest.extra,
    ENV: envName,
    API_BASE_URL: apiBaseUrl,
  };

  return config;
};

export const getConfig = () => {
  if (isWeb) return getWebConfig();
  return CONFIG;
};

export const shouldUseFakeData = () => getConfig().USE_FAKE_DATA === 1;

export const getRefreshTokenTryCount = () =>
  parseInt(getConfig().REFRESH_TOKEN_RETRY_COUNT, 10) || 1;

export const getDashboardRefreshInterval = () =>
  parseInt(getConfig().DASHBOARD_REFRESH_INTERVAL, 10);

export const getRestaurantRefreshInterval = () =>
  parseInt(getConfig().RESTAURANT_REFRESH_INTERVAL, 10);

export default getConfig;
