export const mockDiscoveredPrinters = [
  {
    name: 'Epson TM-800',
    ip: '192.168.100.6',
    mac: '12:34:56:78:56:78',
    target: 'TCP:192.168.100.6',
    bt: '12:34:56:78:56:78',
    usb: '000000000000000000',
    usbSerialNumber: '123456789012345678',
  },
  {
    name: 'Alamo LT-73',
    ip: '192.168.100.6',
    mac: '12:34:56:78:56:78',
    target: 'TCP:192.168.100.6',
    bt: '12:34:56:78:56:78',
    usb: '000000000000000000',
    usbSerialNumber: '123456789012345678',
  },
  {
    name: 'Probuster CM-22',
    ip: '192.168.100.6',
    mac: '12:34:56:78:56:78',
    target: 'TCP:192.168.100.6',
    bt: '12:34:56:78:56:78',
    usb: '000000000000000000',
    usbSerialNumber: '123456789012345678',
  },
  {
    name: 'Philips ESCPOS',
    ip: '192.168.100.6',
    mac: '12:34:56:78:56:78',
    target: 'TCP:192.168.100.6',
    bt: '12:34:56:78:56:78',
    usb: '000000000000000000',
    usbSerialNumber: '123456789012345678',
  },
  {
    name: 'Otwa Tska PO-11',
    ip: '192.168.100.6',
    mac: '12:34:56:78:56:78',
    target: 'TCP:192.168.100.6',
    bt: '12:34:56:78:56:78',
    usb: '000000000000000000',
    usbSerialNumber: '123456789012345678',
  },
];

const wait = (delay) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

class MockPrinterAdapter {
  constructor(options) {
    const newOptions = {
      enableDebug: false,
      defaultCharsPerLine: 30,
      ...options,
    };

    this.enableDebug = newOptions.enableDebug;
    this.defaultCharsPerLine = newOptions.defaultCharsPerLine;
  }

  getCharsPerLine = async () => {
    await wait(40);
    this.log('Getting chars per line');
  };

  log(value, options) {
    const newOptions = {
      type: 'log',
      description: '',
      ...options,
    };

    if (this.enableDebug) {
      console.info(`Class Name: ${this.constructor.name}`);
      console.group();
      if (newOptions.description)
        console[newOptions.type](newOptions.description);
      console[newOptions.type](value);
    }
  }

  discover = async () => {
    await wait(4000);
    const printers = mockDiscoveredPrinters;

    this.log(printers, { description: 'Discovered Printers' });
    return printers.map(({ name, target }) => ({
      name,
      target,
    }));
  };

  connect = async ({ target, printerName }) => {
    await wait(2000);
    const connectionStatus = 0;

    this.log({ target, printerName }, { description: 'Connect Status' });
    return connectionStatus;
  };

  print = async (intArray) => {
    await wait(2000);

    const fakePrintStatus = {
      connection: 'CONNECT',
      online: 'ONLINE',
    };

    console.log('Printing on mocked printer adapter', intArray);

    this.log(fakePrintStatus, { description: 'Print Status' });

    return fakePrintStatus;
  };
}

export default MockPrinterAdapter;
