import { useMemo } from 'react';
import { useTheme } from 'react-native-paper';
import {
  mapProgressNextToActionStatus,
  ORDER_ACTION,
} from '../../../../constants/orders';
import useAsyncFunction from '../../../../hooks/useAsyncFunction/useAsyncFunction';
import { useRestaurant } from '../../../../redux/ducks/User/UserReducer';

const getDetailsMap = (theme) => {
  const statusMap = {
    [ORDER_ACTION.SET_AS_PICKED_UP]: {
      text: 'Set as Picked Up',
      backgroundColor: theme.colors.purple,
      color: theme.colors.white,
    },

    [ORDER_ACTION.SET_AS_READY_FOR_DRIVER]: {
      text: 'Set as Ready for Pickup',
      backgroundColor: theme.colors.green,
      color: theme.colors.white,
    },
  };
  return statusMap;
};

const useStatus = ({ progress, type }) => {
  const status = useMemo(
    () => mapProgressNextToActionStatus(progress, type),
    [progress, type]
  );
  return status;
};

const useDetails = (status) => {
  const theme = useTheme();
  const detailsMap = useMemo(() => getDetailsMap(theme), [theme]);
  return detailsMap[status] || {};
};

export const createButtonAction = (action, parameters) => async () =>
  action?.(parameters);

const useAction = ({
  onActionPress,
  restaurantId,
  orderId,
  progress,
  type,
}) => {
  const [action, isLoading] = useAsyncFunction(
    createButtonAction(onActionPress, {
      progress,
      type,
      restaurantId,
      orderId,
    })
  );
  return [action, isLoading];
};

export const useOrderCardActionButton = ({
  progress,
  type,
  orderId,
  onActionPress,
}) => {
  const status = useStatus({ progress, type });
  const details = useDetails(status);

  const restaurant = useRestaurant();

  const [action, isLoading] = useAction({
    restaurantId: restaurant?.id,
    orderId,
    progress,
    type,
    onActionPress,
  });

  return {
    details,
    action,
    isLoading,
  };
};
