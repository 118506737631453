import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native-paper';
import useStyles from '../../utils/useStyles';
import Stylesheet from './FooterLoading.styles';

function FooterLoading({ loading }) {
  const styles = useStyles(Stylesheet);
  if (loading)
    return (
      <View
        testID="footer-loading-indicator"
        style={styles.footerLoadingIndicator}
      >
        <ActivityIndicator />
      </View>
    );
  return <View style={styles.footer} />;
}

FooterLoading.propTypes = {
  loading: PropTypes.bool,
};

export default FooterLoading;
