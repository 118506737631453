import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { SafeAreaView } from 'react-native';
import SettingsMenu from '../../components/SettingsMenu';
import { RootRoutes } from '../../constants/routes';
import withContactInfo from '../../hocs/withContactInfo';

function Settings() {
  const { navigate } = useNavigation();

  const goTo = (route) => () =>
    navigate(RootRoutes.SETTINGS_STACK.name, { screen: route });

  return (
    <SafeAreaView style={{ margin: 15, flex: 1 }}>
      <SettingsMenu>
        <SettingsMenu.Item
          title="Printer"
          isLink
          onPress={goTo(RootRoutes.SETTINGS_PRINTER.name)}
        />
        <SettingsMenu.Item
          title="Alert Settings"
          isLink
          onPress={goTo(RootRoutes.SETTINGS_ALERT.name)}
        />
        <SettingsMenu.Item
          title="Set Temporary Closing Hours"
          isLink
          onPress={goTo(RootRoutes.SETTINGS_TEMPORARY_CLOSING_HOURS.name)}
        />
      </SettingsMenu>
    </SafeAreaView>
  );
}

export default withContactInfo(Settings);
