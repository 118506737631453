import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      surfaceContainer: {
        elevation: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        padding: 16,
        marginLeft: 8,
        flex: 1,
      },
      title: {
        fontSize: theme.fontSize.FONT_20,
        paddingBottom: 4,
      },
      statusContainer: {
        borderWidth: 0,
        height: 80,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
        flexDirection: 'row',
      },
      statusButton: {
        textColor: theme.appColors.GRAY_300,
      },
      subtitle: {
        paddingTop: 4,
        borderStyle: 'solid',
        fontSize: theme.fontSize.FONT_20,
      },
    });
  }
}
