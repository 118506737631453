import React from 'react';
import { View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../../../utils/useStyles';
import StyleSheet from './OrderCardInfo.styles';

function OrderCardInfo({ title, text, textColor, alignRight, style }) {
  const styles = useStyles(StyleSheet);
  const theme = useTheme();

  return (
    <View style={[styles.container, style]}>
      <Text style={[styles.title, alignRight ? { textAlign: 'right' } : {}]}>
        {title}
      </Text>
      <Text
        style={[
          styles.text,
          alignRight ? { textAlign: 'right' } : {},
          textColor ? { color: theme.appColors[textColor] } : {},
        ]}
      >
        {text}
      </Text>
    </View>
  );
}

OrderCardInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  alignRight: PropTypes.bool,
};

export default OrderCardInfo;
