import React, { useCallback } from 'react';
import { View } from 'react-native';
import { ToggleButton, Text } from 'react-native-paper';
import PropTypes from 'prop-types';
import StyleSheets from './ToggleButtonGroup.styles';

const styles = StyleSheets.getSheet();

// eslint-disable-next-line react/function-component-definition
const renderTextIcon = (text, active) => () =>
  (
    <Text style={[styles.buttonText, active ? styles.buttonTextActive : {}]}>
      {text}
    </Text>
  );

function ToggleButtonGroup({
  value,
  setValue,
  options,
  onChange,
  nullable = false,
  containerStyle,
  buttonStyle,
}) {
  const handleChange = useCallback(
    (v) => {
      let newValue = v;
      if (nullable && newValue === value) {
        newValue = null;
      }
      if (setValue) setValue(newValue);
      if (onChange) onChange(newValue);
    },
    [onChange, setValue, nullable, value]
  );

  return (
    <View style={[styles.container, containerStyle ?? {}]}>
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          testID={`toggle-button-${option.value}`}
          icon={renderTextIcon(option.label, option.value === value)}
          value={option.value}
          onPress={() => handleChange(option.value)}
          style={[
            styles.buttonContainer,
            option.value === value ? styles.buttonContainerActive : {},
            buttonStyle ?? {},
          ]}
        />
      ))}
    </View>
  );
}

ToggleButtonGroup.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      sublabel: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  containerStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  nullable: PropTypes.bool,
};

export default ToggleButtonGroup;
