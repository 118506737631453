import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useStyles from '../../../../utils/useStyles';
import Stylesheet from './OrderItemCommentNote.style';

function OrderDetailsCommentNote({ text }) {
  const styles = useStyles(Stylesheet);

  if (_.isNil(text) || _.isEmpty(text)) return null;

  const formattedText = text.replace(/\n/g, ' ');

  return (
    <View style={styles.containerStyle}>
      <Text
        style={styles.textStyle}
        // icon="comment-text-outline"
        testID="order-details-comment-note"
      >
        <Icon name="comment-alert" style={styles.iconStyle} />
        {` ${formattedText}`}
      </Text>
    </View>
  );
}

OrderDetailsCommentNote.propTypes = {
  text: PropTypes.string,
};

export default OrderDetailsCommentNote;
