/* istanbul ignore file */
import { combineReducers } from 'redux';
import user from './User/UserReducer';
import restaurant from './Restaurant/RestaurantReducer';
import printer from './Printer/PrinterReducer';
import settings from './Settings/SettingsReducer';
import search from './Search/SearchReducer';

export default combineReducers({
  user,
  restaurant,
  printer,
  settings,
  search,
});
