import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  restaurantSettings: {
    loading: false,
    data: null,
  },
  restaurantStatus: {
    loading: false,
    data: null,
  },
};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    restaurantSettingsRequest(state) {
      state.restaurantSettings.loading = true;
    },
    restaurantSettingsResponse(state, action) {
      const { data } = action.payload;
      state.restaurantSettings = {
        loading: false,
        data,
      };
    },
    restaurantStatusRequest(state) {
      state.restaurantStatus.loading = true;
    },
    restaurantStatusResponse(state, action) {
      const { data } = action.payload;
      state.restaurantStatus = {
        loading: false,
        data,
      };
    },
  },
});

export const {
  restaurantSettingsRequest,
  restaurantSettingsResponse,
  restaurantStatusRequest,
  restaurantStatusResponse,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;
