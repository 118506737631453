import React from 'react';
import { Surface } from 'react-native-paper';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import useStyles from '../../../../utils/useStyles';
import { useSetAsReady } from './OrderDetailsSetAsReady.controller';
import Stylesheet from './OrderDetailsSetAsReady.styles';

function OrderDetailsSetAsReady({ progress, type, onPress }) {
  const styles = useStyles(Stylesheet);

  const handlePress = async () => onPress({ progress, type });

  const { isValidAction, details, action, isLoading } = useSetAsReady({
    progress,
    type,
    onPress: handlePress,
  });

  if (!isValidAction) {
    return null;
  }

  return (
    <Surface style={styles.container}>
      <Button
        testID="order-details-set-as-ready-button"
        compact
        style={styles.button}
        color={details.backgroundColor}
        mode="contained"
        loading={isLoading}
        disabled={isLoading}
        onPress={action}
      >
        {details.text}
      </Button>
    </Surface>
  );
}

OrderDetailsSetAsReady.propTypes = {
  progress: PropTypes.shape({
    placed: PropTypes.number,
    received: PropTypes.number,
    confirmed: PropTypes.number,
    completed: PropTypes.number,
  }),
  type: PropTypes.oneOf(['delivery', 'takeout']),
  onPress: PropTypes.func,
};

export default OrderDetailsSetAsReady;
