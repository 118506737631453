import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  alert: {
    visualAlert: true,
    soundAlert: true,
  },
  tempClosingHours: {
    loading: false,
    saving: false,
    deleteHourId: null,
    data: [],
    error: null,
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    setVisualAlert(state, action) {
      return {
        ...state,
        alert: {
          ...state.alert,
          visualAlert: action?.payload?.enabled,
        },
      };
    },
    setSoundAlert(state, action) {
      return {
        ...state,
        alert: {
          ...state.alert,
          soundAlert: action?.payload?.enabled,
        },
      };
    },
    getTempClosingHoursRequest(state, action) {
      console.log(action);
      state.tempClosingHours.loading = true;
      state.tempClosingHours.error = null;
    },
    getTempClosingHoursResponse(state, action) {
      state.tempClosingHours = {
        loading: false,
        ...action.payload,
      };
    },
    addTempClosingHoursRequest(state, action) {
      console.log(action);
      state.tempClosingHours.saving = true;
      state.tempClosingHours.error = null;
    },
    addTempClosingHoursResponse(state, action) {
      state.tempClosingHours = {
        ...state.tempClosingHours,
        ...action.payload,
        saving: false,
      };
    },
    deleteTempClosingHoursRequest(state, action) {
      state.tempClosingHours.deleteHourId = action.payload.hourId;
      state.tempClosingHours.error = null;
    },
    deleteTempClosingHoursResponse(state, action) {
      state.tempClosingHours = {
        ...state.tempClosingHours,
        ...action.payload,
        deleteHourId: null,
      };
    },
  },
});

export const {
  setVisualAlert,
  setSoundAlert,
  getTempClosingHoursRequest,
  getTempClosingHoursResponse,
  addTempClosingHoursRequest,
  addTempClosingHoursResponse,
  deleteTempClosingHoursRequest,
  deleteTempClosingHoursResponse,
} = settingsSlice.actions;

export default settingsSlice.reducer;
