import { useMemo } from 'react';

import ReceiptVisualizer from '../components/ReceiptVisualizer';
import OrderRefund from '../pages/OrderRefund';
import SearchModal from '../components/SearchModal';
import Dashboard from '../pages/Dashboard';
import Menu from '../pages/Menu';
import Login from '../pages/Login';
import OrderDetails from '../pages/OrderDetails';
import OrderHistory from '../pages/OrderHistory';
import RestaurantSelector from '../pages/RestaurantSelector';
import Settings from '../pages/Settings';
import SettingsAlert from '../pages/Settings/SettingsAlert';
import SettingsPrinter from '../pages/Settings/SettingsPrinter';
import SettingsTempClosingHours from '../pages/Settings/SettingsTempClosingHours';
import AddTempClosingHours from '../pages/Settings/SettingsTempClosingHours/AddTempClosingHours';
import Splash from '../pages/Splash';
import SettingsStack from '../routes/SettingsStack';
import { forVerticalInvertedIOS } from '../routes/Utils';

export const GuestRoutes = {
  LOGIN: {
    name: 'LOGIN',
    component: Login,
    options: {
      headerShown: false,
      title: '',
      hideSearchBar: true,
    },
  },
};

export const RootRoutes = {
  SPLASH: {
    name: 'SPLASH',
    component: Splash,
    options: {
      headerShown: false,
      title: '',
      hideSearchBar: true,
    },
  },
  SEARCH: {
    name: 'SEARCH',
    component: SearchModal,
    options: {
      headerShown: false,
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: forVerticalInvertedIOS,
      hideSearchBar: true,
    },
  },
  RESTAURANT_SELECTOR: {
    name: 'RESTAURANT_SELECTOR',
    component: RestaurantSelector,
    options: {
      title: 'Select a Restaurant',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  MENU: {
    name: 'MENU',
    component: Menu,
    options: {
      title: 'Manage Items',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  DASHBOARD: {
    name: 'DASHBOARD',
    component: Dashboard,
    options: {
      title: null,
      headerShown: true,
      hideSearchBar: true,
    },
  },
  ORDER_DETAILS: {
    name: 'ORDER_DETAILS',

    component: OrderDetails,
    options: {
      title: 'Order Details',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  ORDER_HISTORY: {
    name: 'ORDER_HISTORY',
    component: OrderHistory,
    options: {
      title: 'Order History',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  SETTINGS_STACK: {
    name: 'SETTINGS_STACK',
    component: SettingsStack,
    options: {
      title: 'Settings',
      headerShown: false,
      hideSearchBar: true,
    },
  },
  SETTINGS: {
    name: 'SETTINGS',
    component: Settings,
    options: {
      title: 'Settings',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  SETTINGS_PRINTER: {
    name: 'SETTINGS_PRINTER',
    component: SettingsPrinter,
    options: {
      title: 'Printer',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  SETTINGS_ALERT: {
    name: 'SETTINGS_ALERT',
    component: SettingsAlert,
    options: {
      title: 'Alert Settings',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  SETTINGS_TEMPORARY_CLOSING_HOURS: {
    name: 'SETTINGS_TEMPORARY_CLOSING_HOURS',
    component: SettingsTempClosingHours,
    options: {
      title: 'Set Temporary Closing Hours',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  SETTINGS_ADD_TEMPORARY_CLOSING_HOURS: {
    name: 'SETTINGS_ADD_TEMPORARY_CLOSING_HOURS',
    component: AddTempClosingHours,
    options: {
      title: 'Add Temporary Closing Hours',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  RECEIPT_VISUALIZER: {
    name: 'ReceiptVisualizer',
    component: ReceiptVisualizer,
    options: {
      title: 'Receipt',
      headerShown: true,
      hideSearchBar: true,
    },
  },
  ORDER_REFUND: {
    name: 'ORDER_REFUND',
    component: OrderRefund,
    options: {
      headerShown: true,
      hideSearchBar: true,
      title: 'Order Refund',
    },
  },
};

export const DrawerRoutes = {
  ADMIN: {
    id: 'ADMIN',
    title: 'My Restaurant',
    routes: [
      RootRoutes.RESTAURANT_SELECTOR,
      RootRoutes.DASHBOARD,
      RootRoutes.MENU,
      RootRoutes.ORDER_HISTORY,
      RootRoutes.SETTINGS_STACK,
    ],
  },
};

export const useDrawerRoutes = (type) =>
  useMemo(() => DrawerRoutes[type], [type]);

export const userTypes = {
  ADMIN: 'ADMIN',
};
