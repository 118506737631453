import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      surfaceContainer: {
        elevation: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        padding: 16,
        marginRight: 8,
        flex: 1,
      },
      title: {
        fontSize: theme.fontSize.FONT_20,
        paddingBottom: 16,
      },
    });
  }
}
