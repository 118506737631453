import React from 'react';
import { View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../../../utils/useStyles';
import { DotDivider } from '../../../Dividers';
import StyleSheet from './OrderCardContactInfo.styles';

function OrderCardContactInfo({ label, orderId, type, name, phone, style }) {
  const theme = useTheme();
  const styles = useStyles(StyleSheet);

  return (
    <View style={[styles.container, style]}>
      <Text style={styles.orderId}>{label || `#${orderId}`}</Text>
      <View style={styles.infoContainer}>
        <Text
          style={type === 'customer' ? styles.customerInfo : styles.driverInfo}
          testID="order-card-contact-info-name"
        >
          {name}
        </Text>
        <DotDivider style={{ color: theme.colors.black }} />
        <Text
          style={type === 'customer' ? styles.customerInfo : styles.driverInfo}
          testID="order-card-contact-info-phone"
        >
          {phone}
        </Text>
      </View>
    </View>
  );
}
OrderCardContactInfo.propTypes = {
  label: PropTypes.string,
  orderId: PropTypes.number,
  type: PropTypes.oneOf(['driver', 'customer']),
  name: PropTypes.string,
  phone: PropTypes.string,
};

export default OrderCardContactInfo;
