import React, { useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import useDebounce from '../../hooks/useDebounce';
import { getConfig } from '../../utils/config';
import StyleSheet from './TimeStepper.styles';

const DEBOUNCE_DELAY = Number(getConfig().PREPARATION_TIME_DEBOUNCE_DELAY);

const secondsToMinutes = (seconds) => {
  const NUMBER_OF_SECONDS_IN_A_MINUTE = 60;
  return seconds / NUMBER_OF_SECONDS_IN_A_MINUTE;
};

function TimeStepper({
  baseTime,
  skipDebounce,
  loading,
  time,
  setTime,
  step,
  min,
  max,
  timeContainerStyle,
}) {
  const theme = useTheme();
  const styles = useStyles(StyleSheet);

  const [internalTime, setInternalTime] = useState(time);

  useEffect(() => {
    setInternalTime(time);
  }, [time]);

  const [, cancel] = useDebounce(
    () => {
      setTime(internalTime);
    },
    skipDebounce ? null : DEBOUNCE_DELAY,
    [internalTime]
  );

  useEffect(() => {
    if (time === internalTime) cancel();
  }, [time, internalTime]);

  const handleIncrease = () => {
    const newValue = internalTime + step;
    if (newValue > max) return;
    setInternalTime(newValue);
  };
  const handleDecrease = () => {
    const newValue = internalTime - step;
    if (newValue < min) return;
    setInternalTime(newValue);
  };

  const displayValueInMinutes = useCallback(
    (seconds) => Math.round(secondsToMinutes(seconds)),
    []
  );

  return (
    <View style={{ flexDirection: 'row' }}>
      <Button
        disabled={loading}
        labelStyle={{ fontSize: theme.fontSize.FONT_24 }}
        testID="preparation-time-decrease"
        compact
        icon="minus"
        contentStyle={[styles.controlButton, styles.buttonMinus]}
        style={styles.controlButtonWrap}
        theme={{
          colors: {
            primary: theme.appColors.BASE_WHITE,
          },
        }}
        mode="text"
        onPress={handleDecrease}
      />
      <View>
        <Button
          testID="preparation-time-edit"
          disabled={loading}
          compact
          uppercase={false}
          contentStyle={styles.preparationTimeContent}
          style={[styles.timeInfo, { ...timeContainerStyle }]}
          theme={{
            colors: {
              primary: theme.colors.black,
            },
          }}
          mode="text"
        >
          <Text
            testID="preparation-time-text"
            style={styles.preparationTimeText}
          >
            {displayValueInMinutes(baseTime + internalTime)} min
          </Text>
        </Button>
      </View>
      <Button
        disabled={loading}
        labelStyle={{ fontSize: theme.fontSize.FONT_24 }}
        testID="preparation-time-increase"
        icon="plus"
        compact
        contentStyle={[styles.controlButton, styles.buttonPlus]}
        style={styles.controlButtonWrap}
        theme={{
          colors: {
            primary: theme.appColors.BASE_WHITE,
          },
        }}
        mode="text"
        onPress={handleIncrease}
      />
    </View>
  );
}

TimeStepper.propTypes = {
  skipDebounce: PropTypes.bool,
  baseTime: PropTypes.number,
  time: PropTypes.number,
  setTime: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  loading: PropTypes.bool,
  timeContainerStyle: PropTypes.any,
};
TimeStepper.defaultProps = {
  baseTime: 0,
  time: 0,
  step: 300,
  min: 0,
  max: 6000,
};

export default TimeStepper;
