import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import useAppLoading from '../hooks/useAppLoading';
import { RootRoutes } from '../constants/routes';
import GuestStack from './GuestStack';
import AuthenticatedStack from './AuthenticatedStack';

const App = createStackNavigator();

function AppRoutes() {
  const { isAppLoading } = useAppLoading();
  const { isLogged } = useSelector((state) => state.user);

  if (isAppLoading) {
    return (
      <App.Navigator screenOptions={{ headerShown: false }}>
        <App.Screen {...RootRoutes.SPLASH} />
      </App.Navigator>
    );
  }

  if (isLogged) {
    return (
      <App.Navigator screenOptions={{ headerShown: false }}>
        <App.Screen
          options={{
            headerShown: false,
          }}
          name="Authenticated"
          component={AuthenticatedStack}
        />
      </App.Navigator>
    );
  }
  return (
    <App.Navigator screenOptions={{ headerShown: false }}>
      <App.Screen
        options={{
          headerShown: false,
        }}
        name="Guest"
        component={GuestStack}
      />
    </App.Navigator>
  );
}

export default AppRoutes;
