import React from 'react';
import { SafeAreaView } from 'react-native';
import { Divider, Surface } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import Stylesheet from './GeneralFooter.styles';

function GeneralFooter({ hidden, children, ...rest }) {
  const styles = useStyles(Stylesheet);
  if (hidden) return null;
  return (
    <Surface>
      <Divider />
      <Surface style={styles.container} {...rest}>
        {children}
      </Surface>
      <SafeAreaView style={styles.safeArea} />
    </Surface>
  );
}

GeneralFooter.propTypes = {
  hidden: PropTypes.bool,
};

export default GeneralFooter;
