import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      simpleRow: {
        flexDirection: 'row',
        height: 56,
        padding: 14,
      },
      divider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.borderColor,
      },
      title: {
        fontSize: 14,
        color: theme.colors.black,
      },
      pageTitle: {
        fontSize: 14,
        lineHeight: 24,
        paddingLeft: 8,
        color: theme.colors.black,
        fontWeight: 'bold',
      },
      leftIcon: {
        fontSize: 24,
      },
    });
  }
}
