import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  searchInput: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    setSearchInput(state, action) {
      return {
        ...state,
        searchInput: action?.payload?.enabled,
      };
    },
  },
});

export const { setSearchInput } = searchSlice.actions;

export default searchSlice.reducer;
