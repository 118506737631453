/* eslint-disable consistent-return */
import { Platform } from 'react-native';
import axios from 'axios';
import qs from 'qs';
import * as _ from 'lodash';
import { enums } from '../constants/config';
import {
  getRefreshTokenTryCount,
  shouldUseFakeData,
  getConfig,
  appVersion,
} from './config';
import { defaultStore as store } from '../redux/stores/GlobalStore';
import {
  refreshTokenSuccess,
  logoutRequest,
} from '../redux/ducks/User/UserReducer';
import { doRefresh } from '../services/AccountService';

export const getInstance = () => {
  // axios instance
  const instance = axios.create({
    baseURL: getConfig().API_BASE_URL,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'indices' }),
  });

  instance.interceptors.request.use(
    async (originalConfig) => {
      const config = {
        ...originalConfig,
      };
      const appState = await store.getState();
      const tokensData = appState?.user?.token;

      if (!_.isNil(tokensData)) {
        const refreshToken = tokensData.refresh.token;
        const accessToken = tokensData.access.token;

        if (config.url === '/account/refresh') {
          if (refreshToken) {
            config.headers.Authorization = `Basic ${refreshToken}`;
          }
        } else if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      if (shouldUseFakeData()) config.headers['X-API-FakeData'] = 'random';

      config.headers['Content-Type'] = 'application/json';
      config.headers['X-App-Version'] = appVersion;

      config.headers = {
        ...config.headers,
        'X-App-Version': appVersion,
        'X-App-Source': 'rmapp-web',
        'X-App-OS': Platform.OS,
      };

      return config;
    },
    async (error) => Promise.reject(error)
  );

  // response interceptor
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const errorStatus = error?.response?.status;
      const errorMessage = error?.response?.data?.message;
      const originalRequest = error?.config;

      if (_.isNil(originalRequest)) {
        store.dispatch(logoutRequest());
        return;
      }

      if (
        errorStatus === 404 &&
        originalRequest?.url === '/account/refresh' &&
        errorMessage === enums.OAUTH_TOKEN_EXPIRED_MESSAGE
      ) {
        store.dispatch(logoutRequest());
        return;
      }

      if (_.isNil(originalRequest?.tokenRefreshAttempts)) {
        originalRequest.tokenRefreshAttempts = 1;
      }

      const availableRefresh = () =>
        errorStatus === 404 &&
        originalRequest.tokenRefreshAttempts <= getRefreshTokenTryCount() &&
        errorMessage === enums.OAUTH_TOKEN_EXPIRED_MESSAGE;

      if (availableRefresh()) {
        originalRequest.tokenRefreshAttempts += 1;

        const appState = await store.getState();
        const tokensData = appState?.user?.token;

        if (!_.isNil(tokensData)) {
          const response = await doRefresh({ token: tokensData.refresh.token });

          if (!_.isNil(response)) {
            store.dispatch(
              refreshTokenSuccess({
                access_token: response.token,
                access_token_expiration_time: response.expires,
              })
            );

            instance.defaults.headers.common.Authorization = `Bearer ${response.token}`;
          } else {
            store.dispatch(logoutRequest());
            return;
          }
        } else {
          store.dispatch(logoutRequest());
          return;
        }

        return instance(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default getInstance;
