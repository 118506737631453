import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      footerLoadingIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        marginBottom: 30,
      },
      footer: {
        marginBottom: 30,
      },
    });
  }
}
