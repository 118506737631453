import { configureStore as toolkitConfigureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import reducer from '../ducks/RootReducer';
import persistReducer from '../ducks/PersistReducer';
import saga from '../middlewares/sagas/RootSaga';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (preloadedState) => {
  const store = toolkitConfigureStore({
    reducer: persistReducer(reducer),
    middleware: [sagaMiddleware],
    preloadedState,
  });
  sagaMiddleware.run(saga);
  return store;
};
export const defaultStore = configureStore();
export const persistor = persistStore(defaultStore);

export default configureStore;
