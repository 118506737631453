import { StyleSheet } from 'react-native';
import { Theme } from '../../../../constants/theme';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      surfaceContainer: {
        elevation: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        borderColor: theme.appColors.GRAY_300,
        padding: 24,
        flex: 1,
      },
      image: {
        margin: 16,
        width: 150,
        height: 150,
      },
      title: {
        fontSize: theme.fontSize.FONT_16,
        fontWeight: '500',
        color: Theme.appColors.GRAY_900,
        paddingBottom: 16,
      },
      subtitle: {
        fontSize: theme.fontSize.FONT_14,
        color: Theme.appColors.GRAY_500,
        textAlign: 'center',
      },
    });
  }
}
