import { useMemo } from 'react';
import { useTheme } from 'react-native-paper';
import {
  mapProgressNextToActionStatus,
  ORDER_ACTION,
} from '../../../../constants/orders';
import useAsyncFunction from '../../../../hooks/useAsyncFunction';

const getDetailsMap = (theme) => ({
  [ORDER_ACTION.SET_AS_PICKED_UP]: {
    color: theme.colors.white,
    backgroundColor: 'GRAY_800',
    text: 'Set as Picked Up',
  },
  [ORDER_ACTION.SET_AS_READY_FOR_DRIVER]: {
    color: theme.colors.white,
    backgroundColor: 'GRAY_800',
    text: 'Set as Ready for Pickup',
  },
});

export const useSetAsReady = ({ progress, type, onPress }) => {
  const theme = useTheme();
  const orderStatus = mapProgressNextToActionStatus(progress, type);
  const validActions = [
    ORDER_ACTION.SET_AS_READY_FOR_DRIVER,
    ORDER_ACTION.SET_AS_PICKED_UP,
  ];
  const isValidAction = validActions.includes(orderStatus);

  const details = useMemo(
    () => getDetailsMap(theme)[orderStatus],
    [theme, orderStatus]
  );

  const [action, isLoading] = useAsyncFunction(onPress);

  return {
    isValidAction,
    details,
    isLoading,
    action,
  };
};

export default useSetAsReady;
