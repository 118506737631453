import React from 'react';
import ContactInfo from '../../components/ContactInfo';

const withContactInfo = (Component) => {
  function WithContactInfo(props) {
    return (
      <>
        <Component {...props} />
        <ContactInfo hidden={false} testID="contact-info-hoc" />
      </>
    );
  }
  WithContactInfo.displayName = `WithContactInfo(${Component?.displayName})`;
  return WithContactInfo;
};

export default withContactInfo;
