import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        padding: 15,
        width: '50%',
      },
      label: {
        fontSize: 12,
        marginBottom: 6,
      },
      dropdown: {
        borderWidth: 0,
        borderColor: '#FFFFFF',
        borderRadius: 2,
        height: 48,
      },
      dropdownInsideContainer: {
        borderWidth: 0,
        borderColor: '#FFFFFF',
        borderRadius: 2,
      },
      dropdownText: {
        fontSize: 16,
      },
    });
  }
}
