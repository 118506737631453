class EscPosPrinterAdapter {
  getCharsPerLine = async () => {
    console.log('getCharsPerLine method is disabled on web', this);
  };

  log() {
    console.log('log method is disabled on web', this);
  }

  discover = async () => {
    console.log('discover method is disabled on web', this);
  };

  connect = async () => {
    console.log('connect method is disabled on web', this);
  };

  print = async () => {
    console.log('print method is disabled on web', this);
  };
}

export default EscPosPrinterAdapter;
