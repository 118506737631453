import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import { ActivityIndicator, List, useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';
import { formatTimeStamp } from '../../../../utils/DateUtils/conversion';
import { deleteTempClosingHoursRequest } from '../../../../redux/ducks/Settings/SettingsReducer';
import { selectDeleteTempClosingHourId } from '../../../../redux/ducks/Settings/SettingsSelector';

function TempClosingHourItem({ data: { id, start, end }, restaurantId }) {
  const deleteHourId = useSelector(selectDeleteTempClosingHourId);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleDeleteItem = useCallback(() => {
    dispatch(deleteTempClosingHoursRequest({ restaurantId, hourId: id }));
  }, [dispatch, id, restaurantId]);

  return (
    <List.Item
      title={`${formatTimeStamp(start)} - ${formatTimeStamp(end)}`}
      titleStyle={{ color: theme.appColors.GRAY_900 }}
      right={(props) =>
        deleteHourId === id ? (
          <ActivityIndicator />
        ) : (
          <TouchableOpacity onPress={handleDeleteItem}>
            <List.Icon {...props} icon="delete" />
          </TouchableOpacity>
        )
      }
    />
  );
}

TempClosingHourItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  restaurantId: PropTypes.number,
};

export default TempClosingHourItem;
