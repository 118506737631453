import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        paddingBottom: 10,
      },
      flexRow: {
        flexDirection: 'row',
      },
      orderId: {
        color: theme.appColors.GRAY_500,
        fontSize: 14,
        fontFamily: theme.appFonts.semiBold,
      },
      infoContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginVertical: 8,
      },
      customerInfo: {
        fontFamily: theme.appFonts.bold,
        fontSize: 20,
        color: theme.appColors.GRAY_900,
      },
      driverInfo: {
        fontSize: 16,
        fontFamily: theme.appFonts.medium,
        color: theme.appColors.GRAY_500,
      },
    });
  }
}
