import React from 'react';
import { Button as Btn } from 'react-native-paper';
import PropTypes from 'prop-types';
import { Theme } from '../../constants/theme';
import StyleSheets from './Button.styles';

export const ButtonSize = {
  L: 'L',
  M: 'M',
  S: 'S',
};

function Button({
  uppercase = false,
  size = ButtonSize.M,
  testID,
  color = 'PRIMARY_700',
  style,
  children,
  ...props
}) {
  const buttonTestId = testID || 'btn';
  const styles = StyleSheets.getSheet({ size });

  return (
    <Btn
      uppercase={uppercase}
      color={Theme.appColors[color]}
      style={[styles.button, style]}
      labelStyle={styles.label}
      testID={buttonTestId}
      {...props}
    >
      {children}
    </Btn>
  );
}

Button.propTypes = {
  testID: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(ButtonSize)),
  color: PropTypes.oneOf(Object.keys(Theme.appColors)),
  mode: PropTypes.string,
  onPress: PropTypes.func,
  loading: PropTypes.bool,
};

export default Button;
