import React, { useMemo, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import OrdersList from '../../../components/OrdersList';
import { getOrders } from '../../../services/RestaurantManagerPortalService';
import useAsyncFunction from '../../../hooks/useAsyncFunction/useAsyncFunction';
import { RootRoutes } from '../../../constants/routes';
import OrderHistoryFooter from './OrderHistoryFooter';
import useStyles from '../../../utils/useStyles';
import { getNextPageParam, getPageResults } from '../OrderHistory.controller';
import Stylesheets from '../OrderHistory.styles';

const fetchNewOrders = ({
  queryKey: [, { types, status, startTimestamp, endTimestamp, restaurantId }],
  pageParam,
}) =>
  getOrders({
    restaurantId,
    start: startTimestamp,
    end: endTimestamp,
    page: pageParam,
    types,
    status,
    details: 1,
    exclusive: 0,
  });

function OrderHistoryList({
  restaurantId,
  types,
  status,
  startTimestamp,
  endTimestamp,
  refreshTimeStamp,
}) {
  const { navigate } = useNavigation();

  const styles = useStyles(Stylesheets);

  const queryKey = [
    'orders',
    {
      types,
      status,
      startTimestamp,
      endTimestamp,
      restaurantId,
    },
  ];

  const {
    data,
    isFetching,
    isSuccess,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(queryKey, fetchNewOrders, {
    getNextPageParam,
  });

  const ordersList = useMemo(() => getPageResults(data), [data]);

  const [onRefresh, isRefreshing] = useAsyncFunction(refetch);
  useEffect(() => {
    refreshTimeStamp();
  }, [isRefreshing]);

  const goToOrderDetails = ({ orderId }) => {
    navigate(RootRoutes.ORDER_DETAILS.name, {
      restaurantId,
      orderId,
    });
  };

  const summary = useMemo(() => data?.pages?.[0]);

  return (
    <>
      <View style={styles.container} testID="order-history-list">
        <OrdersList
          hideStatusBadges
          onItemPress={goToOrderDetails}
          data={ordersList}
          refetch={onRefresh}
          isLoading={isFetching || isRefreshing}
          isFirstLoading={!isSuccess}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </View>

      <OrderHistoryFooter
        hidden={false}
        orderAmount={summary?.totalResults}
        total={summary?.totalEarned}
      />
    </>
  );
}

OrderHistoryList.propTypes = {
  restaurantId: PropTypes.number,
  refreshTimeStamp: PropTypes.func,
  types: PropTypes.arrayOf(PropTypes.oneOf(['delivery', 'takeout'])),
  status: PropTypes.string,
  startTimestamp: PropTypes.number,
  endTimestamp: PropTypes.number,
};

export default OrderHistoryList;
