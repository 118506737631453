import EscPosEncoder from 'esc-pos-encoder';
import BARCODE from './assets/barcode';
import QRCODE from './assets/qrcode';

export const PX_PER_CHAR_WIDTH = 7.2022;

const QRCODE_TEXT_TO_VALUE = {
  info: 'l',
  warning: 'm',
  debug: 'q',
  error: 'h',
};

class PosEncoder {
  unclosedInitialize = 0;

  unclosedAlignTags = 0;

  unclosedBoldTags = 0;

  unclosedUnderlineTags = 0;

  unclosedItalicTags = 0;

  unclosedMultiplySizeTags = 0;

  tabWidth = 8;

  constructor({ printerInstance }) {
    this.posPrinter = printerInstance;
    this.encoder = new EscPosEncoder();
    this.html = '';
  }

  static encodeWhiteSpaces(str) {
    return str
      .split('')
      .map((c) => (c === ' ' ? '&nbsp;' : c))
      .join('');
  }

  concatHtml(value) {
    this.html = this.html.concat(value);
  }

  align = (value) => {
    if (this.unclosedAlignTags) {
      this.concatHtml('</div>');
      this.unclosedAlignTags -= 1;
    }

    const appendStypeToHtml = {
      left: () => this.concatHtml('<div style="text-align: left;">'),
      right: () => this.concatHtml('<div style="text-align: right;">'),
      center: () =>
        this.concatHtml(
          '<div style=\'text-align: center;\' class="align-center-style">'
        ),
    }[value];
    if (appendStypeToHtml) {
      appendStypeToHtml();

      this.unclosedAlignTags += 1;
    }

    this.encoder.align(value);
    return this;
  };

  bold = () => {
    if (this.unclosedBoldTags) {
      this.unclosedBoldTags -= 1;
      this.concatHtml('</strong>');
    } else {
      this.unclosedBoldTags += 1;
      this.concatHtml('<strong>');
    }
    this.encoder.bold();
    return this;
  };

  underline = () => {
    if (this.unclosedUnderlineTags) {
      this.unclosedUnderlineTags -= 1;
      this.concatHtml('</u>');
    } else {
      this.unclosedUnderlineTags += 1;
      this.concatHtml('<u>');
    }
    this.encoder.underline();
    return this;
  };

  italic() {
    if (this.unclosedItalicTags) {
      this.unclosedItalicTags -= 1;
      this.concatHtml('</i>');
    } else {
      this.unclosedItalicTags += 1;
      this.concatHtml('<i>');
    }
    this.encoder.italic();
    return this;
  }

  static dec2bin(input) {
    let x = input;
    let bin = 0;
    let rem;
    let i = 1;
    while (x !== 0) {
      rem = x % 2;
      x = parseInt(x / 2, 10);
      bin += rem * i;
      i *= 10;
    }

    return bin.toString(2);
  }

  static encodeSizeEnlargement(factor) {
    if (factor < 1 || factor > 8) return 0;
    const sizeInBinary = PosEncoder.dec2bin(factor - 1).padStart(3, 0);
    return parseInt(`0${sizeInBinary}0${sizeInBinary}`, 2);
  }

  multiplySize(factor) {
    const GS = 0x1d;
    const ExclamationPoint = 0x21;

    const codeArrayEnable = [
      GS,
      ExclamationPoint,
      PosEncoder.encodeSizeEnlargement(factor),
    ];
    const codeArrayDisable = [GS, ExclamationPoint, 0];

    if (!factor) this.encoder.raw(codeArrayDisable);

    if (this.unclosedMultiplySizeTags) {
      this.unclosedMultiplySizeTags -= 1;
      this.concatHtml('</span>');

      this.encoder.raw(codeArrayDisable);
    } else {
      this.unclosedMultiplySizeTags += 1;
      this.concatHtml(`<span class="size-${factor}">`);

      this.encoder.raw(codeArrayEnable);
    }
    return this;
  }

  getInitialHTML = () => {
    const paperWidth = Math.ceil(PX_PER_CHAR_WIDTH * this.charsPerLine);

    const multiplicationFactors = Array(8)
      .fill(1)
      .map((item, index) => item * (index + 1));

    return `
    <head>
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <style>
        .align-center-style > img {
            margin: 0 auto;
        }

        html {
            font-family: monospace !important;
            font-size: 12px !important;
            line-height: 1;
        }
        ${multiplicationFactors
          .map(
            (factor) => `
            .size-${factor} {
              font-size: ${12 * factor}px;
            }
          `
          )
          .join('')}

    </style
     </head>
    <div style='display: flex; justify-content: center; margin-top: 40px;'><div style='background-color: #0003; width: ${paperWidth}px; padding: 10px;'>`;
  };

  initialize = () => {
    this.encoder.initialize();
    this.html = this.getInitialHTML();

    this.unclosedInitialize = 2;
    return this;
  };

  line = (value, maxLength) => {
    const maxLen = maxLength ?? this.charsPerLine;
    this.concatHtml(
      `<span>${PosEncoder.encodeWhiteSpaces(value)}</span><br/><br/>`
    );
    this.encoder.line(value, maxLen);
    return this;
  };

  newline = () => {
    this.concatHtml('<br/><br/>');
    this.encoder.newline();
    return this;
  };

  text = (value, maxLength) => {
    const maxLen = maxLength ?? this.charsPerLine;
    const val = value.trim();
    this.concatHtml(`<span>${PosEncoder.encodeWhiteSpaces(val)}</span>`);
    this.encoder.text(val, maxLen);
    return this;
  };

  closeIncompleteHtml = () => {
    while (this.unclosedAlignTags > 0) {
      this.concatHtml('</div>');
      this.unclosedAlignTags -= 1;
    }
    while (this.unclosedInitialize > 0) {
      this.concatHtml('</div>');
      this.unclosedInitialize -= 1;
    }
  };

  encode = () => {
    this.closeIncompleteHtml();
    return {
      encoded: this.encoder.encode(),
      html: this.html,
    };
  };

  loadCharsPerLine = async () => {
    const charsPerLine = await this.posPrinter.getCharsPerLine();
    this.charsPerLine = charsPerLine;
  };

  qrcode(value, model, errorLevel, size = 1) {
    // type qrSizeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    // type qrErrorLevelType = 'l' | 'm' | 'q' | 'h';

    this.encoder.qrcode(
      value,
      model,
      size,
      QRCODE_TEXT_TO_VALUE[errorLevel] || 'l'
    );

    const sizeMultiplyer = 20;
    this.concatHtml(
      `<img src='${QRCODE}' style='width: ${size * sizeMultiplyer}px; height: ${
        size * sizeMultiplyer
      }px;'/>`
    );
    return this;
  }

  barcode(value, symbology, height) {
    // type symbologyType = 'upca' | 'upce' | 'ean13' | 'ean8' | 'coda39' | 'itf' | 'codabar';
    this.encoder.barcode(value, symbology, height);

    const sizeMultiplyer = 0.5;
    this.concatHtml(`
        <img src='${BARCODE}' style='object-fit: contain; height: ${
      height * sizeMultiplyer
    }px;'/>
      `);
    return this;
  }

  printTable = (data, separationCharacter = ' ') => {
    const MAX_CHARACTERS_PER_LINE = this.charsPerLine;

    let cellWidth = MAX_CHARACTERS_PER_LINE / data.length;
    const secondLine = [];
    let secondLineEnabled = false;
    let lineStr = '';
    for (let i = 0; i < data.length; i += 1) {
      let tooLong = false;
      const obj = data[i];
      obj.text = obj.text.toString();

      if (obj.width) {
        cellWidth = MAX_CHARACTERS_PER_LINE * obj.width;
      } else if (obj.cols) {
        cellWidth = obj.cols;
      }

      // If text is too wide go to next line
      if (cellWidth < obj.text.length) {
        tooLong = true;
        obj.originalText = obj.text;
        obj.text = obj.text.substring(0, cellWidth - 1);
      }

      if (obj.align === 'CENTER') {
        const spaces = (cellWidth - obj.text.toString().length) / 2;
        for (let j = 0; j < spaces; j += 1) {
          lineStr += separationCharacter;
        }
        if (obj.text !== '') lineStr += obj.text;

        for (let j = 0; j < spaces - 1; j += 1) {
          lineStr += separationCharacter;
        }
      } else if (obj.align === 'RIGHT') {
        const spaces = cellWidth - obj.text.toString().length;

        for (let j = 0; j < spaces; j += 1) {
          lineStr += separationCharacter;
        }
        if (obj.text !== '') lineStr += obj.text;
      } else {
        if (obj.text !== '') lineStr += obj.text;

        const spaces = cellWidth - obj.text.toString().length;
        for (let j = 0; j < spaces; j += 1) {
          lineStr += separationCharacter;
        }
      }

      if (tooLong) {
        secondLineEnabled = true;
        obj.text = obj.originalText.substring(cellWidth - 1);
        secondLine.push(obj);
      } else {
        obj.text = '';
        secondLine.push(obj);
      }
    }

    this.line(lineStr);

    if (secondLineEnabled) {
      return this.printTable(secondLine);
    }
    return this;
  };

  printHalfTable = (first, second, ratios, spacer = '-') => {
    const newRatios = {
      left: 0.5,
      right: 0.5,
      ...ratios,
    };
    return this.printTable(
      [
        {
          text: `${first} `,
          align: 'LEFT',
          width: newRatios.left,
        },
        {
          text: ` ${second}`,
          align: 'RIGHT',
          width: newRatios.right,
        },
      ],
      spacer
    );
  };

  cut(value) {
    const borderStyle = {
      full: '1px solid #0003',
      partial: '1px dashed #0003',
    };

    this.concatHtml(
      `<div style="text-align: center; border-bottom: ${borderStyle[value]};"></div>`
    );

    this.encoder.cut(value);
    return this;
  }

  setTabWidth(width = 8) {
    const ESC = 0x1b;
    const D = 0x44;
    const NUL = 0x00;

    const kArray = Array(32)
      .fill(1)
      .map((item, index) => item * (index + 1));

    const tabPositions = kArray.map((k) => width * k);

    this.encoder.raw([ESC, D, ...tabPositions, NUL]);
    this.tabWidth = width;
    return this;
  }

  horizontalTab() {
    const HT = 0x09;
    this.encoder.raw([HT]);

    const spaces = Array(this.tabWidth).fill(' ').join('');
    this.concatHtml(`<span>${PosEncoder.encodeWhiteSpaces(spaces)}</span>`);
    return this;
  }

  //   Missing implementations
  //   codepage(value) {}
  //   size(value) {}
}

export default PosEncoder;
