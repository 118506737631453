import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import { useRestaurant } from '../../redux/ducks/User/UserReducer';
import useStyles from '../../utils/useStyles';
import GeneralFooter from '../GeneralFooter';
import Stylesheet from './ContactInfo.styles';

function ContactInfo({ hidden, ...rest }) {
  const restaurant = useRestaurant();
  const styles = useStyles(Stylesheet);
  if (hidden) return null;
  return (
    <GeneralFooter hidden={hidden} {...rest}>
      <Text style={styles.title}>{restaurant?.services?.delivery?.name}</Text>
      <Text style={styles.message}>
        {restaurant?.services?.delivery?.phone}
      </Text>
    </GeneralFooter>
  );
}

ContactInfo.propTypes = {
  hidden: PropTypes.bool,
};

export default ContactInfo;
