import { takeEvery, call, put } from 'redux-saga/effects';
import {
  restaurantSettingsRequest,
  restaurantSettingsResponse,
  restaurantStatusRequest,
  restaurantStatusResponse,
} from '../../../ducks/Restaurant/RestaurantReducer';
import {
  getRestaurantSettings,
  getRestaurantStatus,
} from '../../../../services/RestaurantManagerPortalService';
import errorFallback from '../ErrorFallback';

export function* getRestaurantSettingsSaga(action) {
  try {
    const data = yield call(getRestaurantSettings, action.payload.restaurantId);

    yield put(restaurantSettingsResponse({ data }));
  } catch (err) {
    console.error(err);
  }
}

export function* getRestaurantStatusSaga(action) {
  try {
    const nowTS = Math.round(new Date().getTime() / 1000);
    const data = yield call(getRestaurantStatus, {
      client: action.payload.client,
      at: nowTS,
    });

    yield put(restaurantStatusResponse({ data }));
  } catch (err) {
    console.error(err);
  }
}

export function* watchRestaurant() {
  yield takeEvery(
    restaurantSettingsRequest,
    errorFallback,
    getRestaurantSettingsSaga
  );
  yield takeEvery(
    restaurantStatusRequest,
    errorFallback,
    getRestaurantStatusSaga
  );
}
