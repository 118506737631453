import React from 'react';
import { useTheme } from 'react-native-paper';
import { createStackNavigator } from '@react-navigation/stack';
import { RootRoutes } from '../constants/routes';
import StatusBarWithNetInfo from '../components/StatusBarWithNetInfo';
import AppRoutes from './AppRoutes';

const RootModalStack = createStackNavigator();

function RootStack() {
  const theme = useTheme();
  const { SEARCH, RECEIPT_VISUALIZER } = RootRoutes;

  return (
    <>
      <StatusBarWithNetInfo
        backgroundColor={theme.colors.backgroundColor}
        barStyle="dark-content"
      />
      <RootModalStack.Navigator options={{ presentation: 'modal' }}>
        <RootModalStack.Screen
          options={{ headerShown: false }}
          name="Root"
          component={AppRoutes}
        />
        <RootModalStack.Screen
          name={SEARCH.name}
          component={SEARCH.component}
          options={SEARCH.options}
        />
        <RootModalStack.Screen
          name={RECEIPT_VISUALIZER.name}
          component={RECEIPT_VISUALIZER.component}
          options={RECEIPT_VISUALIZER.options}
        />
      </RootModalStack.Navigator>
    </>
  );
}

export default RootStack;
