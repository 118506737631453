/* istanbul ignore file */
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import createTransform from 'redux-persist/es/createTransform';
import { mapValues } from 'lodash';
import {
  CONNECTIONS_STATUS,
  INITIAL_STATE as PrinterInitialState,
} from './Printer/PrinterReducer';
import { INITIAL_STATE as UserInitialState } from './User/UserReducer';
import { appVersion } from '../../utils/config';

export default (reducers) => {
  const persistReducers = persistReducer(
    {
      key: `rootstore_${appVersion}`,
      storage: AsyncStorage,
      whitelist: ['user', 'printer', 'settings'],
      transforms: [
        // Reset initial loading state for the user
        createTransform(
          (inboundState) => ({
            ...inboundState,
          }),
          (outboundState) => ({
            ...UserInitialState,
            ...outboundState,
            isLoading: false,
            error: null,
            isInitialLoading: true,
          }),
          { whitelist: ['user'] }
        ),
        // Reset connection status for all the printers stored
        createTransform(
          (inboundState) => ({
            ...inboundState,
          }),
          (outboundState) => ({
            ...PrinterInitialState,
            printers: {
              ...outboundState.printers,
              connectedIds: [],
              myIds: [
                ...outboundState.printers.connectedIds,
                ...outboundState.printers.myIds,
              ],
              byId: mapValues(outboundState.printers.byId, (item) => ({
                ...item,
                status: CONNECTIONS_STATUS.NOT_CONNECTED,
              })),
            },
          }),
          { whitelist: ['printer'] }
        ),
      ],
    },
    reducers
  );

  return persistReducers;
};
