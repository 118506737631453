import { useMutation, useQueryClient } from 'react-query';
import { shouldUseFakeData } from '../../../utils/config';
import { fulfillOrder } from '../../../services/RestaurantManagerPortalService';
import DateTZ from '../../../utils/DateUtils/DateUtils';
import { DropDownHolder } from '../../../components/DropDownHolder';

export const handleFulfillOrder = async ({
  progress,
  type,
  restaurantId,
  orderId,
}) =>
  fulfillOrder({
    progress,
    type,
    restaurantId,
    orderId,
  });

export const createTransformQueryData = () => (previousData) => ({
  ...previousData,
  progress: {
    ...previousData.progress,
    completed: DateTZ.getNowTimestamp(),
  },
});

// Set as Ready
export const createOnSettledFulfillOrderHandler =
  ({ queryClient, queryKey }) =>
  async (data, error) => {
    if (!error || shouldUseFakeData()) {
      queryClient.invalidateQueries('orders');
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, createTransformQueryData());
    }
  };

export const createOnErrorFulfillOrderHandler = () => async () => {
  if (!shouldUseFakeData())
    DropDownHolder.showAlert(
      'error',
      'Failed to fulfill request',
      'Sorry, we were unable to complete your request, please try again!'
    );
};

const useFulfillOrderDetails = (queryKey, { restaurantId, orderId }) => {
  const queryClient = useQueryClient();
  const fulfillOrderMutation = useMutation(handleFulfillOrder, {
    onSettled: createOnSettledFulfillOrderHandler({ queryClient, queryKey }),
  });
  return ({ progress, type }) =>
    fulfillOrderMutation
      .mutateAsync({
        restaurantId,
        orderId,
        progress,
        type,
      })
      .catch(createOnErrorFulfillOrderHandler());
};

export default useFulfillOrderDetails;
