import { useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { getErrorOrder } from '../../services/RestaurantManagerPortalService';
import OrderDetailsCard from '../../components/OrderCard/OrderDetailsCard';
import {
  calculateBaseSubtotal,
  calculateFees,
  calculateTaxes,
  useOrderDetails,
} from './OrderDetails.controller';
import OrderItem from './components/OrderItem';
import OrderDetailsBottomConfirmSheet from './components/OrderDetailsBottomConfirmSheet';
import OrderRefundDetails from '../../components/OrderRefundDetails';
import useTimeStepper, { fetchOrderDelays } from '../../hooks/useTimeStepper';
import { mapProgressToStatus, ORDER_STATUS } from '../../constants/orders';
import OrderDetailsSetAsReady from './components/OrderDetailsSetAsReady';
import LoadingScreen from '../../components/LoadingScreen';
import RefreshControl from '../../components/RefreshControl';
import { formatMoney } from '../../utils/CurrencyUtils';
import PrintDialog from '../../components/PrintDialog/PrintDialog';
import templates from '../../utils/PosEncoder/templates';
import { useRestaurant } from '../../redux/ducks/User/UserReducer';
import usePreparationTime from '../../hooks/usePreparationTime/usePreparationTime';
import Hide from '../../components/Hide/Hide';
import { useNewOrderAlert } from '../../context/NewOrderAlert';
import ErrorScreen from '../../components/ErrorScreen/ErrorScreen';
import Chip from '../../components/OrderCard/components/Chip';
import { ChipColor } from '../../components/OrderCard/components/Chip/Chip.constants';
import StyleSheets from './OrderDetails.styles';

function OrderDetails() {
  const theme = useTheme();
  const route = useRoute();
  const orderId = route?.params?.orderId;
  const restaurantId = route?.params?.restaurantId;

  const restaurant = useRestaurant();

  const styles = StyleSheets.getSheet(theme);

  const { setCurrentOrderId } = useNewOrderAlert();

  const { orderDetails, status, refetch, confirmOrder, fulfillOrder } =
    useOrderDetails({
      restaurantId,
      orderId,
    });

  useEffect(() => {
    if (restaurantId && orderId) {
      getErrorOrder({ restaurantId, orderId }).then((res) => {
        console.log(res);
      });
    }
  }, [restaurantId, orderId]);

  useEffect(() => {
    setCurrentOrderId(orderId);
  }, [orderId]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setCurrentOrderId(null);
    };
  }, []);

  const {
    time,
    min,
    max,
    step,
    isLoading: isLoadingTimeStepper,
  } = useTimeStepper(restaurantId, 'orderDelays', fetchOrderDelays);

  const { time: basePrepTime } = usePreparationTime(restaurantId);

  const renderItems = () => (
    <View style={{ marginBottom: 32 }}>
      {orderDetails?.items?.map((item) => {
        const { amount, id } = item;
        return (
          <OrderItem
            key={`order-item-${id}-${amount}`}
            amount={amount}
            item={item}
          />
        );
      })}
    </View>
  );

  const renderBottomSheet = () => {
    const isBottomConfirmSheetHidden =
      !orderDetails ||
      mapProgressToStatus(orderDetails?.progress, orderDetails?.type) !==
        ORDER_STATUS.RECEIVED;

    return (
      <>
        <Hide isHidden={isBottomConfirmSheetHidden}>
          <OrderDetailsBottomConfirmSheet
            timeConfig={{
              baseTime: basePrepTime,
              time,
              min,
              max,
              step,
              isLoading: isLoadingTimeStepper,
            }}
            pickupSchedule={orderDetails?.schedule?.pickup}
            onConfirmOrder={confirmOrder}
            template={templates.orderDetails}
            data={{ order: orderDetails, restaurant }}
          />
        </Hide>
        {/* <ContactInfo hidden={!isBottomConfirmSheetHidden} /> */}
      </>
    );
  };

  const renderSubtotal = () => (
    <View style={styles.feeLine}>
      <Text style={styles.feeLabel}>Subtotal</Text>
      <Text style={styles.feeAmount}>
        {formatMoney(calculateBaseSubtotal(orderDetails?.items))}
      </Text>
    </View>
  );

  const renderFees = () => (
    <View style={styles.feeLine}>
      <Text style={styles.feeLabel}>Tips and Fees</Text>
      <Text style={styles.feeAmount}>
        {formatMoney(calculateFees(orderDetails.fees))}
      </Text>
    </View>
  );

  const renderTaxes = () => (
    <View style={styles.feeLine}>
      <Text style={styles.feeLabel}>Taxes</Text>
      <Text style={styles.feeAmount}>
        {formatMoney(calculateTaxes(orderDetails.taxes))}
      </Text>
    </View>
  );

  const renderCoupon = () => (
    <View style={styles.feeLine}>
      <Text style={styles.feeLabel}>Coupon</Text>
      <Text style={styles.feeAmount}>{formatMoney(orderDetails.coupon)}</Text>
    </View>
  );

  const renderTotal = () => (
    <View style={styles.feeLine}>
      <Text style={styles.feeLabel}>Total</Text>
      <Text style={styles.totalAmount}>{formatMoney(orderDetails.total)}</Text>
    </View>
  );

  if (status.isError) {
    return (
      <ErrorScreen message="Failed to load order details" refetch={refetch} />
    );
  }
  if (!status?.isSuccess) {
    return <LoadingScreen />;
  }

  return (
    <View style={{ flex: 1 }}>
      {orderDetails && restaurant && (
        <PrintDialog
          template={templates.orderDetails}
          data={{ order: orderDetails, restaurant }}
        />
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            enabled
            colors={[theme.colors.primary]}
            tintColor={theme.colors.black}
            refreshing={status?.isFetching}
            onRefresh={refetch}
          />
        }
        style={styles.scrollContainer}
        contentContainerStyle={{ padding: 16 }}
      >
        <OrderRefundDetails error={{}} />
        <View>
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>Order (#{orderId})</Text>
            {orderDetails?.progress.confirmed && (
              <Chip
                title="Order Confirmed"
                color={ChipColor.GREEN}
                style={{ marginLeft: 'auto' }}
              />
            )}
          </View>
          <OrderDetailsCard {...orderDetails} hideActionButton />
        </View>
        <View style={{ marginTop: 24 }}>
          <Text style={styles.sectionTitle}>Items</Text>
          <View style={styles.detailsContainer}>
            {status.isSuccess && renderItems()}
            {status.isSuccess && renderSubtotal()}
            {status.isSuccess && renderFees()}
            {status.isSuccess && renderTaxes()}
            {status.isSuccess && orderDetails.coupon && <>{renderCoupon()}</>}
            {status.isSuccess && renderTotal()}
          </View>
        </View>
      </ScrollView>
      {renderBottomSheet()}
      <OrderDetailsSetAsReady
        progress={orderDetails?.progress}
        type={orderDetails?.type}
        onPress={fulfillOrder}
      />
    </View>
  );
}

export default OrderDetails;
