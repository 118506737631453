import { DateTime } from 'luxon';

export { getDateRanges, getDateRangesOrderedValues } from './getDateRanges';

class DateTZ extends DateTime {
  static getNowTimestamp() {
    return DateTZ.toTimestamp(DateTZ.now());
  }

  static diffInMinutes(timestamp, otherTimestamp, decimalUnits = 0) {
    return parseInt(
      DateTZ.fromSeconds(timestamp)
        .diff(DateTZ.fromSeconds(otherTimestamp))
        .as('minutes')
        .toFixed(decimalUnits),
      10
    );
  }

  static diffToNowInMinutes(timestamp, decimalUnits) {
    return DateTZ.diffInMinutes(
      timestamp,
      DateTZ.toTimestamp(DateTZ.now()),
      decimalUnits
    );
  }

  static diffInDays(timestamp, otherTimestamp, decimalUnits = 0) {
    return parseInt(
      DateTZ.fromSeconds(timestamp)
        .diff(DateTZ.fromSeconds(otherTimestamp))
        .as('days')
        .toFixed(decimalUnits),
      10
    );
  }

  static getFormattedTime(timestamp, timezone = 'utc', locale = 'en-us') {
    return DateTZ.fromSeconds(timestamp)
      .setLocale(locale)
      .setZone(timezone)
      .toFormat('h:mm a');
  }

  static getFormattedFullDate(timestamp, timezone = 'utc', locale = 'en-us') {
    return DateTZ.fromSeconds(timestamp)
      .setLocale(locale)
      .setZone(timezone)
      .toLocaleString(DateTZ.DATE_FULL);
  }

  static getShortFormattedDateAndTime(
    timestamp,
    timezone = 'utc',
    locale = 'en-US'
  ) {
    const date = DateTZ.fromSeconds(timestamp)
      .setLocale(locale)
      .setZone(timezone);

    const tomorrow = DateTZ.now().setZone(timezone).plus({ days: 1 });
    const yesterday = DateTZ.now().setZone(timezone).minus({ days: 1 });

    if (tomorrow.hasSame(date, 'day'))
      return date.toFormat("'Tomorrow, at' h:mm a");
    if (yesterday.hasSame(date, 'day'))
      return date.toFormat("'Yesterday, at' h:mm a");
    return date.toFormat("EEE, LLL d 'at' h:mm a");
  }

  static toTimestamp = (dateTime) => Math.round(dateTime.toMillis() / 1000);

  static fromTimestamp = (timestamp) => timestamp * 1000;

  static timestampToJSDate = (timestamp) =>
    new Date(DateTZ.fromTimestamp(timestamp));

  static changeToTimezoneWithSameLocalTime = (timestamp, timezone) =>
    DateTZ.toTimestamp(
      DateTZ.fromSeconds(timestamp).setZone(timezone, { keepLocalTime: true })
    );

  static isToday = (timestamp, timezone) =>
    DateTZ.fromSeconds(timestamp)
      .setZone(timezone)
      .hasSame(DateTZ.now().setZone(timezone), 'day');

  static isAfterToday = (timestamp, timezone) =>
    DateTZ.fromSeconds(timestamp) > DateTZ.now().setZone(timezone).endOf('day');
}
export default DateTZ;
