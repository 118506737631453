import { createSelector, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const INITIAL_STATE = {
  isLogged: false,
  token: null,
  user: null,
  isLoading: false,
  isInitialLoading: true,
  error: null,
  restaurants: {
    options: [],
    selectedIndex: 0,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    signInRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    signInSuccess(state, action) {
      return {
        ...state,
        token: {
          ...state.token,
          ...action.payload,
        },
        error: null,
      };
    },
    profileRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    profileSuccess(state, action) {
      const { user, restaurants } = action.payload;
      return {
        ...state,
        user,
        isLogged: true,
        restaurants: {
          options: restaurants || [],
          selectedIndex: 0,
        },
        isLoading: false,
        error: null,
      };
    },

    initialLoadRequest(state) {
      return {
        ...state,
        isInitialLoading: true,
      };
    },
    initialLoadUnauthenticated(state) {
      return {
        ...state,
        isInitialLoading: false,
        isLoading: false,
      };
    },
    initialLoadSuccess(state, action) {
      const restaurants = action?.payload?.restaurants || [];
      return {
        ...state,
        user: action.payload.user,
        isInitialLoading: false,
        error: null,
        restaurants: {
          options: restaurants,
          selectedIndex: 0,
        },
      };
    },
    refreshTokenRequest() {},
    refreshTokenSuccess(state, action) {
      return {
        ...state,
        token: {
          ...state.token,
          access: {
            token: action.payload.access_token,
            expires: action.payload.access_token_expiration_time,
          },
        },
        isLogged: true,
      };
    },
    refreshTokenRequestFailure() {
      return {
        ...INITIAL_STATE,
      };
    },
    error(state, action) {
      return {
        ...state,
        error: action?.payload || null,
        isLoading: false,
        isInitialLoading: false,
      };
    },
    clearErrors(state) {
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    },
    logoutRequest() {},
    logoutSuccess() {
      return {
        isLogged: false,
        isLoading: false,
        isInitialLoading: false,
        user: null,
        token: null,
        error: null,
      };
    },
    forgotPasswordRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    forgotPasswordSuccess(state) {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    },
    resetPasswordRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    resetPasswordSuccess(state) {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    },

    selectRestaurant(state, action) {
      const findRestaurantCallback = (restaurant) =>
        restaurant?.id === action?.payload?.id;

      const restaurants = state?.restaurants?.options;
      const restaurantIndex = restaurants?.findIndex(findRestaurantCallback);

      return {
        ...state,
        restaurants: {
          ...state.restaurants,
          selectedIndex: restaurantIndex,
        },
      };
    },
    getRestaurantMenu(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    setRestaurantMenu(state, action) {
      return {
        ...state,
        restaurantMenu: action.payload,
        isLoading: false,
        error: null,
      };
    },
    setRestaurantQuestions(state, action) {
      return {
        ...state,
        restaurantQuestions: action.payload,
        isLoading: false,
        error: null,
      };
    },
    setSearchText(state, action) {
      return {
        ...state,
        searchText: action.payload,
      };
    },
    deleteMenuItem(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    activateMenuItem(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
  },
});

export const {
  signInRequest,
  signInSuccess,
  logoutRequest,
  logoutSuccess,
  error,
  profileRequest,
  profileSuccess,
  initialLoadRequest,
  initialLoadSuccess,
  initialLoadUnauthenticated,
  clearErrors,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenRequestFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  selectRestaurant,
  getRestaurantMenu,
  setRestaurantMenu,
  setSearchText,
  deleteMenuItem,
  activateMenuItem,
  setRestaurantQuestions,
} = userSlice.actions;

export const restaurantOptionsState = (state) =>
  state?.user?.restaurants?.options;
export const restaurantSelectedIndexState = (state) =>
  state?.user?.restaurants?.selectedIndex;
export const getRestaurantByIndex = (options, index) =>
  options?.length ? options[index] : null;

export const restaurantSelector = createSelector(
  [restaurantOptionsState, restaurantSelectedIndexState],
  getRestaurantByIndex
);

export const useRestaurant = () => useSelector(restaurantSelector);

export default userSlice.reducer;
