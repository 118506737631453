import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      item: {
        paddingVertical: 0,
        marginVertical: 0,
      },
      itemLabel: {
        lineHeight: 50,
      },
      subHeader: {
        textTransform: 'uppercase',
        color: theme.colors.black,
      },
      subHeaderIcon: {},
      rightIcon: {
        // padding: 0,
        padding: 10,
      },
      rightWrapper: {
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      rightDisabled: {
        opacity: 0.3,
      },
    });
  }
}
