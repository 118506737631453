import React from 'react';
import { Text, View } from 'react-native';
import { Switch } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import StyleSheet from './ToggleItem.styles';

function ToggleItem({ id, label, onChange, selected }) {
  const styles = useStyles(StyleSheet);
  const onToggleSwitch = () => onChange(!selected);

  return (
    <View style={styles.container}>
      <Switch
        testID={`switch-${id}`}
        value={selected}
        onValueChange={onToggleSwitch}
        color="#0058FF"
      />
      <Text style={styles.text}>{label}</Text>
    </View>
  );
}

ToggleItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
};

export default ToggleItem;
