import React from 'react';
import { View } from 'react-native';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import { formatMoney } from '../../utils/CurrencyUtils/CurrencyUtils';
import TitledItem from '../TitledItem/TitledItem';
import StyleSheet from './OrderRefundDetails.styles';

export const snakeCaseToTitleCase = (text) =>
  startCase(text.replace(/_/i, ' '));

function OrderRefundDetails({ error }) {
  const styles = useStyles(StyleSheet);

  if (!error?.created) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <TitledItem label="Today, 06:45pm" title="Time" />
        {Boolean(error.data) && (
          <TitledItem label={formatMoney(error.data.amount)} title="Amount" />
        )}
      </View>
      <TitledItem label={snakeCaseToTitleCase(error.type)} title="Problem" />
      <TitledItem
        label={snakeCaseToTitleCase(error.solution)}
        title="Solution"
      />
      <TitledItem
        label={error?.description ? `"${error.description}"` : '-'}
        title="Note"
      />
    </View>
  );
}

OrderRefundDetails.propTypes = {
  error: PropTypes.object,
};

export default OrderRefundDetails;
