/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react';
import { SafeAreaView, View } from 'react-native';
import { useTheme, Card, Text, ActivityIndicator } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonSize } from '../../../components/Button';
import { DropDownHolder } from '../../../components/DropDownHolder';
import withContactInfo from '../../../hocs/withContactInfo/withContactInfo';
import { getTempClosingHoursRequest } from '../../../redux/ducks/Settings/SettingsReducer';
import { selectTempClosingHours } from '../../../redux/ducks/Settings/SettingsSelector';
import { useRestaurant } from '../../../redux/ducks/User/UserReducer';
import NoClosingHours from './components/NoClosingHours';
import TempClosingHourItem from './components/TempClosingHourItem';
import { RootRoutes } from '../../../constants/routes';
import useRightMenu from './hooks/useRightMenu';

function SettingsTempClosingHours() {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const restaurant = useRestaurant();

  const { data: tempClosingHours, loading } = useSelector(
    selectTempClosingHours
  );

  useRightMenu();

  useEffect(() => {
    if (restaurant?.id) {
      dispatch(
        getTempClosingHoursRequest({
          restaurantId: restaurant.id,
          showError: (message) => {
            DropDownHolder.showAlert('error', message, '');
          },
        })
      );
    }
  }, [restaurant?.id]);

  const goToAddTempClosingHours = useCallback(() => {
    navigate(RootRoutes.SETTINGS_STACK.name, {
      screen: RootRoutes.SETTINGS_ADD_TEMPORARY_CLOSING_HOURS.name,
    });
  }, [navigate]);

  return (
    <SafeAreaView style={{ margin: 16, flex: 1 }}>
      <Card style={{ marginBottom: 24 }}>
        <Card.Title title="About" />
        <Card.Content>
          <Text variant="bodyMedium">
            If your restaurant needs to close for unexpected circumstances, you
            can set temporary closing hours here. For the days or hours you
            select, you will not receive Eatzy orders.
          </Text>
        </Card.Content>
      </Card>

      <Text
        style={{
          fontWeight: '700',
          color: theme.appColors.GRAY_500,
          paddingLeft: 16,
          paddingBottom: 8,
        }}
      >
        Temporarily Closed
      </Text>

      {loading ? (
        <ActivityIndicator />
      ) : tempClosingHours?.length ? (
        <Card>
          {tempClosingHours.map((item) => (
            <TempClosingHourItem
              key={item.id}
              data={item}
              restaurantId={restaurant.id}
            />
          ))}
        </Card>
      ) : (
        <NoClosingHours />
      )}

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 16,
        }}
      >
        <Button
          mode="outlined"
          testID="add-custom-hours-button"
          icon="plus"
          color="GRAY_700"
          size={ButtonSize.S}
          onPress={goToAddTempClosingHours}
        >
          Add Closing Hours
        </Button>
      </View>
    </SafeAreaView>
  );
}

export default withContactInfo(SettingsTempClosingHours);
