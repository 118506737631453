import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      appBar: {
        borderRadius: 4,
        elevation: 1,
      },
    });
  }
}
