import React from 'react';
import { Image } from 'react-native';
import { Surface, Text } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../../../utils/useStyles';
import StyleSheet from './NoOrders.styles';

const TrackingOrderImage = require('../../../../assets/images/tracking_order.png');

function NoOrders({ title }) {
  const styles = useStyles(StyleSheet);

  return (
    <Surface style={styles.surfaceContainer}>
      <Image
        style={styles.image}
        source={TrackingOrderImage}
        alt="order tracking"
      />
      <Text style={styles.title}>{title}</Text>
    </Surface>
  );
}

NoOrders.propTypes = {
  title: PropTypes.string,
};

export default NoOrders;
