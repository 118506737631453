import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { RootRoutes } from '../constants/routes';
import Header from '../components/Header';
import Drawer from './Drawer';

const Authenticated = createStackNavigator();

export const header = ({ scene, previous, navigation }) => (
  <Header scene={scene} previous={previous} navigation={navigation} />
);

function AuthenticatedStack() {
  return (
    <Authenticated.Navigator options={{ headerMode: 'screen' }}>
      <Authenticated.Screen
        options={{
          headerShown: false,
        }}
        name="Drawer"
        component={Drawer}
      />
      <Authenticated.Screen
        {...RootRoutes.ORDER_DETAILS}
        options={{
          ...RootRoutes.ORDER_DETAILS.options,
          header: ({ previous, navigation, ...scene }) => (
            <Header scene={scene} previous={previous} navigation={navigation} />
          ),
        }}
      />
      <Authenticated.Screen
        {...RootRoutes.ORDER_REFUND}
        options={{
          ...RootRoutes.ORDER_REFUND.options,
          // eslint-disable-next-line react/display-name
          header: ({ previous, navigation, ...scene }) => (
            <Header scene={scene} previous={previous} navigation={navigation} />
          ),
        }}
      />
      <Authenticated.Screen {...RootRoutes.SETTINGS_STACK} />
    </Authenticated.Navigator>
  );
}

export default AuthenticatedStack;
