import React from 'react';
import { Card, Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialIcons';

function NoClosingHours() {
  return (
    <Card>
      <Card.Content>
        <Icon
          testID="info-bar-default-icon"
          name="pending-actions"
          size={24}
          style={{
            textAlign: 'center',
            marginBottom: 12,
          }}
        />
        <Text variant="bodyMedium" style={{ textAlign: 'center' }}>
          No closing hours have been added. Click on the button below to set
          temporary closing hours or select days when your restaurant will be
          closed.
        </Text>
      </Card.Content>
    </Card>
  );
}

export default NoClosingHours;
