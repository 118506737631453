import React, { useState } from 'react';
import { ScrollView, View, LogBox } from 'react-native';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';

import CategoryItems from './components/CategoryItems';
import MenuListTitle from './components/MenuListTitle';
import MenuListItem from './components/MenuListItem';
import StyleSheets from './MenuList.styles';

LogBox.ignoreLogs(['Failed prop type:']);

function MenuList(props) {
  const { menu, active, inactive, showModal, menuQuestions } = props;
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const styles = useStyles(StyleSheets);

  if (selectedCategory) {
    return (
      <View style={styles.container}>
        <MenuListTitle
          name={selectedCategory.name}
          onPress={() => setSelectedCategory()}
          testId={selectedCategory.id.toString()}
        />
        <CategoryItems
          items={selectedMenu?.items}
          menuId={selectedMenu?.info.id}
          categoryId={selectedCategory.id}
          active={active}
          inactive={inactive}
          showModal={showModal}
          questions={selectedCategory.name === 'Answers'}
          menuQuestions={menuQuestions}
        />
      </View>
    );
  }

  if (selectedMenu) {
    return (
      <ScrollView style={styles.container}>
        <MenuListTitle
          name={selectedMenu.info.name}
          onPress={() => setSelectedMenu()}
          testId={selectedMenu.info.id.toString()}
        />
        {selectedMenu.categories.map((item) => (
          <MenuListItem
            key={item.id}
            name={item.name}
            testId={item.id.toString()}
            onPress={() => setSelectedCategory(item)}
          />
        ))}
      </ScrollView>
    );
  }

  return (
    <ScrollView style={styles.container}>
      {menu?.map((item) => (
        <MenuListItem
          key={item.info.id}
          name={item.info.name}
          testId={item.info.id.toString()}
          onPress={() => setSelectedMenu(item)}
        />
      ))}
      <MenuListItem
        name="Answers"
        onPress={() => setSelectedCategory({ name: 'Answers', id: 'answers' })}
        testId="answers"
      />
    </ScrollView>
  );
}

MenuList.propTypes = {
  menu: PropTypes.array,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
  showModal: PropTypes.func,
  menuQuestions: PropTypes.object,
};

export default MenuList;
