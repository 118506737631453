import {
  mapProgressNextToActionStatus,
  ORDER_ACTION,
} from '../constants/orders';
import camelToSnake from '../utils/CaseUtils/camelToSnake';
import snakeToCamel from '../utils/CaseUtils/snakeToCamel';
import { getInstance } from '../utils/AxiosInterceptors';

const axios = () => getInstance();

export const getRestaurantConfiguration = async (restaurantId) => {
  const response = await axios().get(`/restmanager/${restaurantId}/settings`);
  return snakeToCamel(response?.data?.config);
};

export const getRestaurantSettings = async (restaurantId) => {
  const response = await axios().get(`/restmanager/${restaurantId}/settings`);
  return snakeToCamel(response?.data);
};

export const getRestaurantStatus = async ({ client, at }) => {
  const response = await axios().get('/restaurant/customer/status', {
    params: { client, at },
  });
  return snakeToCamel(response?.data);
};

export const updateDelays = async (restaurantId, data) => {
  const response = await axios().post(
    `/restmanager/${restaurantId}/delays`,
    camelToSnake(data)
  );
  return snakeToCamel(response?.data);
};

export const getOrder = async ({ restaurantId, orderId }) => {
  const response = await axios().get(
    `/restmanager/${restaurantId}/orders/${orderId}`
  );
  return snakeToCamel(response?.data);
};

export const getOrders = async ({
  restaurantId,
  start,
  end,
  page,
  types,
  search,
  details,
  status,
  exclusive,
}) => {
  const response = await axios().get(`/restmanager/${restaurantId}/orders`, {
    params: camelToSnake({
      start,
      end,
      page,
      types,
      search,
      details,
      status,
      exclusive,
    }),
  });

  return snakeToCamel(response?.data);
};

export const readyDelivery = async ({ restaurantId, orderId }) => {
  const response = await axios().post(
    `/restmanager/${restaurantId}/orders/${orderId}/ready`
  );
  return snakeToCamel(response?.data);
};

export const completeTakeout = async ({ restaurantId, orderId }) => {
  const response = await axios().post(
    `/restmanager/${restaurantId}/orders/${orderId}/complete`
  );
  return snakeToCamel(response?.data);
};

export const fulfillOrder = ({ progress, type, restaurantId, orderId }) => {
  const status = mapProgressNextToActionStatus(progress, type);
  const selectedMethod = {
    [ORDER_ACTION.SET_AS_READY_FOR_DRIVER]: readyDelivery,
    [ORDER_ACTION.SET_AS_PICKED_UP]: completeTakeout,
  }[status];
  return selectedMethod?.({ restaurantId, orderId });
};

export const confirmOrder = async ({ restaurantId, orderId, pickupTime }) => {
  const response = await axios().post(
    `/restmanager/${restaurantId}/orders/${orderId}/confirm`,
    { pickup: pickupTime }
  );
  return snakeToCamel(response?.data);
};

export const getAvailableRestaurants = async () => {
  const response = await axios().get('/restmanager/restaurants');
  return snakeToCamel(response?.data);
};

export const putErrorOrder = async ({ restaurantId, orderId, formError }) => {
  const response = await axios().put(
    `/restmanager/${restaurantId}/orders/${orderId}/error`,
    formError
  );

  return snakeToCamel(response.data);
};

export const getErrorOrder = async ({ restaurantId, orderId }) => {
  const response = await axios().get(
    `/restmanager/${restaurantId}/orders/${orderId}/error`
  );

  return snakeToCamel(response.data);
};

export const getMenuItems = async (restId) => {
  const response = await axios().get(`/restmanager/${restId}/menus`);
  return snakeToCamel(response?.data);
};
export const getQuestions = async (restId) => {
  const response = await axios().get(`/restmanager/${restId}/questions`);
  return snakeToCamel(response?.data);
};

export const deleteItem = async ({
  restId,
  menuId,
  id,
  start,
  end,
  permanent,
}) => {
  const response = await axios().delete(
    `/restmanager/${restId}/menus/${menuId}/items/${id}`,
    {
      ...(start && { start }),
      ...(end && { end }),
      ...(permanent && { permanent }),
    }
  );
  return snakeToCamel(response?.data);
};

export const makeItemAvailable = async ({ restId, menuId, id }) => {
  const response = await axios().put(
    `/restmanager/${restId}/menus/${menuId}/items/${id}/restore`
  );
  return snakeToCamel(response?.data);
};

export const deleteAnswer = async ({
  restId,
  questionId,
  id,
  start,
  end,
  permanent,
}) => {
  const response = await axios().delete(
    `/restmanager/${restId}/questions/${questionId}/answer/${id}`,
    {
      ...(start && { start }),
      ...(end && { end }),
      ...(permanent && { permanent }),
    }
  );
  return snakeToCamel(response?.data);
};

export const makeAnswerAvailable = async ({ restId, questionId, id }) => {
  const response = await axios().put(
    `/restmanager/${restId}/questions/${questionId}/answer/${id}/restore`
  );
  return snakeToCamel(response?.data);
};

export const saveItem = async ({ restId, menuId, id, item }) => {
  const {
    media,
    prices,
    description,
    name,
    category,
    service,
    preparation,
    type,
    metaCategory,
    sort,
  } = item;
  const response = await axios().post(
    `/restmanager/${restId}/menus/${menuId}/items/${id}`,
    {
      description,
      sort,
      preparation,
      type,
      name,
      meta_category: metaCategory,
      category,
      price: prices.takeout.base,
      service,
      media: media?.length !== 0 ? media : {},
    }
  );
  return snakeToCamel(response?.data);
};

export const saveAnswer = async ({ restId, id, item, questionId }) => {
  const { prices, text, weight, sort } = item;
  const response = await axios().post(
    `/restmanager/${restId}/questions/${questionId}/answer/${id}`,
    {
      sort,
      text,
      weight: weight || 0,
      price: prices?.takeout.base,
    }
  );
  return snakeToCamel(response?.data);
};
