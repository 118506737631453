import React from 'react';
import { Text, View, TouchableWithoutFeedback } from 'react-native';
import { Checkbox, useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import StyleSheet from './CheckboxItem.styles';

export const getStatus = (selected) => (selected ? 'checked' : 'unchecked');

function CheckboxItem({ id, label, onChange, isSelected }) {
  const theme = useTheme();
  const styles = useStyles(StyleSheet);
  const status = getStatus(isSelected(id));

  return (
    <TouchableWithoutFeedback
      onPress={() => onChange(id)}
      testID={`checkbox-item-button-${id}`}
    >
      <View style={styles.container}>
        <Checkbox.Android
          testID={`checkbox-item-${status}`}
          color={theme.colors.primary}
          status={status}
        />
        <Text testID="checkbox-item-label">{label}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

CheckboxItem.propTypes = {
  id: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isSelected: PropTypes.func,
};

export default CheckboxItem;
