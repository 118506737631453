import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme, options) {
    return StyleSheet.create({
      questionContainer: {
        flexDirection: 'row',
        paddingTop: options?.isChild ? 0 : 10,
        paddingLeft: options?.isChild ? 5 : 0,
      },
      questionsSubDirectoryIcon: {
        color: theme.colors.lightText,
      },
      questionTextStyle: {
        color: theme.appColors.GRAY_500,
        fontSize: 16,
        fontFamily: theme.appFonts.semiBold,
        paddingBottom: 4,
      },
      answerLabel: {
        color: theme.appColors.GRAY_900,
        fontFamily: theme.appFonts.semiBold,
        // marginRight: 10,
        fontSize: 16,
        paddingBottom: 4,
      },
      highlightedAnswerQty: {
        color: theme.appColors.PRIMARY_500,
      },
      answerQty: {
        color: theme.appColors.GRAY_500,
      },
    });
  }
}
