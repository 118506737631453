import React, { useCallback } from 'react';
import { SafeAreaView, StatusBar, Text, View } from 'react-native';
import { Appbar, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import useStyles from '../../utils/useStyles';
import { RootRoutes } from '../../constants/routes';
import { useNewOrderAlert } from '../../context/NewOrderAlert';
import Stylesheet from './StatusBarWithNetInfo.styles';

const offlineText = 'You are not connected to the Internet';

function StatusBarWithNetInfo(props) {
  const theme = useTheme();
  const styles = useStyles(Stylesheet);

  const navigation = useNavigation();
  const { newOrdersCount } = useNewOrderAlert();
  const netInfo = useNetInfo();

  const goToOrders = useCallback(() => {
    navigation.navigate(RootRoutes.DASHBOARD.name, {
      initialTab: 'new',
    });
  }, [navigation]);

  if (!netInfo.isConnected) {
    return (
      <View testID="offline-status-bar">
        <StatusBar
          barStyle="light-content"
          backgroundColor={theme.colors.danger}
        />
        <SafeAreaView>
          <Text style={[styles.offlineText]}>{offlineText}</Text>
        </SafeAreaView>
      </View>
    );
  }

  if (newOrdersCount) {
    const pl = newOrdersCount > 1;
    return (
      <View testID="offline-status-bar">
        <StatusBar
          barStyle="light-content"
          backgroundColor={theme.colors.danger}
        />
        <SafeAreaView>
          <Appbar.Header style={styles.appBarHeader}>
            <Appbar.Action icon="bell-alert" color="white" />
            <Appbar.Content
              title={`You have ${newOrdersCount} order${
                pl ? 's' : ''
              }! Please confirm the${pl ? 'se' : ''} order${pl ? 's' : ''}.`}
              titleStyle={styles.offlineText}
              onPress={goToOrders}
            />
          </Appbar.Header>
        </SafeAreaView>
      </View>
    );
  }

  return (
    <View testID="standard-status-bar">
      <StatusBar
        barStyle="dark-content"
        backgroundColor={theme.colors.darkText}
        {...props}
      />
    </View>
  );
}

export default StatusBarWithNetInfo;
