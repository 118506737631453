import { createSelector } from '@reduxjs/toolkit';

export const selectRestaurantState = (state) => state.restaurant;

export const selectRestaurantSettings = createSelector(
  selectRestaurantState,
  (state) => state.restaurantSettings.data
);

export const selectRestaurantOpen = createSelector(
  selectRestaurantState,
  (state) => state.restaurantSettings.data?.status === 'open'
);

export const selectRestaurantSettingsLoading = createSelector(
  selectRestaurantState,
  (state) => state.restaurantSettings.loading
);

export const selectRestaurantStatus = createSelector(
  selectRestaurantState,
  (state) => state.restaurantStatus.data || {}
);
