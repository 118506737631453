import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      tabBar: {
        backgroundColor: theme.colors.surface,
        elevation: 1,
        marginBottom: 5,
      },
    });
  }
}
