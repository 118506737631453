import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { Button, Surface, useTheme } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import useStyles from '../../utils/useStyles';
import { useRestaurant } from '../../redux/ducks/User/UserReducer';
import { getDateRanges } from '../../utils/DateUtils';
import useSelectFields from '../../hooks/useSelectFields';
import CheckboxItem from '../../components/CheckboxItem';
import { SelectRangeMenu } from '../../components/SelectRangeMenu';
import { useBoolean } from '../../hooks/useBoolean/useBoolean';
import OrderHistoryList from './components/OrderHistoryList';
import StyleSheets from './OrderHistory.styles';

export const createOnChangeHandler = (action) => (_, value) => action(value);

export const selectDateRange = (
  setDateRange,
  dateRangeType,
  restaurantTimezone
) => {
  if (setDateRange && dateRangeType !== 'custom') {
    setDateRange(getDateRanges(restaurantTimezone)[dateRangeType]?.range);
  }
};

function OrderHistory() {
  const styles = useStyles(StyleSheets);
  const theme = useTheme();
  const restaurant = useRestaurant();
  const restaurantId = restaurant.id;
  const restaurantTimezone = restaurant.timezone;

  const [types, setTypes] = useState(['delivery', 'takeout']);
  const { isSelected, handleSelection } = useSelectFields({
    isMultiple: true,
    name: 'types',
    value: types,
    onChange: createOnChangeHandler(setTypes),
  });

  const [menuVisible, setMenuVisibility] = useBoolean(false);

  const [dateRangeType, setDateRangeType] = useState('today');
  const [dateRange, setDateRange] = useState(
    () => getDateRanges(restaurantTimezone)[dateRangeType]?.range
  );

  useEffect(() => {
    selectDateRange(setDateRange, dateRangeType, restaurantTimezone);
  }, [restaurantTimezone, dateRangeType]);

  const renderAnchor = (props) => (
    <View style={{ flexDirection: 'row' }}>
      <Button
        testID="order-history-calendar-button"
        compact
        mode="contained"
        color={theme.colors.backgroundColor}
        icon="calendar"
        onPress={setMenuVisibility.on}
        uppercase={false}
        {...props}
      >
        {props?.label} <Icon name="chevron-down" />
      </Button>
    </View>
  );

  return (
    <>
      <Surface style={styles.headerContainer}>
        <View style={styles.header}>
          <SelectRangeMenu
            anchor={renderAnchor}
            closeMenu={setMenuVisibility.off}
            isOpen={menuVisible}
            setDateRange={setDateRange}
            setDateRangeType={setDateRangeType}
            dateRangeType={dateRangeType}
            dateRange={dateRange}
          />

          <View style={styles.checkboxRow}>
            <CheckboxItem
              testID="order-history-checkbox-delivery"
              id="delivery"
              label="Delivery"
              onChange={handleSelection}
              isSelected={isSelected}
            />
            <CheckboxItem
              testID="order-history-checkbox-takeout"
              id="takeout"
              label="Takeout"
              onChange={handleSelection}
              isSelected={isSelected}
            />
          </View>
        </View>
      </Surface>
      <OrderHistoryList
        startTimestamp={dateRange.startDate}
        endTimestamp={dateRange.endDate}
        status="completed"
        types={types}
        restaurantId={restaurantId}
        refreshTimeStamp={selectDateRange}
      />
    </>
  );
}

export default OrderHistory;
