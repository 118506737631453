import React, { useRef } from 'react';
import { Animated, View } from 'react-native';
import { useNewOrderAlert } from '../../context/NewOrderAlert';
import { useBlinkAnimation } from '../../hooks/useBlinkAnimation/useBlinkAnimation';

function Blink({ children }) {
  const { alertLevel } = useNewOrderAlert();
  const animatedValue = useRef(new Animated.Value(0)).current;
  const { color } = useBlinkAnimation({ level: alertLevel, animatedValue });

  return (
    <>
      <View
        testID={alertLevel}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#F5F5F5',
        }}
        pointerEvents="none"
      />
      <Animated.View
        testID="blink-background-alert"
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: color,
          opacity: animatedValue,
        }}
        pointerEvents="none"
      />
      <View
        style={{
          flex: 1,
        }}
      >
        {children}
      </View>
    </>
  );
}

export default Blink;
