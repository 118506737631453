import { useMemo } from 'react';
import { useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';

function useStyles(Stylesheet, options) {
  const defaultTheme = useTheme();

  const theme = useMemo(
    () => Stylesheet.getSheet(defaultTheme, options),
    [defaultTheme, Stylesheet, options]
  );
  return theme;
}

useStyles.propTypes = {
  getTheme: PropTypes.function,
  options: PropTypes.node,
};

export default useStyles;
