import React, { useMemo } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

export default function Hide({ children, isHidden }) {
  const style = useMemo(() => {
    if (isHidden) return { display: 'none' };
    return {};
  });

  return (
    <View testID="hide-wrapper" style={style}>
      {children}
    </View>
  );
}
Hide.propTypes = {
  isHidden: PropTypes.bool,
};

Hide.defaultProps = {
  isHidden: false,
};
