import { format } from 'date-fns';
import DateTZ from './DateUtils';

export const formatDateTime = (date, time) => {
  const { hours, minutes } = time;
  date.setHours(hours);
  date.setMinutes(minutes);

  return format(date, 'E, MMM dd, hh:mm a');
};

export const getTimeStamp = (date, time) => {
  const { hours, minutes } = time;
  date.setHours(hours);
  date.setMinutes(minutes);

  return Math.floor(date.getTime() / 1000);
};

export const formatTimeStamp = (timestamp) => {
  const date = new Date(timestamp * 1000);

  return format(date, 'E, MMM dd, hh:mm a');
};

export const formatFooterNoticeTime = (timestamp, timezone) => {
  if (DateTZ.isToday(timestamp, timezone)) {
    return DateTZ.getFormattedTime(timestamp, timezone);
  }
  return DateTZ.getShortFormattedDateAndTime(timestamp, timezone);
};

export const timeDifferenceFromNow = (timestamp) => {
  const nowTS = Math.round(new Date().getTime() / 1000);
  const diff = Math.abs(timestamp - nowTS);
  const isLate = timestamp < nowTS;
  const isClose = diff < 30 * 60 && !isLate;

  if (diff < 60) {
    return {
      value: diff,
      unit: 'seconds',
      isLate,
      isClose,
    };
  }
  if (diff < 60 * 60) {
    return {
      value: Math.round(diff / 60),
      unit: 'minutes',
      isLate,
      isClose,
    };
  }
  if (diff < 60 * 60 * 24) {
    return {
      value: Math.round(diff / 3600),
      unit: 'hours',
      isLate,
      isClose,
    };
  }

  return {
    value: Math.round(diff / (3600 * 24)),
    unit: 'days',
    isLate,
    isClose,
  };
};
