import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import StyleSheet from './TitledItem.styles';

function TitledItem({ label, title }) {
  const styles = useStyles(StyleSheet);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.label}>{label}</Text>
    </View>
  );
}

TitledItem.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
};

export default TitledItem;
