import Sound from 'react-native-sound';
import { isWeb } from '../config';
import { sounds, soundsMap } from './sounds';

const getSoundObjectList = () => Object.values(soundsMap);

const getSoundById = (soundId) =>
  getSoundObjectList().find((sound) => sound?.id === soundId);

const playSound = (soundId) => {
  if (isWeb) {
    const soundObject = getSoundById(soundId);
    if (soundObject) {
      const sound = new Sound.Howl({
        src: [soundObject.file],
      });
      sound.play();
    }
  } else {
    const soundObject = getSoundById(soundId);
    if (soundObject) {
      const sound = new Sound(soundObject.file, (error) => {
        if (error) {
          console.error('failed to load the sound', error);
          return;
        }

        sound.play(() => {
          sound.release();
        });
      });
    } else {
      console.error('cannot find the sound', soundId);
    }
  }
};

export { playSound, sounds, getSoundObjectList, getSoundById };
