import { StyleSheet } from 'react-native';
import { badgeBackgroundColors, badgeTextColors } from './Chip.constants';

export default class StyleSheets {
  static getSheet(theme, { color, borderRadius }) {
    return StyleSheet.create({
      container: {
        borderRadius: borderRadius || 20,
        paddingVertical: 8,
        paddingHorizontal: 12,
        marginRight: 8,
        alignItems: 'center',
        alignSelf: 'flex-start',
      },
      backgroundStyle: {
        backgroundColor: badgeBackgroundColors[color],
      },
      textStyle: {
        color: badgeTextColors[color],
        fontSize: theme.fontSize.FONT_12,
        fontWeight: '500',
        textAlign: 'center',
      },
      subtitleStyle: {
        color: badgeTextColors[color],
        fontSize: theme.fontSize.FONT_16,
        fontWeight: '600',
        textAlign: 'center',
      },
    });
  }
}
