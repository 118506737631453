import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        elevation: 0,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      orderData: {
        height: 20,
        flexDirection: 'row',
      },
      leftContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: 120,
        paddingRight: 5,
      },
      rightContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: 120,
        paddingLeft: 5,
      },
      orderAmount: {
        fontWeight: 'bold',
      },
      total: {
        fontWeight: 'bold',
      },
      title: {
        textAlign: 'center',
        fontWeight: 'bold',
      },
      message: {
        textAlign: 'center',
      },
      safeArea: { flex: 0 },
    });
  }
}
