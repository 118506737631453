import { useEffect, useMemo } from 'react';
import { Animated } from 'react-native';

export const BLINK_LEVEL = {
  DISABLED: 'DISABLED',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

const BLINK_ANIMATION_INTERVAL = 1000;

export const animationLow = (fadeAnim) =>
  Animated.loop(
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.delay(3000),
    ])
  );

export const animationMedium = (fadeAnim) =>
  Animated.loop(
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.delay(2000),
    ])
  );

export const animationHigh = (fadeAnim) =>
  Animated.loop(
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: BLINK_ANIMATION_INTERVAL,
        useNativeDriver: false,
        // easing: Easing.ease,
      }),
      Animated.delay(1000),
    ])
  );

export const useBlinkAnimation = ({ level, animatedValue }) => {
  const blink = () => {
    switch (level) {
      case BLINK_LEVEL.LOW: {
        Animated.timing(animatedValue).stop();
        animationLow(animatedValue).start();
        break;
      }
      case BLINK_LEVEL.MEDIUM: {
        Animated.timing(animatedValue).stop();
        animationMedium(animatedValue).start();
        break;
      }
      case BLINK_LEVEL.HIGH: {
        Animated.timing(animatedValue).stop();
        animationHigh(animatedValue).start();
        break;
      }
      default: {
        animatedValue.setValue(0);
      }
    }
  };
  const colorsByLevel = useMemo(
    () =>
      ({
        [BLINK_LEVEL.DISABLED]: 'transparent',
        [BLINK_LEVEL.LOW]: 'rgba(255,255,0,1)',
        [BLINK_LEVEL.MEDIUM]: 'rgba(255,165,0,1)',
        [BLINK_LEVEL.HIGH]: 'rgba(255,0,0,1)',
      }[level]),
    [level]
  );

  useEffect(() => {
    blink();
  }, [level]);

  return { color: colorsByLevel };
};

export default useBlinkAnimation;
