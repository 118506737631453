import React from 'react';
import { Provider } from 'react-redux';
import { Provider as PaperProvider, withTheme } from 'react-native-paper';
import { PersistGate } from 'redux-persist/integration/react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { NotifierWrapper } from 'react-native-notifier';
import DropdownAlert from 'react-native-dropdownalert';
import { LogBox } from 'react-native';
import KeepAwake from '@sayem314/react-native-keep-awake';
import { QueryClient, QueryClientProvider } from 'react-query';
/* eslint-disable camelcase */
import {
  useFonts,
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from '@expo-google-fonts/inter';
/* eslint-enable camelcase */
import { Theme } from '../../constants/theme';
import { defaultStore, persistor } from '../../redux/stores/GlobalStore';
import { isWeb } from '../../utils/config';
import Splash from '../Splash';
import { DropDownHolder } from '../../components/DropDownHolder';
import LoadMaterialIconsWeb from '../../components/LoadMaterialIconsWeb';
import { NewOrderAlertProvider } from '../../context/NewOrderAlert';
import Routes from '../../routes/Routes';

LogBox.ignoreLogs(['Setting a timer']);
console.reportErrorsAsExceptions = false;

const queryClient = new QueryClient();

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

function App() {
  /* eslint-disable camelcase */
  useFonts({
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });
  /* eslint-enable camelcase */
  return (
    <PaperProvider theme={Theme}>
      <LoadMaterialIconsWeb />
      <NavigationContainer theme={MyTheme}>
        <Provider store={defaultStore}>
          <PersistGate loading={<Splash />} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <NotifierWrapper>
                <NewOrderAlertProvider>
                  <KeepAwake />
                  <Routes />
                  {!isWeb && (
                    <DropdownAlert
                      ref={(ref) => DropDownHolder.setDropDown(ref)}
                    />
                  )}
                </NewOrderAlertProvider>
              </NotifierWrapper>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </NavigationContainer>
    </PaperProvider>
  );
}

export default withTheme(App);
