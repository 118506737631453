import { omit } from 'lodash';
import { useQuery } from '../../libs/ReactQueryEnhanced/ReactQueryEnhanced';
import { getOrder } from '../../services/RestaurantManagerPortalService';
import useConfirmOrderDetails from './hooks/useConfirmOrderDetails';
import useFulfillOrderDetails from './hooks/useFulfillOrderDetails';

export const calculateTotalQuantity = (items) =>
  items?.reduce((acc, cur) => (cur?.amount || 1) + acc, 0);

export const calculateBaseSubtotal = (items) =>
  items?.reduce(
    (acc, cur) => (cur?.price?.base || 0) * (cur?.amount || 1) + acc,
    0
  );

export const calculateFees = (fees) =>
  fees.reduce((acc, cur) => (cur?.amount || 0) + acc, 0);

export const calculateTaxes = (taxes) =>
  taxes.reduce((acc, cur) => (cur?.amount || 0) + acc, 0);

export const calculateChargedSubtotal = (items) =>
  items?.reduce(
    (acc, cur) => (cur?.price?.charged || 0) * (cur?.amount || 1) + acc,
    0
  );

export const buildQuestionAnswerTree = (
  orderItem,
  questionOrAnswer,
  type = 'question'
) => {
  const childList =
    type === 'question' ? orderItem.answers : orderItem.questions;
  const children = childList?.filter(
    ({ parent }) => parent === questionOrAnswer.id
  );
  const answersObject = {
    answers: children.map((value) =>
      buildQuestionAnswerTree(orderItem, value, 'answer')
    ),
  };
  const questionsObject = {
    subQuestions: children?.map((value) =>
      buildQuestionAnswerTree(orderItem, value, 'question')
    ),
  };

  const child = type === 'question' ? answersObject : questionsObject;
  return {
    ...questionOrAnswer,
    ...child,
  };
};

export const getAmount = (amount) => amount || 1;

export const buildOrderItems = (items) =>
  items.map((item) => {
    if (item?.questions?.length) {
      return {
        ...omit(item, ['questions', 'answers']),
        questions: item.questions
          .filter(({ sub }) => !sub)
          .map((question) => buildQuestionAnswerTree(item, question)),
        amount: getAmount(item?.amount),
        price: {
          ...item?.price,
          totalBase: item?.answers?.reduce(
            (acc, cur) => (cur?.price?.base || 0) + acc,
            item?.price?.base
          ),
          totalCharged: item?.answers?.reduce(
            (acc, cur) => (cur?.price?.charged || 0) + acc,
            item?.price?.charged
          ),
        },
      };
    }
    return { ...item, amount: getAmount(item?.amount) };
  });

export const getTransformedOrderDetails = (response) => ({
  ...response,
  items: buildOrderItems(response.items),
});

export const useOrderDetails = ({ restaurantId, orderId }) => {
  const queryKey = ['order-details', { restaurantId, orderId }];

  const { data, refetch, isRefetching, status, isError, isSuccess } = useQuery(
    ['order-details', { restaurantId, orderId }],
    () => getOrder({ restaurantId, orderId }),
    {
      notifyOnChangeProps: ['data', 'error'],
      select: getTransformedOrderDetails,
      retry: false,
    }
  );

  const confirmOrder = useConfirmOrderDetails(queryKey, {
    restaurantId,
    orderId,
  });

  const fulfillOrder = useFulfillOrderDetails(queryKey, {
    restaurantId,
    orderId,
  });

  return {
    orderDetails: data,
    status: {
      isFetching: status?.isFetching || status?.isLoading || isRefetching,
      isSuccess,
      isError,
    },
    refetch,
    confirmOrder,
    fulfillOrder,
  };
};
