import React, { useState } from 'react';
import {
  View,
  KeyboardAvoidingView,
  SafeAreaView,
  Keyboard,
  Text,
  Image,
} from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme, Snackbar } from 'react-native-paper';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { signInRequest, clearErrors } from '../../redux/ducks/User/UserReducer';

import Button from '../../components/Button';
import Input from '../../components/Input';

import { encryptPassword } from '../../utils/EncryptionManager';
import { appVersion } from '../../utils/config';
import StyleSheets from './Login.styles';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const logo = require('../../assets/images/delivery.png');

function Login({ theme }) {
  const styles = StyleSheets.getSheet(theme);

  const [hidePassword, setHidePassword] = useState(true);
  const { error, isLoading } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSignInPress = async (data) => {
    Keyboard.dismiss();

    const { email, password } = data;
    const encryptedPassword = await encryptPassword(password);
    dispatch(signInRequest({ email, password: encryptedPassword }));
  };

  const onClickedOnEye = () => setHidePassword(!hidePassword);
  const showIconName = hidePassword ? 'eye-off' : 'eye';

  const onDismissSnackBar = async () => {
    dispatch(clearErrors());
  };

  return (
    <SafeAreaView style={styles.container} testID="login-page">
      <KeyboardAvoidingView style={styles.keyboard} behavior="padding">
        <View style={styles.content}>
          <Image source={logo} style={styles.logo} />
          <Text style={styles.signInText}>Sign in</Text>

          <Controller
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                keyboardType="email-address"
                label="E-MAIL OR USERNAME"
                testID="emailInput"
                placeholder="johndoe@gmail.com"
                onChangeText={(event) => onChange(event)}
                value={value}
                error={!!errors.email}
              />
            )}
            name="email"
          />
          {errors.email && (
            <Text testID="errorEmail" style={styles.errorText}>
              {errors.email.message}
            </Text>
          )}

          <Controller
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                secureTextEntry={hidePassword}
                label="PASSWORD"
                testID="passwordInput"
                placeholder="*****"
                rightIcon={showIconName}
                rightIconPress={onClickedOnEye}
                onChangeText={(event) => onChange(event)}
                value={value}
                error={!!errors.password}
              />
            )}
            name="password"
          />
          {errors.password && (
            <Text testID="errorPassword" style={styles.errorText}>
              {errors.password.message}
            </Text>
          )}

          <Button
            testID="submitSignIn"
            style={{ marginTop: 25 }}
            mode="contained"
            color="GRAY_800"
            onPress={handleSubmit(onSignInPress)}
            loading={isLoading}
            disabled={isLoading}
          >
            Sign In
          </Button>

          <Button testID="forgotPassword" style={{ marginTop: 15 }}>
            Forgot Password
          </Button>
        </View>
      </KeyboardAvoidingView>
      <Text style={styles.versionText}>
        v{appVersion} - Copyright © Eatzy 2021
      </Text>
      <Snackbar
        style={styles.snackBar}
        visible={error}
        onDismiss={onDismissSnackBar}
        action={{
          accessibilityLabel: 'login error snackbar',
          label: 'Close',
          onPress: onDismissSnackBar,
        }}
      >
        {error?.message}
      </Snackbar>
    </SafeAreaView>
  );
}

Login.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Login);
