import React, { useEffect, useState, useCallback } from 'react';
import { View, LogBox, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import DraggableFlatList from 'react-native-draggable-flatlist';
import {
  saveItem,
  saveAnswer,
} from '../../../../services/RestaurantManagerPortalService';
import { useRestaurant } from '../../../../redux/ducks/User/UserReducer';

import CategoryItem from '../../../CategoryItem';

LogBox.ignoreLogs(['ReactNativeFiberHostComponent:']);
LogBox.ignoreLogs(['Please report:']);

export const updateItemSort = (
  item,
  sort,
  menuId,
  categoryId,
  selectedRestaurant,
  question
) => {
  const apiCall = question ? saveAnswer : saveItem;
  apiCall({
    restId: selectedRestaurant.id,
    menuId,
    questionId: item.parent,
    id: item.id,
    item: {
      ...item,
      sort,
      ...(item.prices
        ? { prices: { takeout: { base: item.prices?.base } } }
        : {}),
      type: _.snakeCase(item.type?.replace(/\s/g, '')),
    },
  });
};

export const prepareCategoryItems = (
  setCategoryItems,
  items,
  categoryId,
  active,
  inactive,
  searchText,
  questions
) => {
  const newCategoryItems = (items || [])
    .filter(
      (item) =>
        (item.category === categoryId || questions) &&
        (item.status === 'active' || !active) &&
        (item.status !== 'active' || !inactive) &&
        ((item.name || item.text)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
          !searchText)
    )
    .sort((a, b) => a.sort - b.sort);
  setCategoryItems(newCategoryItems);
};

export const onDragEndItem = (
  { data, from, to },
  menuId,
  categoryId,
  selectedRestaurant,
  question
) => {
  if (from !== to) {
    updateItemSort(
      data[to],
      to >= 1 ? data[to - 1].sort + 1 : 0,
      menuId,
      categoryId,
      selectedRestaurant,
      question
    );
  }
};

function CategoryItems(props) {
  const {
    items,
    categoryId,
    active,
    inactive,
    menuId,
    showModal,
    questions,
    menuQuestions,
  } = props;
  const [categoryItems, setCategoryItems] = useState([]);
  const searchText = useSelector((state) => state.user.searchText || '');
  const selectedRestaurant = useRestaurant();

  useEffect(() => {
    prepareCategoryItems(
      setCategoryItems,
      !questions ? items : menuQuestions.answers,
      categoryId,
      active,
      inactive,
      searchText,
      questions
    );
  }, [
    items,
    categoryId,
    active,
    inactive,
    searchText,
    questions,
    menuQuestions,
  ]);

  const renderItem = useCallback(
    ({ item, drag }) => (
      <TouchableOpacity
        testID={`category-items-${item.id}`}
        onLongPress={drag}
        onPress={() => showModal({ item, menuId })}
      >
        <CategoryItem
          item={item}
          menuId={menuId}
          categoryId={categoryId}
          question={questions}
        />
      </TouchableOpacity>
    ),
    []
  );

  return (
    <View style={{ flex: 1 }}>
      <DraggableFlatList
        data={categoryItems}
        renderItem={renderItem}
        keyExtractor={(item) => `draggable-item-${item.id}`}
        onDragEnd={(result) => {
          setCategoryItems(result.data);
          onDragEndItem(
            result,
            menuId,
            categoryId,
            selectedRestaurant,
            questions
          );
        }}
      />
    </View>
  );
}

CategoryItems.propTypes = {
  menuId: PropTypes.number,
  categoryId: PropTypes.number,
  items: PropTypes.array,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
  questions: PropTypes.bool,
  showModal: PropTypes.func,
  menuQuestions: PropTypes.object,
};

export default CategoryItems;
