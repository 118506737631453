const Sound = {
  setCategory(category) {
    console.log(`Setting category to ${category}`);
  },
};

const SoundFile = require('../../assets/sounds/pixie_dust_android.mp3');

Sound.setCategory('Ambient', true);

export const sounds = {
  PIXIE_DUST: 'PIXIE_DUST',
};

export const PIXIE_DUST = {
  id: sounds.PIXIE_DUST,
  name: 'Pixie Dust',
  file: SoundFile,
};

export const soundsMap = {
  PIXIE_DUST,
};
