import React, { useState } from 'react';
import { View } from 'react-native';
import { List, Surface, Text } from 'react-native-paper';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isString } from 'lodash';
import useStyles from '../../utils/useStyles';
import Stylesheet from './SettingsMenu.styles';

export const getOpacity = (doingHeaderPress) => (doingHeaderPress ? 0.5 : 1);

function SettingsMenu({
  header,
  headerIcon,
  children,
  subHeaderStyle,
  onHeaderPress,
  ...props
}) {
  const themeStyle = useStyles(Stylesheet);

  const [doingHeaderPress, setDoingHeaderPress] = useState(false);
  const handleHeaderPress = async () => {
    try {
      setDoingHeaderPress(true);
      await onHeaderPress();
    } finally {
      setDoingHeaderPress(false);
    }
  };

  return (
    <List.Section testID="settings-menu" {...props}>
      {header && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <List.Subheader
            onPress={handleHeaderPress}
            testID="settings-menu-header"
            style={{
              ...themeStyle.subHeader,
              opacity: getOpacity(doingHeaderPress),
              ...subHeaderStyle,
            }}
          >
            {header}
          </List.Subheader>
          {headerIcon && (
            <View>
              {isString(headerIcon) ? (
                <Icon
                  testID="settings-menu-header-icon"
                  style={{
                    fontSize: 16,
                    opacity: getOpacity(doingHeaderPress),
                  }}
                  name={headerIcon}
                />
              ) : (
                headerIcon
              )}
            </View>
          )}
        </View>
      )}

      <Surface style={{ elevation: 1 }}>{children}</Surface>
    </List.Section>
  );
}
SettingsMenu.propTypes = {
  header: PropTypes.string,
  headerIcon: PropTypes.node,
  subHeaderStyle: PropTypes.object,
  onHeaderPress: PropTypes.func,
};
SettingsMenu.defaultProps = {
  header: null,
  headerIcon: null,
  subHeaderStyle: {},
};

function RightComponent({ isLink, text, Component, disabled }) {
  const themeStyle = useStyles(Stylesheet);

  const rightWrapperStyle = disabled
    ? { ...themeStyle.rightWrapper, ...themeStyle.rightDisabled }
    : { ...themeStyle.rightWrapper };

  let ComponentItem = null;
  if (isLink) {
    ComponentItem = function NewComponent(props) {
      return (
        <SettingsMenuIcon
          style={{ margin: 0 }}
          icon="chevron-right"
          {...props}
        />
      );
    };
  } else if (text) {
    ComponentItem = function NewComponent(props) {
      return <Text {...props}>{text}</Text>;
    };
  } else if (Component) {
    ComponentItem = function NewComponent(props) {
      return <Component {...props} />;
    };
  }

  if (ComponentItem === null) {
    ComponentItem = function NewComponent() {
      return <View />;
    };
  }

  return (
    <View
      testID="settings-menu-item-wrapper"
      pointerEvents={disabled ? 'none' : 'auto'}
      style={{
        ...rightWrapperStyle,
      }}
    >
      <ComponentItem testID="settings-menu-item-right" />
    </View>
  );
}

RightComponent.propTypes = {
  isLink: PropTypes.bool,
  Component: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export function SettingsMenuItem({
  style,
  isLink,
  right,
  rightText,
  rightDisabled,
  ...props
}) {
  const themeStyle = useStyles(Stylesheet);

  const rightComponent = () => (
    <RightComponent
      isLink={isLink}
      text={rightText}
      Component={right}
      disabled={rightDisabled}
    />
  );
  return (
    <List.Item
      titleNumberOfLines={1}
      titleStyle={{ ...themeStyle.itemLabel }}
      testID="settings-menu-item"
      style={{
        ...themeStyle.item,
        ...style,
      }}
      right={rightComponent}
      {...props}
    />
  );
}
SettingsMenuItem.propTypes = {
  style: PropTypes.object,
  isLink: PropTypes.bool,
  right: PropTypes.func,
  rightText: PropTypes.string,
  rightDisabled: PropTypes.bool,
};
SettingsMenuItem.defaultProps = {
  style: {},
  isLink: false,
  right: null,
  rightText: null,
  rightDisabled: false,
};
SettingsMenu.Item = SettingsMenuItem;

export function SettingsMenuIcon({ style, color = '#000', icon, ...props }) {
  const styles = useStyles(Stylesheet);
  return (
    <View testID="settings-menu-item-icon">
      <List.Icon
        style={{ ...styles.rightIcon, ...style }}
        color={color}
        icon={icon}
        {...props}
      />
    </View>
  );
}
SettingsMenuIcon.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  icon: PropTypes.string,
};
SettingsMenuIcon.defaultProps = {
  style: {},
};
SettingsMenu.Icon = SettingsMenuIcon;

export default SettingsMenu;
