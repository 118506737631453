import React, { useMemo } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Button,
  Switch,
  useTheme,
  Text,
  Portal,
  Dialog,
  List,
} from 'react-native-paper';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CONNECTIONS_STATUS } from '../../redux/ducks/Printer/PrinterReducer';
import SettingsMenu from '../../components/SettingsMenu';
import { renderIfMatchesConditions } from '../../utils/ReactUtils';
import { PrinterList } from '../../components/PrinterList';
import usePrinter from '../../hooks/usePrinter/usePrinter';
import templates from '../../utils/PosEncoder/templates';

export function PrinterInfoModal({ visible, hideModal, printer }) {
  const monitor = useSelector((state) => state.printer.printerMonitor);

  const monitorItems = useMemo(() => {
    if (printer?.status === CONNECTIONS_STATUS.CONNECTED) {
      return Object.entries(monitor || {});
    }
    return [];
  }, [printer, monitor]);

  const items = Object.entries(printer || {});

  return (
    <Portal>
      <Dialog
        testID="printer-info-modal"
        visible={visible}
        onDismiss={hideModal}
        style={{ maxHeight: '80%' }}
      >
        <Dialog.Title>{printer?.name}</Dialog.Title>

        <Dialog.ScrollArea>
          <ScrollView>
            <List.Section>
              <List.Subheader>Printer Info</List.Subheader>
              {items?.map(([key, value]) => (
                <List.Item
                  style={{ fontWeight: 'bold' }}
                  key={key}
                  title={key}
                  right={() => <Text>{JSON.stringify(value)}</Text>}
                />
              ))}
            </List.Section>
            {monitorItems?.length > 0 && (
              <List.Section>
                <List.Subheader>Printer Connection Status</List.Subheader>
                {monitorItems?.map(([key, value]) => (
                  <List.Item
                    style={{ fontWeight: 'bold' }}
                    key={key}
                    title={key}
                    right={() => <Text>{JSON.stringify(value)}</Text>}
                  />
                ))}
              </List.Section>
            )}
          </ScrollView>
        </Dialog.ScrollArea>
        <Dialog.Actions>
          <Button testID="settings-printer-done-btn" onPress={hideModal}>
            Done
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

PrinterInfoModal.propTypes = {
  visible: PropTypes.bool,
  hideModal: PropTypes.func,
  printer: PropTypes.object,
};

function SettingsPrinter() {
  const theme = useTheme();

  const {
    actions: {
      startDiscovery,
      toggleAllowDiscovery,
      connectPrinter,
      resetPrinters,
      handlePrint,
      openPrinterInfo,
      dismissPrinterInfoModal,
      visualizeReceipt,
    },
    state: {
      printerInfo,
      isDiscovering,
      allowDiscovery,
      isPrinting,
      myPrinters,
      otherPrinters,
      connectedPrinters,
    },
  } = usePrinter({
    template: templates.sample,
    data: null,
  });

  const renderSwitch = () => (
    <Switch
      testID="switch"
      color={theme.colors.primary}
      value={allowDiscovery}
      onValueChange={toggleAllowDiscovery}
    />
  );

  const renderConnectedPrinterButtons = () => {
    if (!connectedPrinters?.length) return null;
    return (
      <View style={{ padding: 10 }}>
        <Button
          testID="settings-printer-print-sample"
          onPress={handlePrint}
          disabled={isPrinting}
          loading={isPrinting}
        >
          Print Sample
        </Button>
        <Button
          testID="settings-printer-preview-print"
          onPress={visualizeReceipt}
          disabled={isPrinting}
          loading={isPrinting}
        >
          Preview Print
        </Button>
      </View>
    );
  };

  const renderDiscoveryLoading = () =>
    renderIfMatchesConditions(
      <SettingsMenu.Item title="Looking for printers..." />,
      [!otherPrinters.length, !myPrinters.length, isDiscovering]
    );

  return (
    <>
      <SafeAreaView style={{ margin: 15, flex: 1 }}>
        <ScrollView>
          <SettingsMenu>
            <SettingsMenu.Item title="Allow Discovery" right={renderSwitch} />

            <PrinterList
              printers={connectedPrinters}
              itemsTestID="settings-printer-connected"
              connectPrinter={connectPrinter}
              openPrinterInfo={openPrinterInfo}
            />
          </SettingsMenu>

          {renderConnectedPrinterButtons()}

          <PrinterList
            header="My Devices"
            printers={myPrinters}
            itemsTestID="settings-printer-mine"
            connectPrinter={connectPrinter}
            openPrinterInfo={openPrinterInfo}
          />

          <SettingsMenu
            header="Other Devices"
            headerIcon={isDiscovering ? <ActivityIndicator size={14} /> : null}
            onHeaderPress={startDiscovery}
          >
            <PrinterList
              printers={otherPrinters}
              itemsTestID="settings-printer-others"
              connectPrinter={connectPrinter}
              openPrinterInfo={openPrinterInfo}
            />
            {renderDiscoveryLoading()}
          </SettingsMenu>
          <View style={{ flex: 1 }} />
        </ScrollView>
      </SafeAreaView>
      <View style={{ backgroundColor: 'white', alignItems: 'center' }}>
        <Button testID="settings-printer-button-reset" onPress={resetPrinters}>
          Reset Networks
        </Button>
        <SafeAreaView style={{ flex: 0 }} />
      </View>

      <PrinterInfoModal
        visible={!!printerInfo}
        printer={printerInfo}
        hideModal={dismissPrinterInfoModal}
      />
    </>
  );
}

export default SettingsPrinter;
