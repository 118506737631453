import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Surface, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/core';
import PropTypes from 'prop-types';
import {
  restaurantSettingsRequest,
  restaurantStatusRequest,
} from '../../../../redux/ducks/Restaurant/RestaurantReducer';
import {
  selectRestaurantStatus,
  selectRestaurantSettings,
} from '../../../../redux/ducks/Restaurant/RestaurantSelector';
import useStyles from '../../../../utils/useStyles';
import { getRestaurantRefreshInterval } from '../../../../utils/config';
import { formatFooterNoticeTime } from '../../../../utils/DateUtils/conversion';
import useInterval from '../../../../hooks/useInterval';
import { Theme } from '../../../../constants/theme';
import StyleSheet from './RestaurantStatus.styles';
import { RootRoutes } from '../../../../constants/routes';

const REFRESH_INTERVAL = getRestaurantRefreshInterval();

function RestaurantStatus({ restaurantId, timezone }) {
  const styles = useStyles(StyleSheet);
  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const {
    open = false,
    nextOpeningAt,
    nextClosingAt,
  } = useSelector(selectRestaurantStatus);

  const restaurantSettings = useSelector(selectRestaurantSettings);
  const [restaurantProfileCode, setRestaurantProfileId] = useState(null);

  useEffect(() => {
    if (restaurantProfileCode) {
      dispatch(restaurantStatusRequest({ client: restaurantProfileCode }));
    }
  }, [restaurantProfileCode]);

  useEffect(() => {
    if (restaurantSettings?.profile) {
      setRestaurantProfileId(restaurantSettings?.profile);
    }
  }, [restaurantSettings]);

  useEffect(() => {
    if (restaurantId) {
      dispatch(restaurantSettingsRequest({ restaurantId }));
    }
  }, [restaurantId]);

  const nextTime = useMemo(() => {
    if (!open && nextOpeningAt) {
      return nextOpeningAt;
    }

    if (open && nextClosingAt) {
      return nextClosingAt;
    }

    return null;
  }, [open, nextOpeningAt, nextClosingAt]);

  useInterval(() => {
    if (restaurantId) {
      dispatch(restaurantSettingsRequest({ restaurantId }));
      if (restaurantProfileCode) {
        dispatch(restaurantStatusRequest({ client: restaurantProfileCode }));
      }
    }
  }, REFRESH_INTERVAL);

  const onStatusClicked = () => {
    console.log('Clicked!');
    navigate(RootRoutes.SETTINGS_STACK.name, {
      screen: RootRoutes.SETTINGS_TEMPORARY_CLOSING_HOURS.name,
    });
  };

  return (
    <Surface style={styles.surfaceContainer}>
      <Text numberOfLines={1} style={styles.title}>
        Restaurant Status
      </Text>
      <View style={styles.statusContainer}>
        <Button
          onPress={onStatusClicked}
          style={styles.statusButton}
          testID="restaurant-current-status-button"
          mode="contained"
          color={open ? Theme.appColors.SUCCESS_500 : Theme.appColors.ERROR_500}
        >
          {open ? 'Currently Open' : 'Currently Closed'}
        </Button>
      </View>
      <Text style={styles.subtitle}>
        {open ? 'Open Until' : 'Closed Until'}:{' '}
        {nextTime ? formatFooterNoticeTime(nextTime, timezone) : '-'}
      </Text>
    </Surface>
  );
}

RestaurantStatus.propTypes = {
  restaurantId: PropTypes.number,
  timezone: PropTypes.string,
};
RestaurantStatus.defaultProps = {
  restaurantId: null,
  timezone: 'America/Denver',
};

export default RestaurantStatus;
