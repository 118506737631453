export const problems = [
  { label: 'Item missing', value: 'Item missing', key: 'item_missing' },
  {
    label: 'Incorrect preparation',
    value: 'Incorrect preparation',
    key: 'incorrect_preparation',
  },
  { label: 'Late delivery', value: 'Late delivery', key: 'late_delivery' },
  {
    label: 'Wrong item received',
    value: 'Wrong item received',
    key: 'wrong_item_received',
  },
  { label: 'Driver error', value: 'Driver error', key: 'driver_error' },
  { label: 'Cannot deliver', value: 'Cannot deliver', key: 'cannot_deliver' },
  { label: 'Other', value: 'Other', key: 'other' },
];

export const solutions = [
  { label: 'Refund now', value: 'Refund now', key: 'refund_now' },
  { label: 'Note', value: 'Note', key: 'note' },
];
