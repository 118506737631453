import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        marginTop: 16,
        flex: 1,
        minWidth: 150,
      },
      input: {
        height: 40,
      },
    });
  }
}
