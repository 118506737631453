import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import useStyles from '../../../../utils/useStyles';
import OrderDetailsCommentNote from '../OrderItemCommentNote';
import Stylesheet from './OrderItemQuestion.style';

export function OrderItemAnswer({ answer }) {
  const styles = useStyles(Stylesheet);

  const renderSubQuestions = () =>
    answer?.subQuestions?.map((subQuestion) => (
      <OrderItemQuestion key={subQuestion?.id} question={subQuestion} isChild />
    ));

  return (
    <View>
      <View>
        <Text testID="order-item-answer-text" style={styles.answerLabel}>
          {answer?.amount && answer?.amount > 1 ? (
            <Text style={styles.highlightedAnswerQty}>{answer.amount}x </Text>
          ) : (
            <Text style={styles.answerQty}>1x </Text>
          )}
          {answer?.text}
        </Text>
      </View>
      <OrderDetailsCommentNote text={answer?.notes} />
      {renderSubQuestions()}
    </View>
  );
}

OrderItemAnswer.propTypes = {
  answer: PropTypes.shape({
    amount: PropTypes.number,
    text: PropTypes.string,
    notes: PropTypes.string,
    subQuestions: PropTypes.arrayOf(PropTypes.object),
  }),
};

function SubdirectoryIcon(props) {
  const styles = useStyles(Stylesheet);
  return (
    <Icon
      name="subdirectory-arrow-right"
      style={styles.questionsSubDirectoryIcon}
      {...props}
    />
  );
}

export function OrderItemQuestion({ question, isChild }) {
  const styles = useStyles(Stylesheet, { isChild });

  const hasAnswers = question?.answers?.length;
  if (!hasAnswers) return null;

  const renderAnswers = () =>
    question?.answers?.map((answer) => (
      <OrderItemAnswer key={answer?.id} answer={answer} />
    ));

  return (
    <View style={styles.questionContainer}>
      {isChild && (
        <SubdirectoryIcon testID="order-item-question-child-prefix" />
      )}
      <View>
        <Text
          testID="order-item-question-text"
          style={styles.questionTextStyle}
        >
          {question?.text}
        </Text>
        {renderAnswers()}
      </View>
    </View>
  );
}
OrderItemQuestion.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.object),
  }),
  isChild: PropTypes.bool,
};

export default OrderItemQuestion;
