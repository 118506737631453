import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      scrollContainer: { flex: 1 },
      sectionHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12,
      },
      sectionTitle: {
        fontSize: theme.fontSize.FONT_20,
        fontWeight: '500',
        color: theme.appColors.GRAY_900,
      },
      detailsContainer: {
        marginBottom: 24,
        borderRadius: 8,
        paddingBottom: 32,
        shadowColor: '#101828',
        shadowOpacity: 0.1,
        shadowOffset: {
          width: 0,
          height: 12,
        },
        shadowRadius: 8,
        elevation: 8,
        backgroundColor: '#ffffff',
      },
      feeLine: {
        paddingHorizontal: 24,
        paddingVertical: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      feeLabel: {
        fontSize: 20,
        fontFamily: theme.appFonts.medium,
        color: theme.appColors.GRAY_500,
      },
      feeAmount: {
        fontSize: 20,
        fontFamily: theme.appFonts.medium,
        color: theme.appColors.GRAY_900,
      },
      totalAmount: {
        fontSize: 30,
        fontFamily: theme.appFonts.semiBold,
        color: theme.appColors.GRAY_900,
      },
    });
  }
}
