import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';

import useStyles from '../../../../utils/useStyles';
import OrderDetailsCommentNote from '../OrderItemCommentNote';
import { OrderItemQuestion } from '../OrderItemQuestion';
import { formatMoney } from '../../../../utils/CurrencyUtils';
import Stylesheet from './OrderItem.styles';

export function OrderItemAmount({ amount }) {
  const styles = useStyles(Stylesheet);
  const amountStyle =
    amount > 1 ? styles.amountItemsTextHighlight : styles.amountItemsText;
  const amountTestID =
    amount > 1 ? 'order-item-amount-highlighted' : 'order-item-amount';
  return (
    <View testID={amountTestID}>
      <Text style={amountStyle}>{amount}x</Text>
    </View>
  );
}

OrderItemAmount.propTypes = {
  amount: PropTypes.number,
};

function OrderItem({ item }) {
  const { name, questions, price, amount, notes } = item;
  const styles = useStyles(Stylesheet);

  const renderHeader = () => (
    <View style={styles.headerContainer}>
      <OrderItemAmount amount={amount} />
      <Text style={styles.headerItemName}>{name}</Text>
      <View>
        <Text style={styles.headerItemPrice}>
          {formatMoney((price?.base || 0) * (amount || 1))}
        </Text>
      </View>
    </View>
  );

  const renderQuestionList = () =>
    questions?.map((question) => (
      <OrderItemQuestion
        key={`item-${item.id}-question-${question.id}`}
        question={question}
      />
    ));

  return (
    <View style={styles.container}>
      <View style={styles.bodyContainer}>
        {renderHeader()}
        {renderQuestionList()}
        <OrderDetailsCommentNote text={notes} />
      </View>
    </View>
  );
}

OrderItem.propTypes = {
  name: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.any),
  price: PropTypes.shape({
    charged: PropTypes.number,
    totalCharged: PropTypes.number,
    base: PropTypes.number,
    totalBase: PropTypes.number,
  }),
  amount: PropTypes.number,
  notes: PropTypes.string,
};

export default OrderItem;
