import React from 'react';
import { useTheme } from 'react-native-paper';
import { Tabs, TabScreen } from 'react-native-paper-tabs';
import PropTypes from 'prop-types';
import useStyles from '../../utils/useStyles';
import Stylesheet from './TabBar.styles';

function TabBar({ children, ...rest }) {
  const styles = useStyles(Stylesheet);
  const theme = useTheme();
  return (
    <Tabs
      style={{ ...styles.tabBar }}
      defaultIndex={0}
      uppercase={false}
      theme={theme}
      {...rest}
    >
      {children}
    </Tabs>
  );
}
TabBar.propTypes = {
  children: PropTypes.node,
};

TabBar.Screen = TabScreen;

export default TabBar;
