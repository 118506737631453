/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { isEmpty, omitBy, isNil } from 'lodash';
import PropTypes from 'prop-types';

export function NoOp({ component }) {
  return <>{component}</>;
}
NoOp.propTypes = {
  component: PropTypes.any,
};

export const isNetworkError = (err) => !!err?.isAxiosError && !err?.response;

export const renderIfMatchesConditions = (component, conditions) => {
  const matches = [conditions].flat().every(Boolean);
  if (matches) return component;
  return null;
};

export const insertAround = (list, component) => {
  if (!list?.length) return [];

  return list.reduce(
    (previous, current) => [
      ...previous,
      current,
      <NoOp component={component} />,
    ],
    [<NoOp component={component} />]
  );
};
export const insertBetween = (list, component) => {
  const insertedAfter = list.reduce(
    (previous, current) => [
      ...previous,
      current,
      <NoOp component={component} />,
    ],
    []
  );

  if (!isEmpty(insertedAfter)) {
    const lastIndex = insertedAfter.length - 1;
    return insertedAfter.slice(0, lastIndex);
  }

  return list;
};

export const filterUndefinedKeys = (obj) => omitBy(obj, isNil);

export const mergeStyles = (styles) =>
  styles.reduce(
    (previous, current) => ({ ...previous, ...filterUndefinedKeys(current) }),
    {}
  );
