import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        elevation: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 20,
        marginBottom: 16,
      },
      button: {
        width: '100%',
      },
      orderStatusText: {
        fontSize: 18,
        fontFamily: theme.appFonts.medium,
        color: theme.appColors.GRAY_900,
      },
    });
  }
}
