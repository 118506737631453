// https://github.com/rodw1995/use-cancelable-promise

import { useCallback } from 'react';
import useMountedState from '../useMountedState';

export default () => {
  const isMounted = useMountedState();
  return useCallback(
    (promise, onCancel) =>
      new Promise((resolve, reject) => {
        promise
          ?.then((result) => {
            if (isMounted()) {
              resolve(result);
            }
          })
          .catch((error) => {
            if (isMounted()) {
              reject(error);
            }
          })
          .finally(() => {
            if (!isMounted() && onCancel) {
              onCancel();
            }
          });
      }),
    [isMounted]
  );
};
