import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet() {
    return StyleSheet.create({
      container: {
        flex: 1,
      },
      row: {
        flexDirection: 'row',
        marginBottom: 16,
      },
    });
  }
}
