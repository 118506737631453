import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { GuestRoutes } from '../constants/routes';

const Guest = createStackNavigator();

const guestRoutesList = Object.values(GuestRoutes);

function GuestStack() {
  return (
    <Guest.Navigator>
      {guestRoutesList.map(({ name, component, options }) => (
        <Guest.Screen
          key={name}
          name={name}
          component={component}
          options={options}
        />
      ))}
    </Guest.Navigator>
  );
}

export default GuestStack;
