import { Theme } from '../../../../constants/theme';

export const ChipColor = {
  RED: 'red',
  BLUE: 'blue',
  LIGHT_YELLOW: 'light-yellow',
  YELLOW: 'yellow',
  GREEN: 'green',
  BLACK: 'black',
  GRAY: 'gray',
};

export const badgeTextColors = {
  [ChipColor.RED]: Theme.appColors.GRAY_900,
  [ChipColor.BLUE]: Theme.appColors.BASE_BLACK,
  [ChipColor.LIGHT_YELLOW]: Theme.appColors.GRAY_900,
  [ChipColor.YELLOW]: Theme.appColors.GRAY_900,
  [ChipColor.GREEN]: Theme.appColors.GRAY_900,
  [ChipColor.BLACK]: Theme.appColors.BASE_WHITE,
  [ChipColor.GRAY]: Theme.appColors.BASE_WHITE,
};

export const badgeBackgroundColors = {
  [ChipColor.RED]: Theme.appColors.ERROR_300,
  [ChipColor.BLUE]: Theme.appColors.PRIMARY_300,
  [ChipColor.LIGHT_YELLOW]: Theme.appColors.WARNING_200,
  [ChipColor.YELLOW]: Theme.appColors.WARNING_400,
  [ChipColor.GREEN]: Theme.appColors.SUCCESS_400,
  [ChipColor.BLACK]: Theme.appColors.GRAY_900,
  [ChipColor.GRAY]: Theme.appColors.GRAY_500,
};
