import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import OrdersList from '../../../components/OrdersList';
import { getOrders } from '../../../services/RestaurantManagerPortalService';
import { getDateRanges } from '../../../utils/DateUtils';
import { useInfiniteQuery } from '../../../libs/ReactQueryEnhanced/ReactQueryEnhanced';
import { useRestaurant } from '../../../redux/ducks/User/UserReducer';
import {
  getNextPageParam,
  getPageResults,
  produceUpdatedProgress,
  useOrderInfiniteItemMutation,
} from '../Dashboard.controller';
import NoOrders from '../components/NoOrders';

const fetchConfirmedOrders = ({
  queryKey: [, { types, status, startTimestamp, endTimestamp, restaurantId }],
  pageParam,
}) =>
  getOrders({
    restaurantId,
    start: startTimestamp,
    end: endTimestamp,
    page: pageParam,
    types,
    status,
    details: 1,
    exclusive: 1,
  });

function ConfirmedOrdersTab({ restaurantId, goToOrderDetails }) {
  const restaurant = useRestaurant();
  const restaurantTimezone = restaurant.timezone;
  const dateRange = useMemo(
    () => getDateRanges(restaurantTimezone).today.range,
    [restaurantTimezone]
  );

  const queryKey = [
    'orders',
    {
      types: ['delivery', 'takeout'],
      status: 'confirmed',
      startTimestamp: dateRange.startDate,
      endTimestamp: dateRange.endDate,
      restaurantId,
    },
  ];

  const {
    data,
    isLoading,
    isRefetching,
    isSuccess,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(queryKey, fetchConfirmedOrders, {
    getNextPageParam,
  });
  const ordersList = useMemo(() => getPageResults(data), [data]);

  const fulfillOrderMutation = useOrderInfiniteItemMutation(
    queryKey,
    produceUpdatedProgress
  );

  return (
    <OrdersList
      onActionPress={fulfillOrderMutation.mutateAsync}
      onItemPress={goToOrderDetails}
      data={ordersList}
      refetch={refetch}
      isLoading={isLoading || isRefetching}
      isFirstLoading={!isSuccess}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      emptyComponent={() => <NoOrders title="No confirmed orders" />}
    />
  );
}
ConfirmedOrdersTab.propTypes = {
  restaurantId: PropTypes.number,
  goToOrderDetails: PropTypes.func,
};

export default ConfirmedOrdersTab;
