import React, { useState, useCallback } from 'react';
import { View, Text } from 'react-native';
import { TextInput, withTheme } from 'react-native-paper';
import { TimePickerModal, DatePickerModal } from 'react-native-paper-dates';
import { format } from 'date-fns';
import useStyles from '../../utils/useStyles';
import StyleSheets from './DateTimePicker.style';

const formattedNumber = (number) => `0${number}`.slice(-2);

function DateTimePicker({
  label,
  date = new Date(),
  setDate,
  time = { hours: 0, minutes: 0 },
  setTime,
  theme,
}) {
  const [openTime, setOpenTime] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const styles = useStyles(StyleSheets);

  const onDismissTime = useCallback(() => {
    setOpenTime(false);
  }, [setOpenTime]);

  const onConfirmTime = useCallback(
    (params) => {
      setOpenTime(false);
      setTime(params);
    },
    [setOpenTime, setTime]
  );

  const onDismissDate = useCallback(() => {
    setOpenDate(false);
  }, [setOpenDate]);

  const onConfirmDate = useCallback(
    (params) => {
      setOpenDate(false);
      setDate(params.date);
    },
    [setOpenDate, setDate]
  );

  return (
    <View style={styles.container}>
      <Text>{label}</Text>
      <TextInput
        value={`${format(date, 'MMM dd, yyyy')} ${formattedNumber(
          time.hours
        )}:${formattedNumber(time.minutes)}`}
        editable={false}
        style={styles.input}
        left={
          <TextInput.Icon name="calendar" onPress={() => setOpenDate(true)} />
        }
        right={
          <TextInput.Icon name="clock" onPress={() => setOpenTime(true)} />
        }
        theme={{
          colors: {
            text: theme.colors.black,
          },
        }}
      />
      <DatePickerModal
        locale="en"
        mode="single"
        visible={openDate}
        onDismiss={onDismissDate}
        date={date}
        onConfirm={onConfirmDate}
      />
      <TimePickerModal
        visible={openTime}
        onDismiss={onDismissTime}
        onConfirm={onConfirmTime}
        hours={time.hours}
        minutes={time.minutes}
      />
    </View>
  );
}

export default withTheme(DateTimePicker);
