import React from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import useStyles from '../../utils/useStyles';
import Stylesheet from './LoadingScreen.styles';

function LoadingScreen() {
  const styles = useStyles(Stylesheet);
  return (
    <View testID="loading-screen" style={styles.container}>
      <ActivityIndicator color="blue" />
    </View>
  );
}

export default LoadingScreen;
