import React from 'react';
import { View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import PropTypes from 'prop-types';

export function VerticalDivider() {
  const theme = useTheme();
  return (
    <View
      style={{
        borderLeftWidth: 1,
        marginVertical: 8,
        borderLeftColor: theme.colors.darkText,
      }}
    />
  );
}

export function DotDivider({ style }) {
  return (
    <Text
      style={{
        paddingHorizontal: 6,
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      •
    </Text>
  );
}

DotDivider.propTypes = {
  style: PropTypes.object,
};
