import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getRestaurantConfiguration,
  updateDelays,
} from '../../services/RestaurantManagerPortalService';

const usePreparationTime = (restaurantId) => {
  const [preparationTimeInSeconds, setPreparationTimeInSeconds] = useState(0);
  const {
    data: restaurantConfigs,
    isLoading: isLoadingRestaurantConfigs,
    isFetching: isFetchingRestaurantConfigs,
  } = useQuery(
    ['restaurantConfigs', restaurantId],
    () => getRestaurantConfiguration(restaurantId),
    {
      retry: 3,
    }
  );

  const {
    minPreparationTime,
    maxPreparationTime,
    stepPreparationTime,
    currentPreparationTime,
  } = restaurantConfigs || {};

  useEffect(() => {
    if (currentPreparationTime) {
      setPreparationTimeInSeconds(currentPreparationTime);
    }
  }, [currentPreparationTime]);

  const { mutate } = useMutation('updateDelays', () =>
    updateDelays(restaurantId, {
      currentPreparationTime: preparationTimeInSeconds,
    })
  );

  const handleChangePreparationTime = (seconds) => {
    if (!isLoadingRestaurantConfigs) {
      setPreparationTimeInSeconds(seconds);
      mutate({
        currentPreparationTime: seconds,
      });
    }
  };

  const isLoading =
    isLoadingRestaurantConfigs ||
    isFetchingRestaurantConfigs ||
    restaurantConfigs == null;

  return {
    time: preparationTimeInSeconds,
    update: handleChangePreparationTime,
    isLoading,
    min: minPreparationTime,
    max: maxPreparationTime,
    step: stepPreparationTime,
  };
};

export default usePreparationTime;
