import React, { useCallback, useMemo } from 'react';
import { ScrollView, View } from 'react-native';

import { Dialog, Portal, useTheme } from 'react-native-paper';
import {
  DatePickerModal,
  en,
  registerTranslation,
} from 'react-native-paper-dates';
import PropTypes from 'prop-types';
import DateTZ, {
  getDateRanges,
  getDateRangesOrderedValues,
} from '../../utils/DateUtils';
import useSelectFields from '../../hooks/useSelectFields';
import SettingsMenu from '../SettingsMenu';
import { useRestaurant } from '../../redux/ducks/User/UserReducer';
import { DropDownHolder } from '../DropDownHolder';

registerTranslation('en', en);

export const isValidDateRange = (dateRange, maxIntervalInDays) =>
  DateTZ.diffInDays(dateRange.endDate, dateRange.startDate) < maxIntervalInDays;

export function SelectRangeMenu({
  anchor,
  isOpen,
  closeMenu,
  dateRange,
  setDateRange,
  maxIntervalInDays = 60,
  dateRangeType,
  setDateRangeType,
}) {
  const theme = useTheme();

  const restaurant = useRestaurant();
  const restaurantTimezone = restaurant?.timezone;

  const defaultDateRanges = useMemo(
    () => getDateRanges(restaurantTimezone),
    [restaurantTimezone]
  );

  const { isSelected, handleSelection } = useSelectFields({
    value: dateRangeType,
    onChange: (_, value) => {
      setDateRangeType(value);
      closeMenu();
    },
    isMultiple: false,
    allowSingleUnselect: false,
    name: 'range',
  });

  const items = getDateRangesOrderedValues();

  const [customDatePickerOpen, setCustomDatePickerOpen] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setCustomDatePickerOpen(false);
  }, [setCustomDatePickerOpen]);

  const onConfirm = React.useCallback(
    ({ startDate, endDate }) => {
      const startTimestamp = DateTZ.toTimestamp(DateTZ.fromJSDate(startDate));
      const endTimestamp = DateTZ.toTimestamp(DateTZ.fromJSDate(endDate));
      const selectedDateRange = {
        startDate: DateTZ.changeToTimezoneWithSameLocalTime(
          startTimestamp,
          restaurantTimezone
        ),
        endDate: DateTZ.changeToTimezoneWithSameLocalTime(
          endTimestamp,
          restaurantTimezone
        ),
      };

      if (!isValidDateRange(selectedDateRange, maxIntervalInDays)) {
        DropDownHolder.showAlert(
          'error',
          'Invalid Date Range',
          'You can only select ranges up to 60 days'
        );
        return null;
      }

      closeMenu();
      setCustomDatePickerOpen(false);
      setDateRange(selectedDateRange);
      handleSelection('custom');
      return null;
    },
    [setDateRange]
  );

  const itemStyle = useCallback(
    (item) => ({
      backgroundColor: isSelected(item)
        ? theme.colors.backgroundColor
        : theme.colors.white,
    }),
    [isSelected]
  );

  return (
    <View>
      {anchor({
        label: defaultDateRanges?.[dateRangeType]?.label ?? 'Custom',
        testID: 'select-range-menu',
      })}

      <View testID="date-picker-modal-container">
        <DatePickerModal
          mode="range"
          visible={customDatePickerOpen}
          onDismiss={onDismiss}
          startDate={DateTZ.timestampToJSDate(dateRange.startDate)}
          endDate={DateTZ.timestampToJSDate(dateRange.endDate)}
          onConfirm={onConfirm}
        />
      </View>

      <Portal>
        <Dialog
          testID="select-range-dialog"
          style={{ paddingLeft: 0 }}
          visible={isOpen}
          onDismiss={closeMenu}
        >
          <Dialog.Title style={{ color: 'black' }}>
            Select a Period
          </Dialog.Title>
          <Dialog.ScrollArea style={{ paddingHorizontal: 0, paddingTop: 0 }}>
            <ScrollView>
              {items.map((item) => {
                const itemLabel = defaultDateRanges?.[item].label;
                return (
                  <SettingsMenu.Item
                    testID="select-range-menu-item"
                    onPress={() => {
                      handleSelection(item);
                    }}
                    style={itemStyle(item)}
                    title={itemLabel}
                    key={item}
                  />
                );
              })}
              <SettingsMenu.Item
                testID="select-range-menu-item"
                isLink
                style={itemStyle('custom')}
                onPress={() => {
                  setCustomDatePickerOpen(true);
                }}
                title="Custom Range"
              />
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
    </View>
  );
}

SelectRangeMenu.propTypes = {
  anchor: PropTypes.func,
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func,
  dateRange: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }),
  setDateRange: PropTypes.func,
  setDateRangeType: PropTypes.func,
  dateRangeType: PropTypes.string,
  maxIntervalInDays: PropTypes.number,
};
