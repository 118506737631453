import React, { useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import { Text } from 'react-native-paper';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useStyles from '../../utils/useStyles';

import CategoryItem from '../CategoryItem';
import StyleSheets from './DeletedItems.styles';

function DeletedItems(props) {
  const { menu, menuQuestions } = props;
  const searchText = useSelector((state) => state.user.searchText || '');
  const styles = useStyles(StyleSheets);

  const deletedMenus = useMemo(() => {
    const items = [];
    menu.forEach((item) =>
      items.push({
        info: item.info,
        items: item.items.filter(
          (itemData) =>
            itemData.status !== 'active' &&
            (!searchText ||
              itemData.name
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase()))
        ),
      })
    );
    return items;
  }, [menu]);

  const deletedAnswers = useMemo(
    () =>
      menuQuestions?.answers?.filter(
        (item) =>
          item.status !== 'active' &&
          (!searchText ||
            item.text
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase()))
      ),
    [menuQuestions]
  );

  return (
    <ScrollView style={styles.container}>
      {deletedMenus?.map((deletedMenu) =>
        deletedMenu.items?.map((item) => (
          <CategoryItem
            key={item.id}
            item={item}
            menuId={deletedMenu.info.id}
            categoryId={item.category}
            question={false}
          />
        ))
      )}
      <View>
        <View style={styles.row}>
          <Text style={styles.title}>ANSWERS</Text>
        </View>
        <View style={styles.divider} />
        {deletedAnswers?.map((item) => (
          <CategoryItem key={item.id} item={item} question />
        ))}
      </View>
    </ScrollView>
  );
}

DeletedItems.propTypes = {
  menu: PropTypes.array,
  menuQuestions: PropTypes.object,
};

export default DeletedItems;
