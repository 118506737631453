import { shouldUseFakeData } from '../../utils/config';
import {
  mapProgressNextToActionStatus,
  ORDER_ACTION,
} from '../../constants/orders';
import DateTZ from '../../utils/DateUtils/DateUtils';
import { DropDownHolder } from '../../components/DropDownHolder';

export const getNextPageParam = (lastPage) =>
  lastPage?.page && lastPage?.page < lastPage.totalPages
    ? lastPage.page + 1
    : undefined;

export const getPageResults = (data) =>
  data?.pages?.flatMap((item) => item.results).filter(Boolean);

export const produceUpdatedProgress = (previous) => {
  const nextAction = mapProgressNextToActionStatus(
    previous.progress,
    previous.type
  );
  const timestamp = DateTZ.getNowTimestamp();
  const newProgress = {
    [ORDER_ACTION.SET_AS_PICKED_UP]: {
      completed: timestamp,
    },
    [ORDER_ACTION.SET_AS_READY_FOR_DRIVER]: {
      ready: timestamp,
    },
  }[nextAction];

  return {
    ...previous,
    progress: {
      ...previous.progress,
      ...newProgress,
    },
  };
};

export const createOnErrorHandler = () => async () => {
  if (!shouldUseFakeData()) {
    DropDownHolder.showAlert(
      'error',
      'Failed to fulfill request',
      'Failed to fulfill request'
    );
  }
};

export const createOnSettledHandler =
  ({ queryClient, queryKey, transformItem }) =>
  async (data, error, variables) => {
    if (!error || shouldUseFakeData()) {
      await queryClient.cancelQueries(queryKey);
      const orderId = variables?.orderId;

      const mapFoundResultToTransformation = (item) =>
        orderId === item?.id ? transformItem(item) : item;

      queryClient.setQueryData(queryKey, (previousData) => ({
        ...previousData,
        pages: previousData.pages?.map((page) => ({
          ...page,
          results: page?.results?.map(mapFoundResultToTransformation),
        })),
      }));
    }
  };
