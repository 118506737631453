import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import useStyles from '../../../../utils/useStyles';
import StyleSheet from './Chip.styles';
import { ChipColor } from './Chip.constants';

function Chip({
  title,
  subtitle,
  borderRadius,
  color = ChipColor.BLUE,
  style,
}) {
  const styles = useStyles(StyleSheet, { color, borderRadius });

  return (
    <View style={[styles.container, styles.backgroundStyle, style]}>
      <Text style={styles.textStyle}>{title}</Text>
      {subtitle && <Text style={styles.subtitleStyle}>{subtitle}</Text>}
    </View>
  );
}

Chip.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  borderRadius: PropTypes.number,
  style: PropTypes.object,
};

export default Chip;
