/* istanbul ignore file */
import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  error,
  logoutRequest,
  logoutSuccess,
  signInRequest,
  signInSuccess,
  profileRequest,
  profileSuccess,
  initialLoadRequest,
  initialLoadUnauthenticated,
  initialLoadSuccess,
  getRestaurantMenu,
  setRestaurantMenu,
  activateMenuItem,
  deleteMenuItem,
  setRestaurantQuestions,
} from '../../../ducks/User/UserReducer';
import { enums } from '../../../../constants/config';
import { doLogin, getProfile } from '../../../../services/AccountService';
import {
  getAvailableRestaurants,
  getMenuItems,
  makeItemAvailable,
  deleteItem,
  getQuestions,
  deleteAnswer,
  makeAnswerAvailable,
} from '../../../../services/RestaurantManagerPortalService';
import errorFallback from '../ErrorFallback';

async function login(email, password) {
  const response = await doLogin({ username: email, password });
  return response;
}

export function* signIn(action) {
  try {
    const { email, password } = action.payload;
    const loginData = yield call(login, email, password);
    const allowedRoles = {
      RestaurantManager: ['Admin', 'Manager', 'Owner', 'Operator'],
      LocalAreaManager: ['Admin', 'Manager'],
    };

    if (
      allowedRoles.RestaurantManager.includes(
        loginData?.roles?.RestaurantManager
      ) ||
      allowedRoles.LocalAreaManager.includes(loginData?.roles?.LocalAreaManager)
    ) {
      yield put(signInSuccess(loginData));

      const profile = yield call(getProfile);
      const restaurants = yield call(getAvailableRestaurants);
      yield put(
        profileSuccess({
          user: profile,
          restaurants,
        })
      );
    } else {
      yield put(error({ message: 'Forbidden' }));
    }
  } catch (err) {
    if (err?.message) yield put(error(err));
    else yield put(error({ message: 'Unknown Error Test' }));
  }
}

function* fetchUser() {
  try {
    const profile = yield call(getProfile);
    const restaurants = yield call(getAvailableRestaurants);

    yield put(
      profileSuccess({
        user: profile,
        restaurants,
      })
    );
  } catch (err) {
    yield put(error({ message: enums.SESSION_EXPIRED_MESSAGE }));
  }
}

function* signOut() {
  yield put(logoutSuccess());
}

function* initialUserLoad() {
  try {
    const isLoggedIn = yield select((state) => state.user.isLogged);
    if (!isLoggedIn) {
      yield put(initialLoadUnauthenticated());
      return;
    }
    const profile = yield call(getProfile);
    const restaurants = yield call(getAvailableRestaurants);

    yield put(
      initialLoadSuccess({
        user: profile,
        restaurants,
      })
    );
  } catch (err) {
    yield put(initialLoadUnauthenticated());
    yield put(error({ message: enums.SESSION_EXPIRED_MESSAGE }));
  }
}

function* fetchRestaurantMenu(action) {
  try {
    const { id } = action.payload;
    const resp = yield call(getMenuItems, id);
    const questions = yield call(getQuestions, id);

    yield put(setRestaurantMenu(resp));
    yield put(setRestaurantQuestions(questions));
  } catch (err) {
    yield put(error({ message: err.message }));
  }
}

function* onActivateMenuItem(action) {
  try {
    const { restId, menuId, item, question } = action.payload;
    const apiCall = question ? makeAnswerAvailable : makeItemAvailable;
    yield call(apiCall, {
      restId,
      menuId,
      item,
      id: item.id,
      questionId: item.parent,
    });
    yield put(getRestaurantMenu({ id: restId }));
  } catch (err) {
    yield put(error({ message: err.message }));
  }
}

function* onDeleteMenuItem(action) {
  try {
    const { restId, menuId, item, id, permanent, start, end, question } =
      action.payload;
    const apiCall = question ? deleteAnswer : deleteItem;
    yield call(apiCall, {
      restId,
      menuId,
      item,
      id,
      permanent,
      start,
      end,
      questionId: item.parent,
    });
    yield put(getRestaurantMenu({ id: restId }));
  } catch (err) {
    yield put(error({ message: err.message }));
  }
}

export function* watchUser() {
  yield takeEvery(logoutRequest, errorFallback, signOut);
  yield takeEvery(signInRequest, errorFallback, signIn);
  yield takeEvery(profileRequest, errorFallback, fetchUser);
  yield takeEvery(initialLoadRequest, errorFallback, initialUserLoad);
  yield takeEvery(getRestaurantMenu, errorFallback, fetchRestaurantMenu);
  yield takeEvery(activateMenuItem, errorFallback, onActivateMenuItem);
  yield takeEvery(deleteMenuItem, errorFallback, onDeleteMenuItem);
}
