import React, { useMemo } from 'react';
import {
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  Text,
} from 'react-native';
import { Searchbar, Surface } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { setSearchText } from '../../redux/ducks/User/UserReducer';
import StyleSheet from './SearchModal.styles';
import useStyles from '../../utils/useStyles';

function SearchModal({ navigation }) {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.user.searchText);
  const searchInput = useSelector((state) => state.search.searchInput);
  const styles = useStyles(StyleSheet);

  const handleClickOutside = () => {
    navigation.goBack();
  };

  const items = useMemo(() => {
    if (!searchText) return [];
    let list = [];
    if (!_.isNil(searchInput)) {
      searchInput.forEach((item) => {
        list = [
          ...list,
          ...item.items.filter((i) =>
            i.name.toLowerCase().includes(searchText.toLowerCase())
          ),
        ];
      });
    }
    return list.slice(0, 5);
  }, [searchText, searchInput]);

  return (
    <View style={{ flex: 1 }}>
      <Surface>
        <SafeAreaView>
          <Searchbar
            placeholder="Search"
            clearIcon="menu"
            icon="keyboard-backspace"
            onIconPress={navigation.goBack}
            style={{ elevation: 0, padding: 20 }}
            onChangeText={(text) => dispatch(setSearchText(text))}
            value={searchText}
          />
        </SafeAreaView>
        {Boolean(items.length) && (
          <View style={{ paddingLeft: 70, paddingBottom: 20 }}>
            <Text style={styles.title}>ITEMS</Text>
            {items.map((item) => (
              <Text key={item.id} style={styles.name}>
                {item.name}
              </Text>
            ))}
          </View>
        )}
      </Surface>
      <TouchableWithoutFeedback
        testID="search-modal-outside"
        onPress={handleClickOutside}
      >
        <View style={{ flex: 1 }} />
      </TouchableWithoutFeedback>
    </View>
  );
}

export default SearchModal;
