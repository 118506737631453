import React from 'react';
import { View, Text } from 'react-native';
import ReactSelect from 'react-select';
import useStyles from '../../utils/useStyles';
import StyleSheet from './DropdownItem.styles';

function DropdownItem({ label, onChange, selectedItem, items }) {
  const styles = useStyles(StyleSheet);

  const handleChange = (e) => {
    onChange(e.value);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <ReactSelect
        testID="drop-down"
        menuPortalTarget={document.body}
        options={items}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        getOptionValue={(option) => option.value}
        defaultValue={items.find((option) => option.value === selectedItem)}
        onChange={handleChange}
      />
    </View>
  );
}

export default DropdownItem;
