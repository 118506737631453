import { StyleSheet } from 'react-native';

export default class StyleSheets {
  static getSheet(theme) {
    return StyleSheet.create({
      container: {
        flexDirection: 'row',
        paddingHorizontal: 24,
        paddingVertical: 32,
        borderBottomWidth: 1,
        borderBottomColor: theme.appColors.GRAY_300,
      },
      amountItemsText: {
        borderRadius: 6,
        width: 48,
        height: 48,
        textAlign: 'center',
        lineHeight: 48,
        color: theme.appColors.GRAY_500,
        backgroundColor: theme.appColors.GRAY_50,
        fontSize: 24,
        fontFamily: theme.appFonts.semiBold,
      },
      amountItemsTextHighlight: {
        borderRadius: 6,
        width: 48,
        height: 48,
        textAlign: 'center',
        lineHeight: 48,
        color: theme.appColors.PRIMARY_500,
        backgroundColor: theme.appColors.PRIMARY_25,
        fontSize: 24,
        fontFamily: theme.appFonts.semiBold,
      },
      bodyContainer: {
        flex: 1,
      },
      headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      headerItemName: {
        marginHorizontal: 14,
        flexGrow: 1,
        flexWrap: 'wrap',
        flexShrink: 1,
        fontSize: 24,
        fontFamily: theme.appFonts.semiBold,
        color: theme.appColors.GRAY_900,
      },
      headerItemPrice: {
        fontFamily: theme.appFonts.semiBold,
        fontSize: 24,
        color: theme.appColors.GRAY_900,
      },
    });
  }
}
