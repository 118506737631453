import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { isWeb } from '../../utils/config';

function LoadMaterialIconsWeb() {
  return isWeb ? (
    <style type="text/css">{`
          @font-face {
            font-family: 'MaterialCommunityIcons';
            src: url(${MaterialCommunityIcons}) format('truetype');
          }
        `}</style>
  ) : null;
}

export default LoadMaterialIconsWeb;
